import { Component, Input, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { ToastrService } from "ngx-toastr";
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
  NgbDate,
  NgbCalendar,
} from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

import { numberValidator, customRegexValidator } from "../../helper/validator";

@Component({
  selector: "app-timings",
  templateUrl: "./timings.component.html",
  styleUrls: ["./timings.component.scss"],
})
export class TimingsComponent implements OnInit {
  @Input() timings;
  @Input() title;
  @Input() startDate;
  @Input() endDate;
  @Input() showDatePicker;
  hoveredDate: NgbDate | null = null;
  hasDateErrors = false;
  fromDate: NgbDate; 
  toDate: NgbDate | null = null;
  dateRangePickerConfig = { rangeInputFormat : 'DD-MM-YYYY', containerClass: 'theme-blue',  minDate: new Date };
  restaurantConfigForm: FormGroup;
  specificDateForm: FormGroup;
  setTimeForm: FormGroup;
  setTimeFormTuesday: FormGroup;
  setTimeFormWednesday: FormGroup;
  setTimeFormThursday: FormGroup;
  setTimeFormFriday: FormGroup;
  setTimeFormSaturday: FormGroup;
  setTimeFormSunday: FormGroup;
  modalReference: NgbModalRef;
  submitted: boolean = false;
  availableTimeSlots = [];
  isAvailableFullTime: boolean = false;
  isAvailableAllDays: boolean = true;
  isAvailableSpecificDays: boolean = false;
  timeWiseAvailabilityEveryDay: boolean = false;
  timeWiseAvailabilitySpecificDay: boolean = false;
  isOpenWholeDay: boolean;
  isOpenForSpecificTimeRangesOnly: boolean;
  availableTimeRangesMonday = [];
  availableTimeRangesTuesday = [];
  availableTimeRangesWednesday = [];
  availableTimeRangesThursday = [];
  availableTimeRangesFriday = [];
  availableTimeRangesSaturday = [];
  availableTimeRangesSunday = [];
  isOpenForSpecificMonday: boolean = false;
  isOpenForSpecificTuesday: boolean = false;
  isOpenForSpecificWednesday: boolean = false;
  isOpenForSpecificThursday: boolean = false;
  isOpenForSpecificFriday: boolean = false;
  isOpenForSpecificSaturday: boolean = false;
  isOpenForSpecificSunday: boolean = false;
  selectedDay = null;
  selectedDayIndex = null;
  timeRangesForSelectedDay = [];
  id;
  ngLoader;
  
  startDate1;
  endDate1;

  specificDay: boolean = false;
  specificDayRange: boolean = false;
  availableDateRange;
  day;
  restaurantTimeSetup = {};
  restaurantData;
  time;
  meridian = true;
  datepickerModel: any;
  selectedDaysTimeSlots = [];
  restaurantId = JSON.parse(localStorage.getItem("restaurantId"));
  constructor(
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), "d", 10);
  }
  days = [
    {
      name: "Sunday",
      isOpen: -1, //-1 means not set yet, 1 means open, 0 means not open
      timeRanges: [],
    },
    {
      name: "Monday",
      isOpen: -1,
      timeRanges: [],
    },
    {
      name: "Tuesday",
      isOpen: -1,
      timeRanges: [],
    },
    {
      name: "Wednesday",
      isOpen: -1,
      timeRanges: [],
    },
    {
      name: "Thursday",
      isOpen: -1,
      timeRanges: [],
    },
    {
      name: "Friday",
      isOpen: -1,
      timeRanges: [],
    },
    {
      name: "Saturday",
      isOpen: -1,
      timeRanges: [],
    },
  ];
  markDayStatus(selectedDayName, status) {
    var days = this.days;
    for (var i in days) {
      var day = days[i];
      var dayName = day.name;
      if (dayName == selectedDayName) {
        this.days[i].isOpen = status;
        break;
      }
    }
  }
  onDateChanged = () => {
    let val = this.specificDateForm.value.specificDate;
    console.log(val);
      
    if(val) {
      this.hasDateErrors =  false;
    }
  }
  handleRestaurantRegistration() {
    var token = localStorage.getItem("UserToken");
    var isLoggedIn = false;
    if (token) {
      isLoggedIn = true;
    }
    if (!isLoggedIn) {
      this.router.navigate(["login"]);
      return false;
    }
    var id = localStorage.getItem("restaurantId");
    if (id) {
      id = JSON.parse(id);
    }
    if (id) {
      this.id = id;
    } else { 
      this.router.navigate(["get-started"]);
      return false;
    }
    return true;
  }
  numberOnly(evt): boolean {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  ngOnChanges() {
    this.createSpecificDateFormIfNeeded();
    let availableTimeSlots = this.timings.availableTimeSlots || {};
    this.initDayConfig(availableTimeSlots);
    this.setDateRange();
  }
  setDateRange() { 
    
    let dateStart = new Date(this.startDate);
    let dateEnd = new Date(this.endDate); 
    this.specificDateForm.reset({
      specificDate: [dateStart, dateEnd]
    });
  } 

  resetSpecificDateRange() { 
    
    let dateStart = '';
    let dateEnd = ''; 
    this.specificDateForm.reset({
      specificDate: [dateStart, dateEnd]
    });
  } 

  createSpecificDateFormIfNeeded() {
    if(!this.specificDateForm) { 
      let dateValidator = this.showDatePicker?["",Validators.required]:[""]
      this.specificDateForm = this.fb.group({
        specificDate: dateValidator,
      });
    }
  }
  ngOnInit() {
    this.setTimeForm = this.fb.group({
      startTime: ["", Validators.required],
      endTime: ["", Validators.required],
    });
    this.createSpecificDateFormIfNeeded();
    this.setDateRange();
 
    let availableTimeSlots = this.timings.availableTimeSlots || {}; 
    this.initDayConfig(availableTimeSlots); 
 
  }

  startDateChange(value) {
    this.startDate = value;
  }

  endDateChange(value) {
    this.endDate = value;
  }

  changeDayAvailability(value) {
    // if (value == "specificDayRange") {
    //   this.specificDayRange = true;
    //   this.isAvailableAllDays = false;
    //   this.availableDateRange = {};
    // } else {
    //   this.specificDayRange = false;
    //   this.isAvailableAllDays = true;
    // }
  }

  changeTimeAvailability(value) {
    if (value == "specificDay") {
      this.specificDay = true;
      this.isAvailableFullTime = false;
    } else {
      this.specificDay = false;
      this.isAvailableFullTime = false;
    }
  }

  getRestaurantData() {
    this.ApiService.getRestaurantsSetup(this.restaurantId).subscribe((data) => {
      this.restaurantData = data["item"];
      this.restaurantConfigForm.patchValue({
        minOrderAcceptanceAmount: this.restaurantData.minOrderAcceptanceAmount,
        minTakeAwayAmount: this.restaurantData.minTakeAwayAmount,
        merchantsRemainder: this.restaurantData.merchantsRemainder,
        customerRemainder: this.restaurantData.customerRemainder,
        preparationTimeMinutes: this.restaurantData.preparationTimeMinutes,
      });
    });
  }
  logForADay(day, log, dayToLog="wednesday") {
    if(day.name.toLowerCase() != dayToLog) return; 
  }
  initDayConfig(availableTimeSlots) { 
    let days = this.days;
    for (let i in days) {
      let day = days[i];
      let dayName = day.name.toLowerCase();
      let isOpen = 1; 
      if (availableTimeSlots[dayName]) {
        let dayValue = availableTimeSlots[dayName];
        let isOpenWholeDay = dayValue.isOpenWholeDay;
        let isOpenForSpecificTimeRangesOnly =
          dayValue.isOpenForSpecificTimeRangesOnly;
        let timeRanges = dayValue.availableTimeRanges;
        if (!isOpenWholeDay) {
          isOpen = 0;
        }
        if (isOpenForSpecificTimeRangesOnly) {
          isOpen = 2;
        }
        days[i].isOpen = isOpen;
        days[i].timeRanges = timeRanges;
      } 
    }
  }

  open(content, day) {
    day = day.toLowerCase();
    this.day = day;
    this.selectedDay = day;
    let days = this.days;
    let i;
    for (i in days) {
      let curDay = days[i];
      let dayName = curDay.name.toLowerCase(); 
      if (day == dayName) {
        this.selectedDayIndex = i;
        break;
      }
    }
    this.timeRangesForSelectedDay = this.days[i].timeRanges;
    this.modalReference = this.modalService.open(content, {
      size: "",
      centered: true,
      windowClass: "modal-custom",
    });
    this.modalReference.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  onSubmitTime() { 
    var selectedDay = this.selectedDay.toLowerCase();
    this.submitted = true;
    if (this.setTimeForm.invalid) {
      this.toastr.error("start time and end time is required");
      return;
    } 
    var timeRange = {
      startTime: this.formatTime(
        this.setTimeForm.value.startTime.hour +
          ":" +
          this.setTimeForm.value.startTime.minute
      ),
      endTime: this.formatTime(
        this.setTimeForm.value.endTime.hour +
          ":" +
          this.setTimeForm.value.endTime.minute
      ),
    };
    
    var days = this.days;
    var i = this.selectedDayIndex;
    var day = days[i];
    var name = day.name.toLowerCase();
    if (selectedDay == name) {
      day.timeRanges.push(timeRange);
      this.days[i] = day;
    }

    this.timeRangesForSelectedDay = day.timeRanges;
  }

  get f() {
    return this.setTimeForm.controls;
  }

  get f1() {
    return this.setTimeFormTuesday.controls;
  }

  get f2() {
    return this.setTimeFormWednesday.controls;
  }

  get f3() {
    return this.setTimeFormThursday.controls;
  }

  get f4() {
    return this.setTimeFormFriday.controls;
  }

  get f5() {
    return this.setTimeFormSaturday.controls;
  }

  get f6() {
    return this.setTimeFormSunday.controls;
  }

  removeTimeRage(index) {
    var selectedDayIdx = this.selectedDayIndex;
    var timeRanges = this.days[selectedDayIdx].timeRanges;
    timeRanges.splice(index, 1);
    this.days[selectedDayIdx].timeRanges = timeRanges;
  }

  displayErrorsAndSuccess(result) {
    if (result["success"] == 1) {
      this.toastr.success(result.message);
    } else {
      this.toastr.warning(result.message);
    }
  }

  // changeMonday(value) {
  //   if(value == 'mondayOpenAllDay') {
  //     this.isOpenForSpecificMonday = false;
  //     this.isOpenWholeDay = true;
  //     this.isOpenForSpecificTimeRangesOnly = false;
  //     this.monday = {
  //       isOpenWholeDay: this.isOpenWholeDay,
  //       isOpenForSpecificTimeRangesOnly: this.isOpenForSpecificTimeRangesOnly,
  //     }
  //   } else if(value == 'mondayCloseAllDay') {
  //     this.isOpenForSpecificMonday = false;
  //     this.isOpenWholeDay = false;
  //     this.isOpenForSpecificTimeRangesOnly = false;
  //     this.monday = {
  //       isOpenWholeDay: this.isOpenWholeDay,
  //       isOpenForSpecificTimeRangesOnly: this.isOpenForSpecificTimeRangesOnly,
  //     }
  //   } else {
  //     this.isOpenWholeDay = false;
  //     this.isOpenForSpecificMonday = true;
  //     this.isOpenForSpecificTimeRangesOnly = true;
  //     this.monday = {
  //       isOpenWholeDay: this.isOpenWholeDay,
  //       isOpenForSpecificTimeRangesOnly: this.isOpenForSpecificTimeRangesOnly,
  //       availableTimeRanges: this.availableTimeRangesMonday
  //     }
  //   }

  // }

  /*

normalTimings: { 
            availableTimeSlots: {
                monday: {
                    isOpenWholeDay: Boolean,
                    isOpenForSpecificTimeRangesOnly: Boolean,
                    availableTimeRanges: [{
                        startTime: String,
                        endTime: String
                    }]
                },
                tuesday: {
                    isOpenWholeDay: Boolean,
                    isOpenForSpecificTimeRangesOnly: Boolean,
                    availableTimeRanges: [{
                        startTime: String,
                        endTime: String
                    }]
                },
                Wednesday: {
                    isOpenWholeDay: Boolean,
                    isOpenForSpecificTimeRangesOnly: Boolean,
                    availableTimeRanges: [{
                        startTime: String,
                        endTime: String
                    }]
                },
                thursday: {
                    isOpenWholeDay: Boolean,
                    isOpenForSpecificTimeRangesOnly: Boolean,
                    availableTimeRanges: [{
                        startTime: String,
                        endTime: String
                    }]
                },
                friday: {
                    isOpenWholeDay: Boolean,
                    isOpenForSpecificTimeRangesOnly: Boolean,
                    availableTimeRanges: [{
                        startTime: String,
                        endTime: String
                    }]
                },
                saturday: {
                    isOpenWholeDay: Boolean,
                    isOpenForSpecificTimeRangesOnly: Boolean,
                    availableTimeRanges: [{
                        startTime: String,
                        endTime: String
                    }]
                },
                sunday: {
                    isOpenWholeDay: Boolean,
                    isOpenForSpecificTimeRangesOnly: Boolean,
                    availableTimeRanges: [{
                        startTime: String,
                        endTime: String
                    }]
                }
            }  
        },




  */
  availabilitySubmit() {
    let field = "normalTimings";
    let availableDateRange = {};
    let dateRange = {

    }
    if (this.showDatePicker) {
      this.hasDateErrors = false;
      if (this.specificDateForm.invalid) {
          this.hasDateErrors = false;
          // return;
      }
      field = "specialTimings";
      var dateStart = new Date(this.datepickerModel[0]);
      var dateEnd = new Date(this.datepickerModel[1]);
      this.startDate =
        dateStart.getFullYear() +
        "-" +
        (dateStart.getMonth() + 1) +
        "-" +
        dateStart.getDate();
      this.endDate =
      dateEnd.getFullYear() +
        "-" +
        (dateEnd.getMonth() + 1) +
        "-" +
        dateEnd.getDate();
      dateRange["startDate"] = this.startDate;
      dateRange["endDate"] = this.endDate;
      availableDateRange = {
        availableDateRange: dateRange
      };
    }

    let days = this.days;
    let availableTimeSlots = {};
    let daysInfo = {};
    for (let day of days) {
      let name = day.name.toLowerCase();
      let isOpen = day.isOpen;
      let isOpenWholeDay = 1;
      if (isOpen == 0) {
        isOpenWholeDay = 0;
      }
      let isOpenForSpecificTimeRangesOnly = 0;
      if (isOpen == 2) {
        isOpenForSpecificTimeRangesOnly = 1;
      }
      let availableTimeRanges = day.timeRanges;
      let dayData = {
        isOpenWholeDay: isOpenWholeDay,
        isOpenForSpecificTimeRangesOnly: isOpenForSpecificTimeRangesOnly,
        availableTimeRanges: availableTimeRanges,
      };
      daysInfo[name] = dayData;
      availableTimeSlots[name] = dayData;
    }

    // var timeAvailability = {
    //   isAvailableFullTime: this.isAvailableFullTime,
    //   availableTimeSlots: availableTimeSlots,
    // };
    // var configTime = {
    //   dayAvailability: dayAvailability,
    //   timeAvailability: timeAvailability,
    // };
    let timings = {
      availableTimeSlots: availableTimeSlots,
      ...availableDateRange
    };
 
    let timeAvailability = {
      [field]: timings,
    };
    
    let data = {
      timeAvailability: timeAvailability,
    }; 
    this.ngLoader = true; 
    this.ApiService.updateRestaurantConfig(data).subscribe((data) => {
      this.ngLoader = false;
      this.displayErrorsAndSuccess(data);
    });
  }
  formatTime(time: string) {
    let timeArray = time.split(":");
    let formattedHours = Number(timeArray[0]).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    let formattedMinutes = Number(timeArray[1]).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return formattedHours + ":" + formattedMinutes;
  }
  onConfigSubmit() {
    this.ngLoader = true;
    this.submitted = true;
    if (this.restaurantConfigForm.invalid) {
      return;
      
    }
    this.ApiService.setupRestaurantsUpdate(
      this.restaurantId,
      this.restaurantConfigForm.value
    ).subscribe((result) => {
      this.displayErrorsAndSuccess(result);
      this.ngLoader = false;
    });
  }
}
