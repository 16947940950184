import { Component, OnInit, ViewChild } from "@angular/core";
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services/api.service";
import { ConfirmationDialogService } from "../../confirmation-dialog/confirmation-dialog.service";
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
  NgbInputDatepickerConfig,
} from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { customRegexValidator } from "src/app/helper/validator";
import { regEx } from "src/app/helper/constants";
import { ThisReceiver } from "@angular/compiler";
@Component({
  selector: "app-promo-code",
  templateUrl: "./promo-code.component.html",
  styleUrls: ["./promo-code.component.scss"],
})
export class PromoCodeComponent implements OnInit {
  promoCodeCreateFrom: FormGroup;
  submitted: boolean = false;
  clicked: boolean = false;
  modalReference: NgbModalRef;
  productList: any;
  showProductInputs: boolean = false;

  model: NgbDateStruct;
  discountList: any;
  promoCodeList: any = [];
  updateId: any;
  isModalTypeEdit: any;
  promocodeDetails: any;
  emiratesId: any;
  areaId: any;
  emiratesData: any;
  areaData: any;
  page = 1;
  perPage = 8;
  pagination: any;
  filterType: any;
  prmotionType: boolean;
  today = new Date();
  enablePromoLimit: any = false;
  ngLoader;
  id;
  startDateConfig: any = {
    customTodayClass: "today1",
    dateInputFormat: "DD-MM-YYYY",
    adaptivePosition: true,
  };
  endDateConfig: any = {
    dateInputFormat: "DD-MM-YYYY",
    adaptivePosition: true,
  };
  selectedDateConfig: any = {
    dateInputFormat: "DD-MM-YYYY",
    adaptivePosition: true,
  };
  startValueChanged: any = false;
  maxUseCodeAlert: any = false;
  autoApplyExist: any = false;
  autoApplyId: any = "";
  constructor(
    private ApiService: ApiService,
    private modalService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    config: NgbInputDatepickerConfig,
    private router: Router,
    calendar: NgbCalendar
  ) {
    this.promoCodeCreateFrom = this.fb.group({
      name: ["", [Validators.required]],
      promocode: [
        "",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(10),
          customRegexValidator({
            pattern: regEx.promoCodeRegEx,
            msg: "Invalid Promo Code",
          }),
        ],
      ],
      description: ["", [Validators.required]],
      value: ["", [Validators.required]],
      // restaurantId: ['', [Validators.required]],
      restaurantItemId: [""],
      startDate: [this.today, [Validators.required]],
      endDate: ["", [Validators.required]],
      discountType: ["flat", [Validators.required]],

      isDiscount: [false, [Validators.required]],
      isPromoCode: [true, [Validators.required]],
      isItemDiscount: [false, [Validators.required]],
      isSubTotalDiscount: [false, [Validators.required]],
      isDeliveryChargeDiscount: [false, [Validators.required]],

      prmotionAppliedOn: ["", [Validators.required]],
      isGeneralDiscount: [false],
      isCategoryDiscount: [false],
      promoMode: ["", [Validators.required]],
      maxDiscountAmount: ["", [Validators.required]],
      minOrderAmount: ["", [Validators.required]],
      hasUseCountLimit: [""],
      // maxUseCount: [1,[Validators.required,Validators.min(1)]],
      maxUseCount: [1],
      maxAllowedUsersCount: ["", [Validators.required]],
      allowSingleUserToUseMultipleTimes: [false],
      // emirate: ["", [Validators.required]],
      // deliveryAreaId: ["", [Validators.required]],
      // "restaurantItemCategoryId" : null,
    });
  }

  onAllowMultipleTimes() {
    console.log(
      this.promoCodeCreateFrom.value.allowSingleUserToUseMultipleTimes
    );
    console.log(this.promoCodeCreateFrom.value.maxUseCount <= 0);

    if (
      this.promoCodeCreateFrom.value.allowSingleUserToUseMultipleTimes &&
      this.promoCodeCreateFrom.value.maxUseCount <= 0
    ) {
      this.maxUseCodeAlert = true;
      console.log(this.promoCodeCreateFrom);
    } else {
      this.maxUseCodeAlert = false;
    }
  }
  onChangePrmotionType(e) {
    let type = e.target.value;
    if (type == "percentage") {
      this.prmotionType = true;
    } else if (type == "flat") {
      this.prmotionType = false;
    }
  }
  get f() {
    return this.promoCodeCreateFrom.controls;
  }
  onChangeEnablePromoLimit(event) {
    this.enablePromoLimit = event;

    if (event == "true") {
      this.enablePromoLimit = true;
    } else if (event == "false") {
      this.enablePromoLimit = false;
      this.promoCodeCreateFrom.patchValue({
        maxUseCount: 0,
      });
    }
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  open(content, modalId, type) {
    this.updateId = modalId;
    this.isModalTypeEdit = type;
    console.log("CHECK!!!!!!!!!!!!!!!!!!!!!!!!");
    console.log(content);
    console.log(modalId);
    console.log(type);

    this.modalReference = this.modalService.open(content, {
      size: "",
      centered: true,
      windowClass: "modal-custom",
    });
    this.modalReference.result.then(
      (result) => {},
      (reason) => {
        this.promoCodeCreateFrom.reset();
      }
    );
    if (this.isModalTypeEdit == "add") {
      this.promoCodeCreateFrom.patchValue({
        startDate: this.today,
      });
    }
    if (this.isModalTypeEdit == "update") {
      this.ngLoader = true;
      this.ApiService.getPromocodeDetails(modalId).subscribe((res: any) => {
        if (res.success == 1) {
          let promoDetails = res["restaurantOffer"];
          this.promocodeDetails =
            this.ApiService.addDatesFromUnixTs(promoDetails);
          // var deliveryAreaId = null;
          // var emirateId = null;
          // var deliveryArea = promoDetails.deliveryAreaId;
          // if (deliveryArea) {
          //   deliveryAreaId = deliveryArea.id;
          //   var emirate = deliveryArea.parentEmirateId;
          //   if (emirate) {
          //     emirateId = emirate.id;
          //   }
          // }
          // this.emiratesId = emirateId;
          // this.areaId = deliveryAreaId;
          //restaurantItemId
          this.promoCodeCreateFrom.patchValue({
            name: this.promocodeDetails.name,
          });
          this.promoCodeCreateFrom.patchValue({
            promocode: this.promocodeDetails.code,
          });
          this.promoCodeCreateFrom.patchValue({
            restaurantItemId: this.promocodeDetails.restaurantItemId,
          });
          this.promoCodeCreateFrom.patchValue({
            description: this.promocodeDetails.description,
          });
          this.promoCodeCreateFrom.patchValue({
            value: this.promocodeDetails.value,
          });
          this.promoCodeCreateFrom.patchValue({
            startDate: new Date(this.promocodeDetails.startDate),
          });
          this.promoCodeCreateFrom.patchValue({
            endDate: new Date(this.promocodeDetails.endDate),
          });
          this.promoCodeCreateFrom.patchValue({
            discountType: this.promocodeDetails.discountType,
          });
          this.promoCodeCreateFrom.patchValue({
            isDiscount: this.promocodeDetails.isDiscount,
          });
          this.promoCodeCreateFrom.patchValue({
            isPromoCode: this.promocodeDetails.isPromoCode,
          });
          this.promoCodeCreateFrom.patchValue({
            isItemDiscount: this.promocodeDetails.isItemDiscount,
          });
          this.promoCodeCreateFrom.patchValue({
            isSubTotalDiscount: this.promocodeDetails.isSubTotalDiscount,
          });
          this.promoCodeCreateFrom.patchValue({
            isDeliveryChargeDiscount:
              this.promocodeDetails.isDeliveryChargeDiscount,
          });
          this.promoCodeCreateFrom.patchValue({
            prmotionAppliedOn: this.promocodeDetails.prmotionAppliedOn,
          });
          this.promoCodeCreateFrom.patchValue({
            isGeneralDiscount: this.promocodeDetails.isGeneralDiscount,
          });
          this.promoCodeCreateFrom.patchValue({
            isCategoryDiscount: this.promocodeDetails.isCategoryDiscount,
          });
          this.promoCodeCreateFrom.patchValue({
            promoMode: this.promocodeDetails.promoMode,
          });
          this.promoCodeCreateFrom.patchValue({
            maxDiscountAmount: this.promocodeDetails.maxDiscountAmount,
          });
          this.promoCodeCreateFrom.patchValue({
            minOrderAmount: this.promocodeDetails.minOrderAmount,
          });
          this.promoCodeCreateFrom.patchValue({
            hasUseCountLimit: this.promocodeDetails.hasUseCountLimit,
          });
          this.promoCodeCreateFrom.patchValue({
            maxUseCount: this.promocodeDetails.maxUseCount,
          });
          this.promoCodeCreateFrom.patchValue({
            maxAllowedUsersCount: this.promocodeDetails.maxAllowedUsersCount,
          });
          // this.promoCodeCreateFrom.patchValue({ emirate: emirateId });
          // this.promoCodeCreateFrom.patchValue({
          //   deliveryAreaId: deliveryAreaId,
          // });
          this.promoCodeCreateFrom.patchValue({
            allowSingleUserToUseMultipleTimes:
              this.promocodeDetails.allowSingleUserToUseMultipleTimes,
          });
          if (this.promocodeDetails.prmotionAppliedOn === "isItemDiscount") {
            this.showProductInputs = true;
          } else {
            this.showProductInputs = false;
          }
          this.handleAreaList();
        }

        this.ngLoader = false;
      });
    }
  }

  selectEmirates(e) {
    this.emiratesId = e.target.value;
    this.handleAreaList();
  }

  selectArea(e) {
    this.areaId = e.target.value;
  }
  handleEmiratesList() {
    this.ngLoader = true;
    this.ApiService.getEmiratesList().subscribe((data) => {
      this.emiratesData = data["items"];
      this.ngLoader = false;
    });
  }
  handleAreaList() {
    this.ngLoader = true;
    this.ApiService.getAreaList(this.emiratesId).subscribe((data) => {
      this.areaData = data["items"];
      this.ngLoader = false;
    });
  }

  checkPrmotionAppliedOn(event) {
    if (event.target.value === "isItemDiscount") {
      this.showProductInputs = true;
    } else {
      this.showProductInputs = false;
    }
  }
  promoCodeFilter(type) {
    this.filterType = type;
    this.ngLoader = true;
    this.ApiService.getPromoCodeApi(type).subscribe(
      (res: any) => {
        this.promoCodeList = res["items"];
        this.pagination = res["pagination"];
        this.ngLoader = false;
      },
      (error) => {
        this.toastr.error(error.error.message);
      }
    );
  }

  handleRestaurantRegistration() {
    var token = localStorage.getItem("UserToken");
    var isLoggedIn = false;
    if (token) {
      isLoggedIn = true;
    }
    if (!isLoggedIn) {
      this.router.navigate(["login"]);
      return;
    }
    var id = localStorage.getItem("restaurantId");
    if (id) {
      id = JSON.parse(id);
    }
    if (id) {
      this.id = id;
    } else {
      this.router.navigate(["get-started"]);
      return;
    }
  }

  ngOnInit() {
    this.handleRestaurantRegistration();
    this.getProductListItem();
    this.getPromoCodeList();
    this.handleEmiratesList();
  }

  getProductListItem() {
    this.ngLoader = true;
    this.ApiService.getProductList("", "").subscribe(
      (res: any) => {
        this.ngLoader = false;
        this.productList = res["items"];
      },
      (error) => {
        this.ngLoader = false;
        this.toastr.error(error.error.message);
      }
    );
  }
  getPromoCodeList(type = null) {
    this.ngLoader = true;
    if (type) this.filterType = type;
    console.log(type);
    this.ApiService.getPromoCodeApi(type).subscribe(
      (res: any) => {
        this.ngLoader = false;
        let items = [];
        const records = res.items;
        console.log("promo Api...........");

        console.log(records);

        for (let record of records) {
          const item = this.ApiService.addDatesFromUnixTs(record);
          items.push(item);
        }

        for (let i = 0; i < items.length; i++) {
          let d = new Date();
          let currentTime = Math.floor(d.getTime() / 1000);
          console.log(items[i].endUnixTsSeconds < currentTime);

          if (
            items[i].promoMode == "autoApply" &&
            items[i].endUnixTsSeconds < currentTime
          ) {
            this.ApiService.updatePromocodeModeDetails(items[i].id, {
              promoMode: "open",
            }).subscribe(
              (res: any) => {
                console.log(res);
              },
              (error) => {
                this.toastr.error(error.error.message);
              }
            );
          }
        }

        this.promoCodeList = items;
        console.log(items);
        for (let i = 0; i < this.promoCodeList.length; i++) {
          if (this.promoCodeList[i].promoMode == "autoApply") {
            this.autoApplyExist = true;
            this.autoApplyId = this.promoCodeList[i].id;
            break;
          } else {
            this.autoApplyExist = false;
          }
        }
        this.pagination = res["pagination"];
      },
      (error) => {
        this.ngLoader = false;
        this.toastr.error(error.error.message);
      }
    );
  }

  onSubmit() {
    console.log(this.promoCodeCreateFrom);

    console.log(this.promoCodeCreateFrom.value.promoMode);

    if (
      this.promoCodeCreateFrom.value.promoMode == "autoApply" &&
      this.autoApplyExist &&
      this.autoApplyId != this.updateId
    ) {
      // prompt("cannot add auto apply");
      this.toastr.warning(`Auto Apply Already Exist !  `);
      return;
    }

    if (this.isModalTypeEdit == "update") {
      this.updatePromoCode();
    } else {
      if (!this.promoCodeCreateFrom.value.allowSingleUserToUseMultipleTimes) {
        this.promoCodeCreateFrom.patchValue({
          maxUseCount: 0,
        });
        console.log(this.promoCodeCreateFrom);
      }
      this.createPromoCode();
    }
  }

  enableDisable(customerId, type) {
    console.log(type);
    const body = {
      isActive: type ? false : true,
    };
    /* if (type != true) {
      body = {
        isActive: true,
      };
    } else {
      body = {
        isActive: false,
      };
    } */
    this.ngLoader = true;
    this.ApiService.updatePromocodeStatus(customerId, body).subscribe(
      (res: any) => {
        this.ngLoader = false;
        if (res.success == 1) {
          this.toastr.success(res.message);
          if (type) {
            this.ApiService.updatePromocodeModeDetails(customerId, {
              promoMode: "open",
            }).subscribe(
              (res: any) => {
                console.log(res);
              },
              (error) => {
                this.toastr.error(error.error.message);
              }
            );
          }
          this.getPromoCodeList();
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.ngLoader = false;
        this.toastr.error(error.error.message);
      }
    );
  }
  onDeletePromocode(id) {
    Swal.fire({
      title: "Delete",
      text: "Are you sure you want to delete",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#374abe",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.ngLoader = true;
        this.ApiService.deletePromoCode(id).subscribe((res: any) => {
          this.ngLoader = false;
          this.toastr.success(res.message);
          this.getPromoCodeList();
        });
      }
    });
  }

  createPromoCode() {
    this.submitted = true;
    this.clicked = true;
    console.log(this.maxUseCodeAlert);
    const promoCode = this.promoCodeCreateFrom.value.promocode;
    this.promoCodeCreateFrom.patchValue({
      promocode: promoCode?.toLowerCase(),
    });
    this.promoCodeCreateFrom.patchValue({ isDiscount: false });
    this.promoCodeCreateFrom.patchValue({ isPromoCode: true });
    let prmotionAppliedOn = this.promoCodeCreateFrom.value.prmotionAppliedOn;
    if (prmotionAppliedOn === "isItemDiscount") {
      this.promoCodeCreateFrom.patchValue({ isItemDiscount: true });
      this.promoCodeCreateFrom.patchValue({ isSubTotalDiscount: false });
      this.promoCodeCreateFrom.patchValue({ isDeliveryChargeDiscount: false });
      let restaurantItemId = this.promoCodeCreateFrom.value.restaurantItemId;
      if (!restaurantItemId) {
        this.f.restaurantItemId.setErrors({ required: true });
        return;
      }
    } else if (prmotionAppliedOn === "isSubTotalDiscount") {
      this.promoCodeCreateFrom.patchValue({ isItemDiscount: false });
      this.promoCodeCreateFrom.patchValue({ isSubTotalDiscount: true });
      this.promoCodeCreateFrom.patchValue({ isDeliveryChargeDiscount: false });
    } else if (prmotionAppliedOn === "isDeliveryChargeDiscount") {
      this.promoCodeCreateFrom.patchValue({ isItemDiscount: false });
      this.promoCodeCreateFrom.patchValue({ isSubTotalDiscount: false });
      this.promoCodeCreateFrom.patchValue({ isDeliveryChargeDiscount: true });
    }
    if (this.promoCodeCreateFrom.invalid) {
      this.clicked = false;
      this.getFormValidationErrors(this.promoCodeCreateFrom);
      return;
    }

    let data = this.promoCodeCreateFrom.value;
    data = this.ApiService.addUnixTsToRequestBody(data);
    this.ngLoader = true;
    this.ApiService.createPromoCodeApi(data).subscribe(
      (res: any) => {
        this.ngLoader = false;
        if (res.success == 1) {
          this.toastr.success(res.message);
          this.getPromoCodeList();
          this.modalReference.close();
          this.promoCodeCreateFrom.reset();
          this.submitted = false;
          this.clicked = false;
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.ngLoader = false;
        this.toastr.error(error.error.message);
      }
    );
  }
  getFormValidationErrors(form) {
    Object.keys(form.controls).forEach((key) => {
      const controlErrors: any = form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }
  updatePromoCode() {
    this.submitted = true;
    this.clicked = true;

    this.ApiService.deletePromoCode(this.updateId).subscribe((res: any) => {
      this.ngLoader = false;
      /* this.toastr.success(res.message); */
      this.createPromoCode();
    });
    /* let prmotionAppliedOn = this.promoCodeCreateFrom.value.prmotionAppliedOn;

    this.promoCodeCreateFrom.patchValue({ isDiscount: false });
    this.promoCodeCreateFrom.patchValue({ isPromoCode: true });
    if (prmotionAppliedOn === "isItemDiscount") {
      this.promoCodeCreateFrom.patchValue({ isItemDiscount: true });
      this.promoCodeCreateFrom.patchValue({ isSubTotalDiscount: false });
      this.promoCodeCreateFrom.patchValue({ isDeliveryChargeDiscount: false });
      let restaurantItemId = this.promoCodeCreateFrom.value.restaurantItemId;
      if (!restaurantItemId) {
        this.f.restaurantItemId.setErrors({ required: true });
        return;
      }
    } else if (prmotionAppliedOn === "isSubTotalDiscount") {
      this.promoCodeCreateFrom.patchValue({ isItemDiscount: false });
      this.promoCodeCreateFrom.patchValue({ isSubTotalDiscount: true });
      this.promoCodeCreateFrom.patchValue({ isDeliveryChargeDiscount: false });
    } else if (prmotionAppliedOn === "isDeliveryChargeDiscount") {
      this.promoCodeCreateFrom.patchValue({ isItemDiscount: false });
      this.promoCodeCreateFrom.patchValue({ isSubTotalDiscount: false });
      this.promoCodeCreateFrom.patchValue({ isDeliveryChargeDiscount: true });
    }
    if (this.promoCodeCreateFrom.invalid) {
      this.getFormValidationErrors(this.promoCodeCreateFrom);
      this.clicked = false;
      return;
    }
    let data = this.promoCodeCreateFrom.value;
    this.ngLoader = true;
    data = this.ApiService.addUnixTsToRequestBody(data);
    this.ApiService.updatePromocodeDetails(this.updateId, data).subscribe(
      (res: any) => {
        this.ngLoader = false;
        if (res.success == 1) {
          this.toastr.success(res.message);
          this.getPromoCodeList();
          this.modalReference.close();
          this.promoCodeCreateFrom.reset();
          this.submitted = false;
          this.clicked = false;
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.ngLoader = false;
        this.toastr.error(error.error.message);
      }
    ); */
  }

  dateChanged(e: any) {
    console.log(e.target);
  }
}
