import { Component, OnInit } from '@angular/core';
import { ApiService } from './services/api.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  title = 'foodbook-admin';
  restaurantId: any;
  userRoles: any;
 
  constructor( private ApiService: ApiService,private router: Router) { }

  ngOnInit() {

    // if( this.userRoles == 'marketing'){
    //   this.router.navigate(['promo-codes'])
    // } 
   
  }
  ngDoCheck(){
    if(localStorage.getItem('restaurantId')){
    //  console.log('restaurantId Yes app ')
     this.restaurantId = JSON.parse(localStorage.getItem('restaurantId'))
     this.ApiService.getRestaurantId(this.restaurantId)
     this.userRoles = JSON.parse(localStorage.getItem('userRoles'))
     this.ApiService.getUserRoles(this.userRoles)
    }

  }

}
