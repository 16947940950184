import { Injectable } from '@angular/core';
import { CanActivate,ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router,private ApiService:ApiService, private toastr: ToastrService){}

  // canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean{
  //   if(this.ApiService.loggedIn()){ 
  //         return true;
  //   }else{
  //     this.router.navigate(['/login']); 
  //     return false
  //   }
  // }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):  boolean {
      if (localStorage.getItem('UserToken') != null)
      {
        console.log("************************************* Roles are ")
        console.log(next.data["roles"])
        let roles = next.data["roles"] as Array<string>;
        console.log("Roles in auth guard is", roles);
        if (roles) {
          var match = this.ApiService.roleMatch(roles);
          console.log("Match", match);
          if (match) return true;
          else {
            console.log("In else of auth.guard")
            this.toastr.error('ACCESS DENIED, You dont have the permission to access this resource!');   
            this.router.navigate(['/notFound']);          
            // Swal.fire({
            //   icon: 'error',
            //   title: 'Oops...',
            //   text: 'ACCESS DENIED, You dont have the permission to access this resource!',
            // })
            return false;
          } 
        }
        else
          return true;
      }
      this.router.navigate(['/login']);
      return false;
  }
  

  
}
