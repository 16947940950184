import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import {
  customRegexValidator,
  requiredFileType,
  numberRangeValidator,
} from "../../helper/validator";
import { regEx } from "../../helper/constants";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
@Component({
  selector: "app-static-pages-form",
  templateUrl: "./static-pages-form.component.html",
  styleUrls: ["./static-pages-form.component.scss"],
})
export class StaticPagesFormComponent implements OnInit {
  aboutUsForm: FormGroup;
  othersForm: FormGroup;
  submitted: boolean = false;
  clicked: boolean = false;
  edit: boolean = false;
  restaurantData;
  aboutUsData;
  pageData;
  value;
  imagePath;
  imgURL;
  selectedFile;
  storeImage;
  imageBase;
  id;
  ngLoader;
  currentPageTitle;
  public Editor = ClassicEditor;
  restaurantId = JSON.parse(localStorage.getItem("restaurantId"));
  constructor(
    private fb: FormBuilder,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  handleRestaurantRegistration() {
    var token = localStorage.getItem("UserToken");
    var isLoggedIn = false;
    if (token) {
      isLoggedIn = true;
    }
    if (!isLoggedIn) {
      this.router.navigate(["login"]);
      return false;
    }
    var id = localStorage.getItem("restaurantId");
    if (id) {
      id = JSON.parse(id);
    }
    if (id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(["get-started"]);
      return false;
    }
    return true;
  }
  get aboutFormControls() {
    return this.aboutUsForm.controls;
  }
  get othersFormControls() {
    return this.othersForm.controls;
  }
  onTitleChange(searchValue: string): void {
    console.log("Slug change function called");
    let slugName = this.createSlug(searchValue);
    this.othersForm.patchValue({ slug: slugName });
  }
  createSlug(Text) {
    return Text.toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-");
  }
  ngOnInit() {
    //About Page Validations
    this.aboutUsForm = this.fb.group({
      homePageTitle: [
        "",
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(200),
          customRegexValidator({
            pattern: regEx.pageTitleRegEx,
            msg: "Invalid Characters",
          }),
        ],
      ],
      homePageDescription: [
        "",
        [
          Validators.required,
          Validators.minLength(5),
          // Validators.maxLength(500),
          Validators.maxLength(507),
        ],
      ],
      storeImage: ["", [requiredFileType(["png", "jpeg", "jpg"])]],
      cuisines: [
        "",
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(200),
          customRegexValidator({
            pattern: regEx.staticPageDescriptionRegEx,
            msg: "Invalid Characters",
          }),
        ],
      ],
      contactEmail: [
        "",
        [
          Validators.required,
          customRegexValidator({
            pattern: regEx.emailRegEx,
            msg: "Invalid Email Format",
          }),
        ],
      ],
      contactPhone: [
        "",
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(15),
          customRegexValidator({
            pattern: regEx.phoneNumberWithoutCodeRegEx,
            msg: "Invalid Phone Number",
          }),
        ],
      ],
      whatsappNumber: [
        "",
        [
          Validators.minLength(7),
          Validators.maxLength(15),
          customRegexValidator({
            pattern: regEx.phoneNumberWithoutCodeRegEx,
            msg: "Invalid Whatsapp Number",
          }),
        ],
      ],
      aboutPageTitle: [
        "",
        [
          customRegexValidator({
            pattern: regEx.staticPageDescriptionRegEx,
            msg: "Invalid Characters",
          }),
        ],
      ],
      aboutPageDescription: [
        "",
        [
          customRegexValidator({
            pattern: regEx.staticPageDescriptionRegEx,
            msg: "Invalid Characters",
          }),
        ],
      ],
      aboutPageKeywords: [
        "",
        [
          customRegexValidator({
            pattern: regEx.staticPageDescriptionRegEx,
            msg: "Invalid Characters",
          }),
        ],
      ],
    });
    //Static Page and Validations
    this.othersForm = this.fb.group({
      title: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          customRegexValidator({
            pattern: regEx.pageTitleRegEx,
            msg: "Invalid Title",
          }),
        ],
      ],
      slug: [
        { value: "" },
        // {value: '', disabled: true},
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          customRegexValidator({
            pattern: regEx.slugRegEx,
            msg: "Invalid Slug",
          }),
        ],
      ],
      // htmlContent: ["", [Validators.required]],
      htmlContent: ["", [Validators.required, Validators.maxLength(507)]],
      // htmlContent: ["", [Validators.required,Validators.maxLength(500)]],
      //image: [Validators.required, requiredFileType(["png", "jpeg", "jpg"])],
      seoTitle: [""],
      seoDescription: [""],
      seoKeywords: [""],
    });
    var ok = this.handleRestaurantRegistration();
    if (!ok) {
      return;
    }
    this.value = this.route.snapshot.paramMap.get("value");
    console.log(this.value);
    if (this.value == "about") {
      this.currentPageTitle = "About us";
      this.getRestaurantData();
    } else if (this.value == "others") {
      this.edit = false;
      this.currentPageTitle = "Create";
    } else {
      this.edit = true;
      this.getPageDetails();
    }
  }

  preview(event) {
    if (event.target.files.length === 0) return;
    this.selectedFile = event.target.files[0];
    this.aboutUsForm.patchValue({ storeImage: this.selectedFile });
    var mimeType = this.selectedFile.type;
    if (mimeType.match(/image\/*/) == null) {
      this.toastr.warning("Only images are supported");
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }

  displayErrorsAndSuccess(result) {
    if (result["success"] == 1) {
      this.toastr.success(result.message);
    } else {
      this.toastr.warning(result.message);
    }
  }

  getPageDetails() {
    this.ngLoader = true;
    this.ApiService.getPageDetails(this.value).subscribe((result) => {
      this.pageData = result["item"];
      this.currentPageTitle = this.pageData.title;
      this.othersForm.patchValue({
        title: this.pageData.title,
        slug: this.pageData.slug,
        htmlContent: this.pageData.htmlContent,
        seoTitle: this.pageData.seoTitle,
        seoDescription: this.pageData.seoDescription,
        seoKeywords: this.pageData.seoKeywords,
      });
      this.ngLoader = false;
    });
  }

  getRestaurantData() {
    this.ngLoader = true;
    this.ApiService.getRestaurantsSetup(this.restaurantId).subscribe(
      (result) => {
        console.log("Data is ");
        console.log(JSON.stringify(result));
        this.imageBase = result["imageBase"];
        this.restaurantData = result["item"];
        this.storeImage = this.restaurantData.storeImage;
        var seoHomePageData = this.restaurantData.seoHomePageMeta;
        var homePageTitle = null;
        var homePageDescription = null;
        if (seoHomePageData) {
          homePageTitle = seoHomePageData.pageTitle
            ? seoHomePageData.pageTitle
            : null;
          homePageDescription = seoHomePageData.pageDescription
            ? seoHomePageData.pageDescription
            : null;
        }

        var seoAboutPageData = this.restaurantData.seoAboutPageMeta;
        var aboutPageTitle = null;
        var aboutPageDescription = null;
        var aboutPageKeywords = null;
        if (seoAboutPageData) {
          aboutPageTitle = seoAboutPageData.aboutPageTitle
            ? seoAboutPageData.aboutPageTitle
            : null;
          aboutPageDescription = seoAboutPageData.aboutPageDescription
            ? seoAboutPageData.aboutPageDescription
            : null;
          aboutPageKeywords = seoAboutPageData.aboutPageKeywords
            ? seoAboutPageData.aboutPageKeywords
            : null;
        }
        this.aboutUsForm.patchValue({
          homePageTitle: this.restaurantData.seoHomePageMeta.pageTitle || "",
          homePageDescription:
            this.restaurantData.seoHomePageMeta.pageDescription || "",
          cuisines: this.restaurantData.cuisines,
          contactEmail: this.restaurantData.contactEmail,
          contactPhone: this.restaurantData.contactPhone,
          whatsappNumber: this.restaurantData.whatsappNumber,
          aboutPageTitle: aboutPageTitle,
          aboutPageDescription: aboutPageDescription,
          aboutPageKeywords: aboutPageKeywords,
        });
        this.ngLoader = false;
      }
    );
  }

  onAboutSubmit() {
    this.submitted = true;
    this.clicked = true;
    if (this.aboutUsForm.invalid) {
      console.log("Form has errors...");

      this.clicked = false;
      return;
    }
    const formData = new FormData();

    formData.append("homePageTitle", this.aboutUsForm.value.homePageTitle);
    formData.append(
      "homePageDescription",
      this.aboutUsForm.value.homePageDescription
    );
    formData.append("cuisines", this.aboutUsForm.value.cuisines);
    formData.append("contactEmail", this.aboutUsForm.value.contactEmail);
    formData.append("contactPhone", this.aboutUsForm.value.contactPhone);
    formData.append("whatsappNumber", this.aboutUsForm.value.whatsappNumber);
    formData.append("aboutPageTitle", this.aboutUsForm.value.aboutPageTitle);
    formData.append(
      "aboutPageDescription",
      this.aboutUsForm.value.aboutPageDescription
    );
    formData.append(
      "aboutPageKeywords",
      this.aboutUsForm.value.aboutPageKeywords
    );
    formData.append("storeImage", this.aboutUsForm.get("storeImage").value);

    console.log(formData);
    this.ngLoader = true;
    this.ApiService.setupRestaurantsUpdate(
      this.restaurantId,
      formData
    ).subscribe((result) => {
      this.displayErrorsAndSuccess(result);
      this.ngLoader = false;
      if (result["success"]) {
        this.router.navigate(["/static-pages"]);
      }
    });
  }

  onOthersSubmit() {
    this.submitted = true;
    this.clicked = true;
    console.log(this.othersForm);
    // console.log( this.othersFormControls.htmlContent.errors.maxlength.actualLength);

    if (this.othersForm.invalid) {
      console.log("Form is invalid...");
      this.clicked = false;
      return;
    }
    this.ngLoader = true;
    if (this.edit) {
      this.ApiService.updatePageData(
        this.value,
        this.othersForm.value
      ).subscribe((result) => {
        this.displayErrorsAndSuccess(result);
        this.ngLoader = false;
        this.router.navigate(["/static-pages"]);
      });
    } else {
      console.log(this.othersForm);

      this.ApiService.createPage(
        this.restaurantId,
        this.othersForm.value
      ).subscribe((result) => {
        this.displayErrorsAndSuccess(result);
        this.ngLoader = false;
        if (result["success"]) {
          this.router.navigate(["/static-pages"]);
        }
      });
    }
  }
}
