import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { GetStartComponent } from './pages/get-start/get-start.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { OrderListComponent } from './pages/order-list/order-list.component';
import { ProductsListComponent } from './pages/products-list/products-list.component';
import { CatalogueComponent } from './pages/catalogue/catalogue.component';
import { CancellationComponent } from './pages/cancellation/cancellation.component';
import { DeliveryComponent } from './pages/delivery/delivery.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { CreateOrderComponent } from './pages/create-order/create-order.component';
import { PaymentGatewayComponent } from './pages/payment-gateway/payment-gateway.component';
import { SmsGatewayComponent } from './pages/sms-gateway/sms-gateway.component';
import { ManageCityComponent } from './pages/manage-city/manage-city.component';
import { UserRolesComponent } from './pages/user-roles/user-roles.component';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { SeoComponent } from './pages/seo/seo.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { DynamicScriptComponent } from './pages/dynamic-script/dynamic-script.component';
import { StaticPagesComponent } from './pages/static-pages/static-pages.component';
import { StaticPagesFormComponent } from './pages/static-pages-form/static-pages-form.component';
import { EditOrderComponent } from './pages/edit-order/edit-order.component';
import { PromoCodeComponent } from './pages/promo-code/promo-code.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { CustomersEditComponent } from './pages/customers-edit/customers-edit.component';
import { CustomersDetailsComponent } from './pages/customers-details/customers-details.component';
import { DomainComponent } from './pages/domain/domain.component';
import { DiscountComponent } from './pages/discount/discount.component';
import { RestaurantComponent } from './pages/restaurant/restaurant.component';
import { ImportCatalogueComponent } from './pages/import-catalogue/import-catalogue.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { OrderSummaryComponent } from './pages/order-summary/order-summary.component';
import { HomeComponent } from './pages/home/home.component';
import { RestaurantListComponent } from './pages/restaurant-list/restaurant-list.component';
import { AreaListComponent } from './pages/area-list/area-list.component';
import { VerifyRestaurantComponent } from './pages/verify-restaurant/verify-restaurant.component';



const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:id', component: ResetPasswordComponent },
  { path: 'register', component: RegistrationComponent },

  //GENERAL
  { path: 'verify-restaurant/:token', component: VerifyRestaurantComponent },
  { path: 'get-start', component: GetStartComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin'] } },
  { path: 'get-started', component: GetStartComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin'] } },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin'] } },
  { path: 'order-list', component: OrderListComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin', 'orderView'] } },
  { path: 'orders', component: OrderListComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin', 'orderView'] } },
  { path: 'order-create', component: CreateOrderComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin', 'orderView'] } },
  // { path: 'order-edit/:id', component: EditOrderComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin', 'orderView'] } },
  
  { path: 'order-edit/:id', component: CreateOrderComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin', 'orderView'] } },
  { path: 'order-summary/:id', component: OrderSummaryComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin', 'orderView'] } },
  { path: 'products-list', component: ProductsListComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin', 'products'] } },
  { path: 'customers', component: CustomersComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin', 'customers'] } },
  { path: 'customers-edit/:id', component: CustomersEditComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin', 'customers'] } },
  { path: 'customers-details/:id', component: CustomersDetailsComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin', 'customers'] } },
  { path: 'restaurant', component: RestaurantComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin'] } },

  //CONFIGURE
  { path: 'users', component: UserRolesComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'configuration'] } },
  { path: 'import-catalogue', component: ImportCatalogueComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'configuration'] } },
  { path: 'catalogue', component: CatalogueComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'configuration'] } },
  { path: 'cancellation', component: CancellationComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'configuration'] } },
  { path: 'delivery', component: DeliveryComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'configuration'] } },
  { path: 'checkout', component: CheckoutComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'configuration'] } },
  { path: 'emirates', component: ManageCityComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'configuration'] } },
  { path: 'emirates/:id/areas', component: AreaListComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'configuration'] } },

  //MARKETING
  { path: 'promo-codes', component: PromoCodeComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'marketing'] } },
  { path: 'discounts', component: DiscountComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'marketing'] } },
  { path: 'seo', component: SeoComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'marketing'] } },
  { path: 'analytics', component: AnalyticsComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'marketing'] } },
  { path: 'dynamic-script', component: DynamicScriptComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'marketing'] } },

  //GATEWAY
  { path: 'payment-gateway', component: PaymentGatewayComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin', 'configuration'] } },
  { path: 'sms-gateway', component: SmsGatewayComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin', 'configuration'] } },
  { path: 'static-pages', component: StaticPagesComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin', 'configuration'] } },
  { path: 'static-pages-form/:value', component: StaticPagesFormComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin', 'configuration'] } },

  //ACCOUNT
  { path: 'domain', component: DomainComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin'] } },
  { path: 'settings', component: AccountSettingsComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'subAdmin'] } },

  //SUPER ADMIN
  { path: 'restaurant-list', component: RestaurantListComponent, canActivate: [AuthGuard], data: { roles: ['superadmin', 'admin'] } },
  { path: 'notFound', component: NotFoundComponent, canActivate: [AuthGuard] },
  { path: "**", redirectTo: "notFound" },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
