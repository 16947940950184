import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-static-pages',
  templateUrl: './static-pages.component.html',
  styleUrls: ['./static-pages.component.scss']
})
export class StaticPagesComponent implements OnInit {
  restaurantId = JSON.parse(localStorage.getItem('restaurantId'));
  itemsArray;
  pageId;
  id;
  ngLoader;
  constructor(private router: Router, private route: ActivatedRoute, private ApiService: ApiService, private toastr: ToastrService) { }

  handleRestaurantRegistration() {
    var token =  localStorage.getItem('UserToken');
    var isLoggedIn = false;
    if(token) {
      isLoggedIn = true;
    }
    if(!isLoggedIn) { 
      this.router.navigate(['login']);
      return false;
    }
    var id = localStorage.getItem('restaurantId'); 
    if(id) {
      id = JSON.parse(id);
    }
    if(id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(['get-started']);
      return false;
    }
    return true;
  }
  ngOnInit() {
    var ok = this. handleRestaurantRegistration();
    if(!ok) return; 
    this.getList();
  }

  displayErrorsAndSuccess(result) {
    if(result['success'] == 1) {
      this.toastr.success(result.message);
      } else {
      this.toastr.warning(result.message)
      }
  }

  aboutUs(value) {
    console.log('About us clicked');
    this.router.navigate(['/static-pages-form', value]);
  }

  privacy(value) {
    this.router.navigate(['/static-pages-form', value]);
  }

  getList() {
    this.ngLoader =  true;
    this.ApiService.getPageList(this.restaurantId).subscribe(result => {
      this.itemsArray = result['items'];
      this.ngLoader =  false;
    })
  }

  delete(id) {
   this.pageId = id;
   Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.value) {
     this.confirmDelete();
    }
  })
  }

  confirmDelete() {
    this.ngLoader =  true;
    this.ApiService.deletePage(this.pageId).subscribe(result => {
      this. displayErrorsAndSuccess(result);
      this.ngLoader =  false;
      this.getList();
    })
  }

}
