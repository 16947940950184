import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-cancellation',
  templateUrl: './cancellation.component.html',
  styleUrls: ['./cancellation.component.scss']
})
export class CancellationComponent implements OnInit {
  cancellationForm: FormGroup;
  cancellation: boolean = false;
  cancellationReasonArray = [];
  cancellationReasonPredefinedArray = [];
  selectedReason;
  isClicked = [];
  updateData = {};
  custom: boolean = false;
  preDefined: boolean = false;
  reasons;
  restaurantData;
  id = JSON.parse(localStorage.getItem('restaurantId'));
  ngLoader;
  
  

  handleRestaurantRegistration() {
    var token =  localStorage.getItem('UserToken');
    var isLoggedIn = false;
    if(token) {
      isLoggedIn = true;
    }
    if(!isLoggedIn) { 
      this.router.navigate(['login']);
      return false;
    }
    var id = localStorage.getItem('restaurantId'); 
    if(id) {
      id = JSON.parse(id);
    }
    if(id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(['get-started']);
      return false;
    }
    return true;
  } 
  constructor(private fb: FormBuilder, private ApiService: ApiService, private toastr: ToastrService,  private router: Router) { }

  ngOnInit() {
    console.log("Calling handle restaurant reg.");
    var ok = this.handleRestaurantRegistration();
    this.cancellationForm = this.fb.group({
      cancellationReason: [''],
    })
    if(!ok) {
      console.log("Not ok");
      return;
    } else {
      console.log("Ok");
    }
    this.cancellationReasonPredefinedArray.push('Delivery time was more than 90 minutes', 'Wrong Products', 'Package was damaged', 'Order placed by mistakes', 'Out of delivery zone', 'Can’ t afford', 'Others');
    this.getRestaurantData();
  }

  getRestaurantData() {
    this.ngLoader = true;
    this.ApiService.getRestaurantsSetup(this.id).subscribe(result => {
      this.restaurantData = result['item'];
      console.log(this.restaurantData);
      this.reasons = this.restaurantData.cancellationReasons;
      if(this.restaurantData.cancellationReasonType == 'predefined') {
        this.preDefined = true;
      }
      this.ngLoader = false;
    })
  }

  toggleCancellationReason(event) {
   this.cancellation = event.target.checked;
   if(this.cancellation) {
    this.custom = true;
   } else {
     this.custom = false;
   }
  }

  onSubmit() {
    this.cancellationReasonArray.push(this.cancellationForm.value);
  }

  deleteReason(index) {
    this.cancellationReasonArray.splice(index,1)
  }

  selectReason(i) {
    // this.isClicked[i] = (this.isClicked[i] ? false : true);
  }

  saveData() {
   if(this.custom) {
     this.updateData['cancellationReasons'] = this.cancellationReasonArray;
     this.updateData['cancellationReasonType'] = 'custom';
   } else {
     this.updateData['cancellationReasons'] = this.cancellationReasonPredefinedArray;
     this.updateData['cancellationReasonType'] = 'predefined';
   }
   
   this.ngLoader = true;
   this.ApiService.setupRestaurantsUpdate(this.id, this.updateData).subscribe(result => {
    this.ngLoader = false;
    this.toastr.success('Successfully Updated');
   })
  }

}
