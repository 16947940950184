import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.scss']
})
export class EditOrderComponent implements OnInit {
  createCustomerForm: FormGroup;
  createOrderForm: FormGroup;
  modalReference: NgbModalRef;
  submitted: boolean = false;
  clicked: boolean = false;
  searchResult: any;
  selectedcustomerName: boolean;
  toggleContainer: boolean;
  setDeliveryMethod: any;
  setOrderMethod: any;
  searchResultProducts: boolean;
  imageBase: any;
  productItems: any;
  productAddons: any;
  resTableList: any;
  emiratesId: any;
  areaData: any;
  emiratesData: any;
  areaId: any;
  foodCount = 1
  foodId: any;
  modalQuantity: any;
  count: number = 1
  modalTotalAmount: number = 0;
  productTotalAmount:number = 0;
  isChecked;
  isCheckedName;
  addOns = []
  pickedProducts = []
  restaurantItemId: string;
  quantity: number;
  valueConut: any;
  selectedcustomerPhone: any;
  showDeliveryInputs: boolean = false;
  showTableInputs: boolean = false;
  selectedProducts: any;
  pageId: any;
  getOrderDetail: any;

  constructor(
    private ApiService: ApiService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router,
    private route: ActivatedRoute,) { }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  open(content, modalCategoryId, modalType) {

    // console.log('modal CategoryId : ' + modalCategoryId)
    // console.log('modal modalType : ' + modalType)

    this.modalReference = this.modalService.open(content, { size: '', centered: true, windowClass: 'modal-custom' });
    this.modalReference.result.then((result) => {

    }, (reason) => {
      // console.log('closemodal')
    });

    if (modalType === "add_product") {
      this.modalQuantity = 1

      this.searchResultProducts = false
      this.ApiService.getProductdetails(modalCategoryId).subscribe((res: any) => {
      //  console.log(res);
        if (res.success == 1) {
          this.imageBase = res.imageBase
          this.productItems = res['item']
          this.modalTotalAmount = parseFloat(this.productItems.sellingPrice)
          this.productAddons = this.productItems['addOns']

        }
      })
    }
  }

  get f() {
    return this.createCustomerForm.controls;
  }

  get f2() {
    return this.createOrderForm.controls;
  }

  ngOnInit() {
    this.getTableList()
    this.handleEmiratesList()
    this.handlePageEdit()

    this.createCustomerForm = this.fb.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.minLength(9)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', [Validators.required, Validators.minLength(4)]],
    })
    
    this.createOrderForm = this.fb.group({
      userId: ['', [Validators.required]],
      items: ['',],
      orderNotes: ['',],
      deliveryAddress: ['',],
      deliveryAreaId: ['',],

      isDineIn: [false,],
      isHomeDelivery: [false,],
      isTakeAway: [false,],

      isCashOnDelivery: [false,],
      isCardOnDelivery: [false,],
      isOnlinePayment: [false,],

      orderMethod: ['', [Validators.required]],
      deliveryMethod: ['', [Validators.required]],
      deliveryOption: ['',],
      tableName: ['', ],
      productInput: [''],      

    })
  }

//////////////////////////////////////////////////////////  EDIT ORDER  START HERE..  ////////////////////////////////////////////////////////////////////////////////

  handlePageEdit() {
    this.pageId = this.route.snapshot.paramMap.get('id')     
    this.ApiService.getOrderDetails(this.pageId).subscribe((res:any)=>{
      console.log(res);
      if (res.success == 1) {
        this.getOrderDetail = res['orderData']
         this.getOrderDetail.id 
        if ( this.getOrderDetail.isCashOnDelivery == true) {
          this.createOrderForm.get('orderMethod').patchValue('isCashOnDelivery');
        }
        else if ( this.getOrderDetail.isCardOnDelivery == true) {
          this.createOrderForm.get('orderMethod').patchValue('isCardOnDelivery');
        }
        else if ( this.getOrderDetail.isOnlinePayment == true) {
          this.createOrderForm.get('orderMethod').patchValue('isOnlinePayment');
        }

        if ( this.getOrderDetail.isDineIn == true) {
          this.createOrderForm.get('deliveryMethod').patchValue('isDineIn');
          this.showTableInputs = true
        }
        else if ( this.getOrderDetail.isTakeAway == true) {
          this.createOrderForm.get('deliveryMethod').patchValue('isTakeAway');
        }
        else if ( this.getOrderDetail.isHomeDelivery == true) {
          this.createOrderForm.get('deliveryMethod').patchValue('isHomeDelivery');
          this.showDeliveryInputs = true
        }

        this.createOrderForm.patchValue({ userId:  this.getOrderDetail.userId.id }); 
        this.selectedcustomerName =  this.getOrderDetail.userId.name 
        this.createOrderForm.get('tableName').patchValue( this.getOrderDetail.tableName); 
        this.createOrderForm.patchValue({ orderNotes:  this.getOrderDetail.orderNotes }); 
        this.pickedProducts =  this.getOrderDetail['items']

      }
    })
  }
 
  
/////////////////////////////////////////////////////////////   EDIT ORDER END HERE..  ////////////////////////////////////////////////////////////////////////////////


  selectEmirates(e) {
    this.emiratesId = e.target.value
    this.handleAreaList()
  }

  selectArea(e) {
    this.areaId = e.target.value
  }
  handleEmiratesList() {
    this.ApiService.getEmiratesList().subscribe((data) => {
      this.emiratesData = data['items']
    })
  }
  handleAreaList() {
    this.ApiService.getAreaList(this.emiratesId).subscribe((data) => {
      this.areaData = data['items']
    })
  }

  getTableList() {
    this.ApiService.tableListApi().subscribe((res: any) => {
      this.resTableList = res['items']
    })
  }

  onSearchCustomer(searchValue: string): void {
    if (searchValue != "") {
      this.ApiService.searchCustomerApi(searchValue).subscribe((res: any) => {
        this.searchResult = res['items']
      })
    } else if (searchValue === "") {
      this.searchResult = false
    }
  }

  onSearchProducts(searchValue: string): void {
    if (searchValue != "") {
      this.ApiService.getProductList('', searchValue).subscribe((res: any) => {
        this.imageBase = res.imageBase
        this.searchResultProducts = res['items']
      })
    } else if (searchValue === "") {
      this.searchResultProducts = false
    }
  }


  selectCustomer(customerId, customerName, phone) {
    this.createOrderForm.patchValue({ userId: customerId });
    this.selectedcustomerName = customerName
    this.selectedcustomerPhone = phone
    this.searchResult = false
  }

  onSubmitCreateCustomer() {

    this.submitted = true;
    this.clicked = true;
    if (this.createCustomerForm.invalid) {
      this.clicked = false;
      return;
    }


    this.ApiService.createCustomerApi(this.createCustomerForm.value).subscribe((res: any) => {
      if (res.success == 1) {
        this.toastr.success(res.message);
        this.toggleContainer = false
      } else {
        this.toastr.warning(res.message);
      }
    }, error => {
      this.toastr.error(error.error.message);
    })

  }

  checkdeliveryMethod(event){
 
    if (event.target.value === 'isHomeDelivery') {
       this.showDeliveryInputs = true
    }else{
      this.showDeliveryInputs = false
    } 
    if (event.target.value === 'isDineIn') {
      this.showTableInputs = true
    }else{
      this.showTableInputs = false
    }

  }
  onSubmitCreateOrder() {

    this.setDeliveryMethod = this.createOrderForm.value.deliveryMethod
    this.setOrderMethod = this.createOrderForm.value.orderMethod 

    if (this.setDeliveryMethod === 'isDineIn') {
      this.createOrderForm.patchValue({ isDineIn: true });
      this.createOrderForm.patchValue({ isTakeAway: false });
      this.createOrderForm.patchValue({ isHomeDelivery: false });
    }
    else if (this.setDeliveryMethod === 'isTakeAway') {
      this.createOrderForm.patchValue({ isDineIn: false });
      this.createOrderForm.patchValue({ isTakeAway: true });
      this.createOrderForm.patchValue({ isHomeDelivery: false });
    }
    else if (this.setDeliveryMethod === 'isHomeDelivery') {
      this.createOrderForm.patchValue({ isDineIn: false });
      this.createOrderForm.patchValue({ isTakeAway: false });
      this.createOrderForm.patchValue({ isHomeDelivery: true });
    }
    if (this.setOrderMethod === 'isCashOnDelivery') {
      this.createOrderForm.patchValue({ isCashOnDelivery: true });
      this.createOrderForm.patchValue({ isCardOnDelivery: false });
      this.createOrderForm.patchValue({ isOnlinePayment: false });
    }
    else if (this.setOrderMethod === 'isCardOnDelivery') {
      this.createOrderForm.patchValue({ isCashOnDelivery: false });
      this.createOrderForm.patchValue({ isCardOnDelivery: true });
      this.createOrderForm.patchValue({ isOnlinePayment: false });
    }
    else if (this.setOrderMethod === 'isOnlinePayment') {
      this.createOrderForm.patchValue({ isCashOnDelivery: false });
      this.createOrderForm.patchValue({ isCardOnDelivery: false });
      this.createOrderForm.patchValue({ isOnlinePayment: true });
    }

    this.createOrderForm.patchValue({ items:  this.pickedProducts });

    this.submitted = true;
    this.clicked = true;
    if (this.createOrderForm.invalid) {
      this.clicked = false;
      return;
    }
    let pageId = this.getOrderDetail.id
    let formData = this.createOrderForm.value

console.log(formData)
    console.log('Updating order details now...');
    this.ApiService.updateOrderDetails( pageId,formData).subscribe((res: any) => {
      if (res.success == 1) {
        this.toastr.success(res.message); 
        this.toggleContainer = false
      } else {
        this.toastr.warning(res.message);
      }
      
    }, error => {
      this.toastr.error(error.error.message);
      console.log(error.error.errors);
    })



  }

  addonsSelectOne(index, addonId, addonName, optionId, optionName, qty, event, price) {
    this.isChecked = !this.isChecked;
    this.isCheckedName = event.target.name;
    price = parseFloat(price);
    let addonObj = {}
    let optionObj = {}
    addonObj = {
      id: addonId,
      name: addonName,
      options: []
    }
    optionObj = {
      id: optionId,
      name: optionName,
      quantity: qty
    }
    addonObj['options'] = []
    addonObj['options'].push(optionObj);
    let addonIndex = this.addOns.findIndex(x => x.id === addonId);
    if (addonIndex > -1) {
      let isOld = false;
      let indexOfAddon = this.productItems.addOns.findIndex(x => x._id === addonId);
      if(indexOfAddon > -1){      
    
      let optionsArray = this.addOns[addonIndex].options;
      let oldOptionId = optionsArray[0].id;
      let oldOptionIndex =  this.productItems.addOns[indexOfAddon].options.findIndex(x => x._id === oldOptionId)
      if(oldOptionIndex > -1){
      let oldPrice =  this.productItems.addOns[indexOfAddon].options[oldOptionIndex].price;
    
      if(oldOptionId === optionId){
        this.modalTotalAmount = this.modalTotalAmount - oldPrice;
        isOld = true;
      }else{
        this.modalTotalAmount = this.modalTotalAmount - oldPrice;
        this.modalTotalAmount = this.modalTotalAmount + price;
      }    

      }
    }
    this.addOns.splice(addonIndex, 1);
    if(!isOld){
    this.addOns.push(addonObj);
    }
    } else {
      this.modalTotalAmount = this.modalTotalAmount + price
      this.addOns.push(addonObj);
    }
  }

  modifyQty(i, j, type, addonId, addonName, optionId, optionName,price) {
   price = parseFloat(price);

    let indexOfAddon = this.productItems.addOns.findIndex(x => x._id === addonId);
    let maxOptionCount = this.productItems.addOns[indexOfAddon].maxAllowedOptionsCount;

    var addonIndex = this.addOns.findIndex(x => x.id === addonId);
    if (addonIndex > -1) {
      var mainAddon = {};
      mainAddon = this.addOns[addonIndex];
      var indexOfOption = mainAddon['options'].findIndex(x => x.id === optionId);
      if (indexOfOption > -1) {
        let qty = parseInt(mainAddon['options'][indexOfOption].quantity);
        let isHigherMaximumQty = this.checkQuantity(mainAddon['options'], type, maxOptionCount);
        if (!isHigherMaximumQty) {
          if (type === 'plus') {
            qty = qty + 1;
            this.modalTotalAmount = this.modalTotalAmount + price            
          } else {
            qty = qty - 1;
            if(qty >= 0){
              this.modalTotalAmount = this.modalTotalAmount - price
            }
          }
          if (qty >= 0) {
            this.productAddons[i].options[j].quantity = qty;
            this.addOns[addonIndex].options[indexOfOption].quantity = qty;
            if (qty === 0) {
              if (this.addOns[addonIndex].options.length > 1) {
                this.addOns[addonIndex].options.splice(indexOfOption, 1)

              } else {
                this.addOns.splice(addonIndex, 1)
              }
            }
          }
        }
      } else {
        if (type === 'plus') {
          let isHigherMaximumQty = this.checkQuantity(mainAddon['options'], type, maxOptionCount);
          if (!isHigherMaximumQty) {
            let optionObj = {
              id: optionId,
              name: optionName,
              quantity: 1
            }
            this.addOns[addonIndex].options.push(optionObj);
            this.productAddons[i].options[j].quantity = 1;
            this.modalTotalAmount = this.modalTotalAmount + price
          }
        }
      }
    } else {
      if (type === 'plus') {
        let addonObj = {}
        let optionObj = {}
        addonObj = {
          id: addonId,
          name: addonName,
          options: []
        }
        optionObj = {
          id: optionId,
          name: optionName,
          quantity: 1
        }
        addonObj['options'] = []
        addonObj['options'].push(optionObj);
        this.addOns.push(addonObj);
        this.productAddons[i].options[j].quantity = 1;
        this.modalTotalAmount = this.modalTotalAmount + price

      }
    }
    this.valueConut = this.productAddons[i].options[j].quantity
    
  }

  checkQuantity(options, type, maxOptionCount) {
    let qty = 0;
    for (let i = 0; i < options.length; i++) {
      qty = qty + parseInt(options[i].quantity)
    }
    if (type === 'plus') {
      qty = qty + 1;
    } else {
      qty = qty - 1;
    }
    // console.log(qty + " qty")

    if (maxOptionCount < qty) {
      return true;
    } else {
      return false;
    }
  }


  changeQtyModal(changeVal, addedItem, sellingPrice) {
    this.modalQuantity = 1;     
    if (changeVal === 'plus') {      
      if (this.count != 100) {       
        this.count++;
        this.modalQuantity = this.count;
        this.modalTotalAmount = this.modalTotalAmount + parseInt(sellingPrice)
      }
    }
    else {
      if (this.count != 1) {
        this.count--;
        this.modalQuantity = this.count;
        this.modalTotalAmount = this.modalTotalAmount - parseInt(sellingPrice)
      }
    }
    console.log( this.modalQuantity)
  }

  modalAddProduct(productId, name, price, qty, productTotalPrice) {
    let mainAddonIndex = this.pickedProducts.findIndex(x => x.restaurantItemId === productId);

    if (mainAddonIndex > -1) {
      this.pickedProducts.splice(mainAddonIndex, 1);
      let pobj = {}
        pobj["restaurantItemId"] = productId;
        pobj["productName"] = name;
        pobj["productPrice"] = price;
        pobj["quantity"] = qty;
        pobj["totalPrice"] = productTotalPrice;
        pobj["addOns"] = this.addOns;
      this.pickedProducts.push(pobj);
    } else {
      let pobj = {}
        pobj["restaurantItemId"] = productId;
        pobj["productName"] = name;
        pobj["productPrice"] = price;
        pobj["quantity"] = qty;
        pobj["totalPrice"] = productTotalPrice;
        pobj["addOns"] = this.addOns;

      this.pickedProducts.push(pobj);
    }    
  //  console.log( this.pickedProducts[0].productName)
    this.createOrderForm.patchValue({ items:  this.pickedProducts });
    this.createOrderForm.patchValue({ productInput:  this.pickedProducts[0].productName });
    this.selectedProducts = this.pickedProducts[0].productName 
    console.log(this.pickedProducts)
    this.modalReference.close();
    this.addOns = []
    this.count = 1
  }


  updateQty(changeVal,_index,prdQty,productAmount) {    

    if (changeVal === 'plus') {
      if (prdQty != 100) {
        prdQty++;
        this.pickedProducts[_index].quantity =  prdQty
        this.pickedProducts[_index].totalPrice =   this.pickedProducts[_index].totalPrice + parseInt(productAmount)
      }
    }
    else {
      if (prdQty - 1 == 0) {
        this.pickedProducts.splice(_index, 1)
      }
      if (prdQty != 1) {
        prdQty--;
        this.pickedProducts[_index].quantity =  prdQty
        this.pickedProducts[_index].totalPrice =  this.pickedProducts[_index].totalPrice - parseInt(productAmount)

      }
    }

    console.log( 'prdQty ' + prdQty)
    console.log('productAmount ' + productAmount)

    console.log( this.pickedProducts)
    console.log( this.pickedProducts[_index])

  }



}


 