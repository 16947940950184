export const regEx = Object.freeze({
    nameRegEx: /^[a-z ,.'-]+$/i,
    phoneNumberWithoutCodeRegEx: /^[0-9]*$/,
    numbersWithDecimalsRegEx : /\d+(\.\d{1,2})?/,
    restaurantNameRegEx: /^[a-z ,.'-]+$/i,
    categoryNameRegEx: /^[0-9a-z ,.&'"()-]+$/i,
    promoCodeRegEx: /^[0-9a-z]+$/i,
    productNameRegEx: /^[0-9a-z ,.&'"()-]+$/i,
    addonNameRegEx: /^[0-9a-z ,.&'"()-]+$/i,
    pageTitleRegEx: /^[a-z ,.'-]+$/i,
    addressRegEx: /^[0-9a-z ,.&'"()\s-]+$/i,
    slugRegEx: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
    noSpaceAtStartRegEx: /^[^\s]+(\s+[^\s]+)*$/,
    emailRegEx: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    skuNumberRegEx: /^[0-9]*$/,
    priceRegEx: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
    descriptionTextRegEx: /^[0-9a-z ,.&'"()\s-]+$/i,
    staticPageDescriptionRegEx: /^[0-9a-z ,.&'"()\s-]+$/i,
    ingredientsTextRegEx: /^[0-9a-z ,.&'"()\s-]*$/i,
});
