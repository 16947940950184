import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { ToastrService } from "ngx-toastr";
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
  NgbDate,
  NgbCalendar,
} from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

import { numberValidator, customRegexValidator } from "../../helper/validator";
@Component({
  selector: "app-restaurant",
  templateUrl: "./restaurant.component.html",
  styleUrls: ["./restaurant.component.scss"],
})
export class RestaurantComponent implements OnInit {
  normalTimings = {}
  specialTimings = {}
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  restaurantConfigForm: FormGroup;
  specificDateForm: FormGroup;
  setTimeForm: FormGroup;
  setTimeFormTuesday: FormGroup;
  setTimeFormWednesday: FormGroup;
  setTimeFormThursday: FormGroup;
  setTimeFormFriday: FormGroup;
  setTimeFormSaturday: FormGroup;
  setTimeFormSunday: FormGroup;
  modalReference: NgbModalRef;
  submitted: boolean = false;
  availableTimeSlots = [];
  isAvailableFullTime: boolean = false;
  isAvailableAllDays: boolean = false;
  isAvailableSpecificDays: boolean = false;
  timeWiseAvailabilityEveryDay: boolean = false;
  timeWiseAvailabilitySpecificDay: boolean = false;
  isOpenWholeDay: boolean;
  isOpenForSpecificTimeRangesOnly: boolean;
  availableTimeRangesMonday = [];
  availableTimeRangesTuesday = [];
  availableTimeRangesWednesday = [];
  availableTimeRangesThursday = [];
  availableTimeRangesFriday = [];
  availableTimeRangesSaturday = [];
  availableTimeRangesSunday = [];
  isOpenForSpecificMonday: boolean = false;
  isOpenForSpecificTuesday: boolean = false;
  isOpenForSpecificWednesday: boolean = false;
  isOpenForSpecificThursday: boolean = false;
  isOpenForSpecificFriday: boolean = false;
  isOpenForSpecificSaturday: boolean = false;
  isOpenForSpecificSunday: boolean = false;
  selectedDay = null;
  selectedDayIndex = null;
  timeRangesForSelectedDay = [];
  id;
  startDate;
  endDate;
  ngLoader;

  startDate1;
  endDate1;

  specificDay: boolean = false;
  specificDayRange: boolean = false;
  availableDateRange;
  day;
  restaurantTimeSetup = {};
  restaurantData;
  time;
  meridian = true;
  datepickerModel: Date;
  selectedDaysTimeSlots = [];
  restaurantId = JSON.parse(localStorage.getItem("restaurantId"));
  constructor(
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), "d", 10);
  }
  days = [
    {
      name: "Sunday",
      isOpen: 1,
      timeRanges: [],
    },
    {
      name: "Monday",
      isOpen: 1,
      timeRanges: [],
    },
    {
      name: "Tuesday",
      isOpen: 1,
      timeRanges: [],
    },
    {
      name: "Wednesday",
      isOpen: 1,
      timeRanges: [],
    },
    {
      name: "Thursday",
      isOpen: 1,
      timeRanges: [],
    },
    {
      name: "Friday",
      isOpen: 1,
      timeRanges: [],
    },
    {
      name: "Saturday",
      isOpen: 1,
      timeRanges: [],
    },
  ];
  markDayStatus(selectedDayName, status) {
    var days = this.days;
    for (var i in days) {
      var day = days[i];
      var dayName = day.name;
      if (dayName == selectedDayName) {
        this.days[i].isOpen = status;
        break;
      }
    }
  }
  handleRestaurantRegistration() {
    var token = localStorage.getItem("UserToken");
    var isLoggedIn = false;
    if (token) {
      isLoggedIn = true;
    }
    if (!isLoggedIn) {
      this.router.navigate(["login"]);
      return false;
    }
    var id = localStorage.getItem("restaurantId");
    if (id) {
      id = JSON.parse(id);
    }
    if (id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(["get-started"]);
      return false;
    }
    return true;
  }
  numberOnly(evt): boolean {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  ngOnInit() {
    var ok = this.handleRestaurantRegistration();
    this.restaurantConfigForm = this.fb.group({
      currency: [""],
      isOTPVerificationEnabled: [""],
      preparationTimeMinutes: [""],
      minOrderAcceptanceAmount: [
        "",
        [numberValidator({ isDecimalAllowed: true, moreThanOrEqual: 0 })],
      ],
      minTakeAwayAmount: [""],
      merchantsRemainder: [""],
      customerRemainder: [""],
    });

    this.specificDateForm = this.fb.group({
      specificDate: [""],
    });

    this.setTimeForm = this.fb.group({
      startTime: ["", Validators.required],
      endTime: ["", Validators.required],
    });

    if (!ok) {
      return;
    }
    this.getConfigData();
    this.getRestaurantData();
  }

  startDateChange(value) {
    this.startDate = value;
  }

  endDateChange(value) {
    this.endDate = value;
  }

  changeDayAvailability(value) {
    if (value == "specificDayRange") {
      this.specificDayRange = true;
      this.isAvailableAllDays = false;
      this.availableDateRange = {};
    } else {
      this.specificDayRange = false;
      this.isAvailableAllDays = true;
    }
  }

  changeTimeAvailability(value) {
    if (value == "specificDay") {
      this.specificDay = true;
      this.isAvailableFullTime = false;
    } else {
      this.specificDay = false;
      this.isAvailableFullTime = false;
    }
  }

  getRestaurantData() {
    this.ApiService.getRestaurantsSetup(this.restaurantId).subscribe((data) => {
      this.restaurantData = data["item"];
      this.restaurantConfigForm.patchValue({
        minOrderAcceptanceAmount: this.restaurantData.minOrderAcceptanceAmount,
        isOTPVerificationEnabled: this.restaurantData.isOTPVerificationEnabled,
        minTakeAwayAmount: this.restaurantData.minTakeAwayAmount,
        merchantsRemainder: this.restaurantData.merchantsRemainder,
        customerRemainder: this.restaurantData.customerRemainder,
        preparationTimeMinutes: this.restaurantData.preparationTimeMinutes,
      });
    });
  }

  getConfigData() {
    this.ApiService.getRestaurantConfig().subscribe((result) => {

      var config = result["config"];
      if (config != null) {
        let timeAvailability = config.timeAvailability;
        console.log("time availabliit is ", timeAvailability)
        this.normalTimings = timeAvailability.normalTimings || {};
        console.log("time availabliit is ", this.normalTimings)
        this.specialTimings = timeAvailability.specialTimings || {}; 
        this.startDate = this.specialTimings["availableDateRange"]?.startDate;
        this.endDate = this.specialTimings["availableDateRange"]?.endDate;
        console.log(`Start date`, this.startDate);
        console.log(`End date`, this.endDate);
      }
    });
  }

  open(content, day) {
    day = day.toLowerCase();
    this.day = day;
    this.selectedDay = day;
    var days = this.days;
    for (var i in days) {
      var curDay = days[i];
      var dayName = curDay.name.toLowerCase();
      if (day == dayName) {
        this.selectedDayIndex = i;
        break;
      }
    }
    this.timeRangesForSelectedDay = this.days[i].timeRanges;
    this.modalReference = this.modalService.open(content, {
      size: "",
      centered: true,
      windowClass: "modal-custom",
    });
    this.modalReference.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  onSubmitTime() {
    console.log(this.setTimeForm.value.startTime);
    var selectedDay = this.selectedDay.toLowerCase();
    this.submitted = true;
    if (this.setTimeForm.invalid) {
      this.toastr.error("start time and end time is required");
      return;
    }
    var timeRange = {
      startTime:
        this.setTimeForm.value.startTime.hour +
        ":" +
        this.setTimeForm.value.startTime.minute,
      endTime:
        this.setTimeForm.value.endTime.hour +
        ":" +
        this.setTimeForm.value.endTime.minute,
    };
    console.log("Selected days is " + this.selectedDay);
    console.log("Days is ");
    console.log(JSON.stringify(this.days));
    var days = this.days;
    var i = this.selectedDayIndex;
    var day = days[i];
    var name = day.name.toLowerCase();
    if (selectedDay == name) {
      day.timeRanges.push(timeRange);
      this.days[i] = day;
    }

    this.timeRangesForSelectedDay = day.timeRanges;
  }

  get f() {
    return this.setTimeForm.controls;
  }

  get f1() {
    return this.setTimeFormTuesday.controls;
  }

  get f2() {
    return this.setTimeFormWednesday.controls;
  }

  get f3() {
    return this.setTimeFormThursday.controls;
  }

  get f4() {
    return this.setTimeFormFriday.controls;
  }

  get f5() {
    return this.setTimeFormSaturday.controls;
  }

  get f6() {
    return this.setTimeFormSunday.controls;
  }

  removeTimeRage(index) {
    var selectedDayIdx = this.selectedDayIndex;
    var timeRanges = this.days[selectedDayIdx].timeRanges;
    timeRanges.splice(index, 1);
    this.days[selectedDayIdx].timeRanges = timeRanges;
  }

  displayErrorsAndSuccess(result) {
    if (result["success"] == 1) {
      this.toastr.success(result.message);
    } else {
      this.toastr.warning(result.message);
    }
  }

  // changeMonday(value) {
  //   if(value == 'mondayOpenAllDay') {
  //     this.isOpenForSpecificMonday = false;
  //     this.isOpenWholeDay = true;
  //     this.isOpenForSpecificTimeRangesOnly = false;
  //     this.monday = {
  //       isOpenWholeDay: this.isOpenWholeDay,
  //       isOpenForSpecificTimeRangesOnly: this.isOpenForSpecificTimeRangesOnly,
  //     }
  //   } else if(value == 'mondayCloseAllDay') {
  //     this.isOpenForSpecificMonday = false;
  //     this.isOpenWholeDay = false;
  //     this.isOpenForSpecificTimeRangesOnly = false;
  //     this.monday = {
  //       isOpenWholeDay: this.isOpenWholeDay,
  //       isOpenForSpecificTimeRangesOnly: this.isOpenForSpecificTimeRangesOnly,
  //     }
  //   } else {
  //     this.isOpenWholeDay = false;
  //     this.isOpenForSpecificMonday = true;
  //     this.isOpenForSpecificTimeRangesOnly = true;
  //     this.monday = {
  //       isOpenWholeDay: this.isOpenWholeDay,
  //       isOpenForSpecificTimeRangesOnly: this.isOpenForSpecificTimeRangesOnly,
  //       availableTimeRanges: this.availableTimeRangesMonday
  //     }
  //   }

  // }
  availabilitySubmit() {
    if (this.datepickerModel) {
      var dateStart = new Date(this.datepickerModel[0]);
      var dateEnd = new Date(this.datepickerModel[1]);
      this.startDate =
        dateStart.getDate() +
        "-" +
        (dateStart.getMonth() + 1) +
        "-" +
        dateStart.getFullYear();
      this.endDate =
        dateEnd.getDate() +
        "-" +
        (dateEnd.getMonth() + 1) +
        "-" +
        dateEnd.getFullYear();
    }

    var days = this.days;
    var availableTimeSlots = {};
    var daysInfo = {};
    for (var day of days) {
      var name = day.name.toLowerCase();
      var isOpen = day.isOpen;
      var isOpenWholeDay = 1;
      if (isOpen == 0) {
        isOpenWholeDay = 0;
      }
      var isOpenForSpecificTimeRangesOnly = 0;
      if (isOpen == 2) {
        isOpenForSpecificTimeRangesOnly = 1;
      }
      var availableTimeRanges = day.timeRanges;
      var dayData = {
        isOpenWholeDay: isOpenWholeDay,
        isOpenForSpecificTimeRangesOnly: isOpenForSpecificTimeRangesOnly,
        availableTimeRanges: availableTimeRanges,
      };
      daysInfo[name] = dayData;
      availableTimeSlots[name] = dayData;
    }
    availableTimeSlots = [availableTimeSlots];
    var dayAvailability = {
      isAvailableAllDays: this.isAvailableAllDays,
      availableDateRange: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
    };

    var timeAvailability = {
      isAvailableFullTime: this.isAvailableFullTime,
      availableTimeSlots: availableTimeSlots,
    };
    var configTime = {
      dayAvailability: dayAvailability,
      timeAvailability: timeAvailability,
    };
    console.log("Config being submitted is ");
    console.log(JSON.stringify(configTime));
    this.ngLoader = true;
    this.ApiService.updateRestaurantConfig(configTime).subscribe((data) => {
      this.ngLoader = false;
      this.displayErrorsAndSuccess(data);
    });
  }

  onConfigSubmit() {
    this.ngLoader = true;
    this.submitted = true;
    if (this.restaurantConfigForm.invalid) {
      return;
    }
    this.ApiService.setupRestaurantsUpdate(
      this.restaurantId,
      this.restaurantConfigForm.value
    ).subscribe((result) => {
      this.displayErrorsAndSuccess(result);
      this.ngLoader = false;
    });
  }
}
