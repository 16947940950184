import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  cashOnDelivery: boolean = false;
  cardOnDelivey: boolean = false;
  onlinePayemnt: boolean = false;
  isOrderNotesEnabled: boolean = false;
  checkOutData;
  restaurantData;
  ngLoader;
  id = JSON.parse(localStorage.getItem('restaurantId'));
  constructor(private ApiService: ApiService, private toastr: ToastrService, private router: Router) { }


  handleRestaurantRegistration() {
    var token =  localStorage.getItem('UserToken');
    var isLoggedIn = false;
    if(token) {
      isLoggedIn = true;
    }
    if(!isLoggedIn) { 
      this.router.navigate(['login']);
      return false;
    }
    var id = localStorage.getItem('restaurantId'); 
    if(id) {
      id = JSON.parse(id);
    }
    if(id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(['get-started']);
      return false;
    }
    return true;
  } 
  ngOnInit() {
    var ok  = this.handleRestaurantRegistration();
    if(!ok) {
      return
    }
    this.getRestaurantData();
  }

  getRestaurantData() {
    this.ngLoader = true;
    this.ApiService.getRestaurantsSetup(this.id).subscribe(data => {
      this.restaurantData = data['item'];
      if(this.restaurantData.isCardOnDeliveryEnabled) {
        this.cardOnDelivey = true;
      }
      if(this.restaurantData.isCashOnDeliveryEnabled) {
        this.cashOnDelivery = true;
      }
      if(this.restaurantData.isOnlinePaymentEnabled) {
        this.onlinePayemnt = true;
      }
      if(this.restaurantData.isOrderNotesEnabled) {
        this.isOrderNotesEnabled = true;
      }
      this.ngLoader = false;
    })
  }

  toggleCashDelivery(event){
    this.cashOnDelivery = event.target.checked;
   
  }

  toggleCardDelivery(event) {
   this.cardOnDelivey = event.target.checked;
  }

  toggleOnlinePayment(event) {
   this.onlinePayemnt = event.target.checked;
  }

  toggleOrderNotes(event) {
    this.isOrderNotesEnabled = event.target.checked;
  }

  saveCheckOut() {
    this.checkOutData = {
    isCashOnDeliveryEnabled: this.cashOnDelivery,
    isCardOnDeliveryEnabled: this.cardOnDelivey,
    isOnlinePaymentEnabled: this.onlinePayemnt,
    isOrderNotesEnabled: this.isOrderNotesEnabled
   };
   this.ngLoader = true;
   this.ApiService.setupRestaurantsUpdate(this.id,this.checkOutData).subscribe(data => {
      this.toastr.success('Successfully saved');
      this.ngLoader = false;
    })
  }

}
