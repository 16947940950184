import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

@Component({
  selector: "app-order-list",
  templateUrl: "./order-list.component.html",
  styleUrls: ["./order-list.component.scss"],
})
export class OrderListComponent implements OnInit {
  filterForm: FormGroup;
  ordersList: any;
  ordersStatus: any;
  isAccepted: boolean = false;
  isCancelled: boolean = false;
  filterPanel: boolean = false;
  orderStatusFilter = "";
  emiratesData: any;
  page = 1;
  perPage = 100;
  pagination: any;
  areaId: any;
  areaData: any;
  emiratesId: any;
  ngLoader: boolean = true;
  id;
  restaurantToken: any;
  deliveryTime: any;
  constructor(
    public ApiService: ApiService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.formControler();
  }

  get f() {
    return this.filterForm.controls;
  }

  tableFilterToggle() {
    this.filterPanel = !this.filterPanel;
    this.handleEmiratesList();
  }
  onAcceptOrder(
    id,
    preparationTime = null,
    homeDelivery = false,
    list = undefined
  ) {
    let preparationTimeText = "Preparation Time (in Minutes)";
    let preparationTimeMinutes = "0";
    if (preparationTime) {
      if (homeDelivery) {
        preparationTime = this.deliveryTime + preparationTime;
      }
      preparationTimeText =
        "Preparation Time (estimated " + preparationTime + " minutes)";
      preparationTimeMinutes = String(preparationTime);
    }
    const msg = list.isTakeAway
      ? "Ready time update"
      : "( Preparation time + Delivery time )";

    Swal.fire({
      title: "Accept Order",
      html: `Processing time in minutes <br /> ${msg}`,
      input: "text",
      inputPlaceholder: preparationTimeText,
      inputValue: preparationTimeMinutes,
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#1dbc60",
      cancelButtonColor: "#374abe",
      confirmButtonText: "Accept",
      cancelButtonText: "Close",
    }).then((result) => {
      console.log("Result is ---------------------------------");
      console.log(result);
      if (result.value) {
        this.orderAccept(id, "accept", {
          preparationTimeMinutes: result.value,
        });
      }
    });
  }
  onCancelOrder(id) {
    let cancellationReasonText = " ";

    Swal.fire({
      title: "Cancel Order",
      text: "Cancellation Reason",
      input: "text",
      inputPlaceholder: "Enter cancellation Reason",
      inputValue: cancellationReasonText,
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#374abe",
      confirmButtonText: "Reject",
      cancelButtonText: "Close",
    }).then((result) => {
      if (result.value) {
        this.orderAccept(id, "cancell", {
          cancellationReason: result.value,
        });
      }
    });
  }

  orderAccept(orderId, type, info = null) {
    let body = {};
    let cancellationReason = null;
    let preparationTimeMinutes = null;
    if (type == "accept") {
      if (info && info.preparationTimeMinutes)
        preparationTimeMinutes = info.preparationTimeMinutes;
      body = {
        isAccepted: true,
        isCancelled: false,
        preparationTimeMinutes: preparationTimeMinutes,
      };
    } else {
      if (info && info.cancellationReason)
        cancellationReason = info.cancellationReason;
      body = {
        isAccepted: false,
        isCancelled: true,
        cancellationReason: cancellationReason,
      };
    }
    console.log("------------------- About to call change order status ");
    this.ApiService.ordersAccept(orderId, body).subscribe((res: any) => {
      console.log("calling handle orders list ---------------------");
      this.handleOrdersList();
      this.toastr.success(res.message);
      console.log("Order status changed ---------------------");
      this.router.navigate;
    });
  }

  changeStatus(orderId, statusId, statusName = undefined) {
    let body = {
      orderStatusId: statusId,
    };
    this.ApiService.updateOrdersStatus(orderId, body).subscribe((res: any) => {
      this.handleOrdersList();
      this.toastr.success(res.message);
    });
  }

  quickFilter(event) {
    this.orderStatusFilter = event.target.value;
    this.handleOrdersList();
  }

  editOrder(pageId) {
    this.router.navigate(["/order-edit", pageId]);
  }
  billSummary(pageId) {
    this.router.navigate(["/order-summary", pageId]);
  }

  handleRestaurantRegistration() {
    var token = localStorage.getItem("UserToken");
    var isLoggedIn = false;
    if (token) {
      isLoggedIn = true;
    }
    if (!isLoggedIn) {
      this.router.navigate(["login"]);
      return;
    }
    var id = localStorage.getItem("restaurantId");
    if (id) {
      id = JSON.parse(id);
    }
    if (id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(["get-started"]);
      return;
    }
  }
  ngOnInit() {
    this.restaurantToken = JSON.parse(localStorage.getItem("restaurantId"));
    this.ApiService.getRestaurantsSetup(this.restaurantToken).subscribe(
      (res: any) => {
        console.log(res);
        this.deliveryTime = res.item.preparationTimeMinutes;
        console.log("DELIVERY TIME :" + this.deliveryTime);
      }
    );
    this.ordersList = [];
    this.handleRestaurantRegistration();
    this.handleOrdersList();
    this.handleOrdersStatus();
  }

  formControler() {
    this.filterForm = this.fb.group({
      orderId: [""],
      startDate: [""],
      endDate: [""],
      deliveryAreaId: [""],
      emiratesId: [""],
      userName: [""],
    });
  }

  // handleEmiratesList() {
  //   this.ApiService.getEmiratesList().subscribe((data) => {
  //     this.emiratesData = data['items']
  //   })
  // }

  selectEmirates(e) {
    this.emiratesId = e.target.value;
    this.handleAreaList();
  }
  selectArea(e) {
    this.areaId = e.target.value;
  }
  handleEmiratesList() {
    this.ApiService.getEmiratesList().subscribe((data) => {
      this.emiratesData = data["items"];
    });
  }
  handleAreaList() {
    this.ApiService.getAreaList(this.emiratesId).subscribe((data) => {
      this.areaData = data["items"];
    });
  }

  handleOrdersList() {
    console.log("handle orders list called ----------------------------");
    this.ngLoader = true;
    this.ApiService.getOrdersList(
      this.orderStatusFilter,
      this.filterForm.value,
      this.page,
      this.perPage
    ).subscribe((res) => {
      console.log("-------------------------------------- Orders List is ");
      console.log(res);
      this.ordersList = res["items"];
      this.pagination = res["pagination"];
      this.ngLoader = false;
    });
  }

  handleOrdersStatus() {
    this.ApiService.getOrdersStatus().subscribe((res) => {
      this.ordersStatus = res["items"];
      console.log("Order status is ");
      console.log(JSON.stringify(this.ordersStatus));
    });
  }

  pageChange(page) {
    this.page = page;
    this.handleOrdersList();
  }
  pageItemCount(event) {
    console.log(event.target.value);
    this.perPage = event.target.value;
    this.handleOrdersList();
  }

  clearFilter() {
    this.formControler();
    this.handleOrdersList();
  }

  onSubmitFilter() {
    
    let formData = this.filterForm.value;
    console.log(this.filterForm.value);
    this.ApiService.getOrdersList(
      this.orderStatusFilter,
      this.filterForm.value,
      this.page,
      this.perPage
    ).subscribe((res) => {
      console.log(res);
      this.ordersList = res["items"];
      this.tableFilterToggle();
    });
  }
}
