import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ApiService } from "../../services/api.service";
@Component({
  selector: "app-roles-list",
  templateUrl: "./roles-list.component.html",
  styleUrls: ["./roles-list.component.scss"],
})
export class RolesListComponent implements OnInit {
  rolesArray;
  @Input() selectedRoleList: any[];
  @Output() triggerCheckboxClick = new EventEmitter<any>();
  constructor(private ApiService: ApiService) {}

  ngOnInit() {
    this.getRole();
  }

  getRole() {
    this.ApiService.getRoles().subscribe((result) => {
      this.rolesArray = result["items"];
      console.log("Roles array is ");
      console.log(this.rolesArray);
    });
  }
  getSelectedRole = (role: any) => {
    const roleList = this.selectedRoleList?.filter(
      (selectedRole) => selectedRole.name === role.name
    );
    return roleList?.length ? true : false;
  };
  checkboxValueUpdate = (role: any, event: any) => {
    console.log(role, event);

    if (event.target.checked) {
      if (!this.selectedRoleList) {
        this.selectedRoleList = [];
      }
      this.selectedRoleList.push(role);
    } else {
      const selectedRole = this.selectedRoleList?.findIndex(
        (selectedRole) => selectedRole.name === role.name
      );
      this.selectedRoleList?.splice(selectedRole, 1);
    }
    this.triggerCheckboxClick.emit(this.selectedRoleList);
  };
}
