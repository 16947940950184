import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {
  isDineInEnabled: boolean = false;
  isTakeAwayEnabled: boolean  = false;
  isHomeDeliveryEnabled: boolean = false;
  deliveryInAllAreas: boolean = false;
  setupDeliveryArea: boolean = false;
  manualSelection: boolean = false;
  deliveryForm: FormGroup;
  formData;
  emirateId;
  restaurantData;
  emiratesArray = [];
  areasArray = [];
  selectedArea;
  selectedEmirate;
  addAll: boolean = false;
  selected = [];
  selectAll = [];
  deliveryServeAreas = [];
  selectAllDisable: boolean = false;
  addAllAreasButton: boolean = false;
  areaNotFound: boolean = false;
  loading = true; 
  allAreas = [];
  allAreasIndices = {}; //stores id of area as key and index in allAreas array as value
  areasIndices =  {};//stores id of area as key and index in areas array as value
  deletedAreas = {};
  addedAreas = {};
  isServeEveryWhere: boolean = false;
  id;
  constructor(private fb: FormBuilder, private ApiService: ApiService, private toastr: ToastrService,private router: Router) { }
  handleRestaurantRegistration() {
      var token =  localStorage.getItem('UserToken');
      var isLoggedIn = false;
      if(token) {
        isLoggedIn = true;
      }
      if(!isLoggedIn) { 
        this.router.navigate(['login']);
        return;
      }
      var id = localStorage.getItem('restaurantId');
      if(id) {
        id = JSON.parse(id);
      }
      if(id) {
        this.id = id;
      } else {
        this.router.navigate(['get-started']);
        return;
      }
  }
  ngOnInit() {
    this.handleRestaurantRegistration();
    this.deliveryForm = this.fb.group({
      deliveryTime: [],
      deliveryCurrency: [''],
      deliveryFee: [],
      deliveryArea: ['']
    })
    this.getRestaurantData();
    this.getEmiratesList();
    this.fillAllAreas();
  }
  checkAnyCitySelected() { 
    let keys = Object.keys(this.addedAreas);
    return keys.length > 0;
  }
  checkIfAnyCityAvailable() { 
    let count = this.areasArray.length;
    for(let area of this.areasArray) {
      if(this.addedAreas[area.id]) {
        count--;
      }
    }
    let result = count > 0;
    return result;
  }

  displayErrorsAndSuccess(result) {
    if(result['success'] == 1) {
      this.toastr.success(result.message);
      } else {
      this.toastr.warning(result.message)
      }
  }

  getRestaurantData() {
    this.loading = true;
    this.ApiService.getRestaurantsSetup(this.id).subscribe(data => {
      console.log("Data is "+JSON.stringify(data));
      this.restaurantData = data['item'];
      if(!this.restaurantData) {
        this.loading = false;
        return;
      }
      this.isDineInEnabled = this.restaurantData.isDineInEnabled;
      this.isTakeAwayEnabled = this.restaurantData.isTakeAwayEnabled;
      this.isHomeDeliveryEnabled = this.restaurantData.isHomeDeliveryEnabled;
      
      if(this.restaurantData.isDeliveryAvailableInAllDeliveryAreas) {
        this.deliveryInAllAreas = true;
      } else {
        this.manualSelection = true;
      } 
      this.deliveryForm.patchValue({
        deliveryTime: this.restaurantData.defaultDeliveryTimePerOrderMinutes,
        deliveryFee: this.restaurantData.defaultDeliveryCharge
      })
      
      this.loading = false;
    })
  }
  fillAllAreas() {
    
   this.ApiService.getAreaList(null).subscribe(result => {
    console.log("Areas are ");
   this.loading =  false;
   var areas = result['items']; 
   console.log(JSON.stringify(areas));
    if(result['items'].length == 0) {
      this.areaNotFound = true
      this.allAreas = [];
    }else {
      let i = 0;
      for(let area of areas) {
        if(area.isDeliveryAvailable) {
          this.addedAreas[area.id] = 1;
        } else {
          this.deletedAreas[area.id] = 1;
        }
        this.allAreasIndices[area.id] = i;
        i++;
      }
     this.allAreas = areas;
    }
     
    
  })
  }
  
 
  toggleTakeAwayEnabled(){     
    console.log("Previous value of tk "+this.isTakeAwayEnabled);
    this.isTakeAwayEnabled = !this.isTakeAwayEnabled; 
    console.log("Previous value of tk "+this.isTakeAwayEnabled);
 }
 toggleHomeDeliveryEnabled(){    
  console.log("Previous value of hd "+this.isHomeDeliveryEnabled);
   this.isHomeDeliveryEnabled = !this.isHomeDeliveryEnabled;
   console.log("Previous value of hd "+this.isHomeDeliveryEnabled);

}
toggleDineInEnabled(){   
  console.log("Previous value of dn "+this.isDineInEnabled);
  this.isDineInEnabled = !this.isDineInEnabled;
  console.log("Current value of dn "+this.isDineInEnabled);
}
  deliveryMethodChange(value) {
    if(value == 'dine-in') {
      this.isDineInEnabled =  !this.isDineInEnabled?true:false;  
    } else if(value == 'take-away') {
      this.isTakeAwayEnabled =  !this.isTakeAwayEnabled?true:false;  
    } else { 
      this.isHomeDeliveryEnabled =  !this.isHomeDeliveryEnabled?true:false; 
    }
  }

  deliveryInAllArea(value) {
    this.deliveryInAllAreas = value;
    if(this.deliveryInAllAreas) {
      this.manualSelection = false;
      this.isServeEveryWhere = true;
    }
  }

  setupDeliveryAreaManually(value) {
   this.setupDeliveryArea = value;
   if(this.setupDeliveryArea) {
     this.manualSelection = true;
     this.isServeEveryWhere = false;
   }
  }


  getEmiratesList() {
    this.loading =  true;
    this.ApiService.getEmiratesList().subscribe(result => {
      this.emiratesArray = result['items'];
      if(!this.emiratesArray) {
        this.loading = false;
        return;
      } 
      if(this.emiratesArray.length) {
        var firstEmirate = this.emiratesArray[0];
        var emirateId = firstEmirate.id;
        console.log("Loading first emirate...");
        this.getAreas(null, emirateId);
      }
      this.loading  = false;
    }
    , error => {
      this.toastr.error(error.error.message);
      this.loading  = false;
    })
  }

  getAreas(event, emirateId) { 
   this.addAllAreasButton = true;
   this.emirateId = event?event.target.value: null;
   if(emirateId) {
    this.emirateId =  emirateId;
   }
   this.loading =  true;
   if(!this.emirateId) {
     this.loading = false;
     return;
   }
   this.ApiService.getAreaList(this.emirateId).subscribe(result => {
     console.log("Areas are ");
    this.loading =  false;
    var areas = result['items']; 
    console.log(JSON.stringify(areas));
     if(result['items'].length == 0) {
       this.areaNotFound = true
       this.areasArray = [];
     }else {
       
      let i = 0;
      for(let area of areas) {
        this.areasIndices[area.id] = i;

        if(this.deletedAreas[area.id]) {
          area.isDeliveryAvailable = false;
        } else if(this.addedAreas[area.id]) {
          area.isDeliveryAvailable = true;
        } else {
            if(area.isDeliveryAvailable) {// first time loading available areas case
              this.addedAreas[area.id] = 1;
            } else {
              this.deletedAreas[area.id] = 1;
            }
        }
        i++;
      }
      this.areasArray = areas;
     }
     
     this.selectAll = [];
     this.selected = [];
     
   })
  }
  selectAllCities() {
    let areas = this.areasArray;
    console.log(`Adding areas`, areas);
    let tmp = [];
    for(let area of areas) {
      delete this.deletedAreas[area.id];
      area.isDeliveryAvailable = true;
      if(!this.addedAreas[area.id]) { // no need to add two times
        this.allAreas.push(area);
        this.allAreasIndices[area.id] = this.allAreas.length - 1;
        this.addedAreas[area.id] = 1; 
      }
    }  
  }
  unSelectAllCities() {
    let areas = this.allAreas;
    for(let area of areas) {
      area.isDeliveryAvailable = false;
      delete this.addedAreas[area.id];
      this.deletedAreas[area.id] = 1; 
    } 
  }
  selectCity(i) { 
    let area = this.areasArray[i]; 
    delete this.deletedAreas[area.id];
    this.areasArray[i].isDeliveryAvailable =  true;
    let index = this.allAreasIndices[area.id];
    if(index !== undefined)
      this.allAreas[index].isDeliveryAvailable = true;
      console.log(`Area id is`, area.id);
    console.log(`Index is `, index)
    this.addedAreas[area.id] = 1;

    console.log(`Index`, index);
  }
  unSelectCity(i) { 
    let area = this.allAreas[i];
    delete this.addedAreas[area.id];
    let index = this.areasIndices[area.id]; 
    if(index !== undefined)
      this.areasArray[index].isDeliveryAvailable = false;
   
    this.deletedAreas[area.id] = 1;
    this.allAreas[i].isDeliveryAvailable =  false;
  }
  addAllAreas() {
    this.selected = [];
    this.addAll = true;
    this.selectAllDisable = true;
    this.ApiService.getAreaList(this.emirateId).subscribe(result => {
      this.areasArray = result['items'];
      for(let i=0;i<this.areasArray.length;i++) {
        this.selectAll.push({
          areaId: this.areasArray[i].id,
          selectedArea: this.areasArray[i].name,
          selectedEmirate: this.areasArray[i].parentEmirateId.name
        })
      }
    })
  }

  selectArea(area,areaId,emirate) {
    this.selectAll = [];
    this.addAll = false;
     this.selectedArea = area;
     this.selectedEmirate = emirate;
     this.selected.push({
       areaId: areaId,
       selectedArea: this.selectedArea,
       selectedEmirate: this.selectedEmirate
     })
  }

  delete(i) {
    this.selected.splice(i, 1);
  }

  deleteFromAll(i) {
    this.selectAll.splice(i, 1);
  }

  deleteAllAreas() {
    this.selectAll = [];
    this.selected = [];
    this.selectAllDisable = false;
  }

  submitDeliveryArea() {
    console.log("Submit delivery area called..."); 
    let deliveryAreas = Object.keys(this.addedAreas); 
    this.deliveryServeAreas = deliveryAreas; 
    let deliveryAreaSelection = {
      isServeEveryWhere: this.isServeEveryWhere,
      deliveryServeAreas: this.deliveryServeAreas
   }
   this.loading = true;
   this.ApiService.updateDeliveryAreas(deliveryAreaSelection).subscribe(result => {
      this.displayErrorsAndSuccess(result);
      this.loading = false;
   })
  } 

  onSubmit() {
    this.formData = {
      isDineInEnabled: this.isDineInEnabled,
      isHomeDeliveryEnabled: this.isHomeDeliveryEnabled,
      isTakeAwayEnabled: this.isTakeAwayEnabled,
      defaultDeliveryTimePerOrderMinutes: this.deliveryForm.value.deliveryTime,
      defaultDeliveryCharge: (this.deliveryForm.value.deliveryFee),
      isDeliveryAvailableInAllDeliveryAreas: this.deliveryInAllAreas
    };
    console.log("Form data is "+JSON.stringify(this.formData));
    this.loading =  true;
    console.log("id is "+this.id);
    this.ApiService.setupRestaurantsUpdate(this.id,this.formData).subscribe(data => {
      this.loading =  false;
      this.toastr.success('Successfully saved');
    })
  }

}
