import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';
@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.scss']
})
export class DomainComponent implements OnInit {
  domainForm: FormGroup;
  submitted:boolean = false;
  clicked:boolean = false;
  restaurantData;
  restaurantId = JSON.parse(localStorage.getItem('restaurantId'));
  id;
  ngLoader;
  constructor(private fb: FormBuilder, private ApiService: ApiService, private toastr: ToastrService, private router: Router) { }

  handleRestaurantRegistration() {
    var token =  localStorage.getItem('UserToken');
    var isLoggedIn = false;
    if(token) {
      isLoggedIn = true;
    }
    if(!isLoggedIn) { 
      this.router.navigate(['login']);
      return false;
    }
    var id = localStorage.getItem('restaurantId'); 
    if(id) {
      id = JSON.parse(id);
    }
    if(id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(['get-started']);
      return false;
    }
    return true;
  }
  ngOnInit() {
    this.domainForm = this.fb.group({
      dashboardDomainName: [''],
      iframeCode: ['']
    }) 
    var ok = this.handleRestaurantRegistration();
    if(!ok) {
      return;
    }
    this.getDomainData();
  }

  getDomainData() {
    this.ngLoader = true;
    this.ApiService.getRestaurantsSetup(this.restaurantId).subscribe(result => {
      this.restaurantData = result['item'];
      console.log(this.restaurantData);
      var src = this.ApiService.getFrontPageUrl();
      this.domainForm.patchValue({
        dashboardDomainName: this.restaurantData.dashboardDomainName,
        iframeCode: `<iframe src=${src}" border="0" style="position: fixed; right: 10px; bottom: 0; top: 10px; height: 100%; width: 400px; border:none; z-index: 999999;" ></iframe>`
      });
      this.ngLoader = false;
    })
  }

  displayErrorsAndSuccess(result) {
    if(result['success'] == 1) {
      this.toastr.success(result.message);
      } else {
      this.toastr.warning(result.message)
      }
  }

  onSubmit() {
    this.submitted = true;
    this.clicked = true;
    if (this.domainForm.invalid) {
      this.clicked = false;
      return;
    } 
    console.log(this.domainForm.value);
    this.ngLoader = true;
    this.ApiService.setupRestaurantsUpdate(this.restaurantId, this.domainForm.value).subscribe(result => {
     this.displayErrorsAndSuccess(result);
     this.ngLoader = false;
    })
  }

  copyInputMessage(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.toastr.success('Content copied to clipboard');
  }

}
