import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'; 
import { Router,ActivatedRoute } from '@angular/router';
import { stringify } from 'querystring';

@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss']
})
export class CreateOrderComponent implements OnInit {
  createCustomerForm: FormGroup;
  createOrderForm: FormGroup;
  AddressCreateForm: FormGroup;
  modalReference: NgbModalRef;
  submitted: boolean = false;
  clicked: boolean = false;
  submitted1: boolean = false;
  clicked1: boolean = false;
  submitted2: boolean = false;
  clicked2: boolean = false;
  searchResult: any;
  selectedcustomerName: boolean;
  toggleContainer: boolean;
  setDeliveryMethod: any;
  setOrderMethod: any;
  searchResultProducts: any;
  imageBase: any;
  productItems: any;
  productAddons: any;
  resTableList: any;
  emiratesId: any;
  totalAmounts = {};
  areaData: any;
  emiratesData: any;
  areaId: any;
  foodCount = 1
  foodId: any;
  modalQuantity: any;
  count: number = 1
  modalTotalAmount: number = 0;
  productPrice = 0;
  currentModalTotal = 0;
  productTotalAmount: number = 0;
  isChecked;
  isCheckedName;
  checkedAddons = {}
  selectedOptions = {};
  addOns = []
  pickedProducts = []
  restaurantItemId: string;
  quantity: number;
  // pickedProducts: Array<{ restaurantItemId: string, productName: string, productPrice: number, quantity: number, totalPrice: number,addOns:any }> = [];
  valueConut: any;
  selectedcustomerPhone: any;
  showDeliveryInputs: boolean = false;
  showTableInputs: boolean = false;
  selectedProducts: any;
  searchResultAddress: any;
  addressList: any;
  addressArray = []
  selectedcustomerId: any;
  addressType: any;
  addressLine: any;
  selectedaddressType: any;
  ngLoader: boolean = false;
  id;
  addProductBtnLabel="Add";
  currentProducts = {};
  productMeta = {};
  emirates = [];
  areas = [];
  emiratesNameIdMap = {};
  areaNameIdMap = {};
  emirateName;
  areaName;
  addressError=null;
  orderId = null;
  pageTitle = null;
  selectedAddressId;
  promoCodeLists:any;
  constructor(
    private ApiService: ApiService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router,
    private route: ActivatedRoute) { }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  onNoFocus(event,type) { 
    // console.log(event.target);
    // console.log(event.target.getAttribute('data-avoid-no-focus-on-click'));
    if(event.target.getAttribute('data-avoid-no-focus-on-click') == "true") return;
    if(type == "address") {
      this.addressArray = [];
    } else if(type == "customer") {
      this.searchResult = [];
    } else if(type == "products") {
      this.searchResultProducts = [];
    }
  }
  calculateTotalAmountForProductsInModal() {
   console.log("calculateTotalAmountForProductsInModal called...");
    var quantity = this.modalQuantity;
    var price = this.productPrice;
    var totalAmount: any = price*quantity; 
    console.log("Total amount calculated is "+totalAmount);
    var product = this.productItems;
    if(!product) {
      console.log("No product found in modal. Returning...");
      return;
    }
    var addons = product.addOns;
    addons = addons?addons: [];
    for(var addon of addons) { 
      console.log("Calculating for addon "+addon.name);
      for(var option of addon.options) { 
        var optionId = option.restaurantItemOptionId;
        var optionPrice =  option.price;
        console.log("Calculating for option "+option.name);
        var optionQty = null; 
        if(this.checkedAddons[optionId]){ 
          optionQty = 1; 
          console.log(this.checkedAddons[optionId]);
        } else
        if(this.selectedOptions[optionId]&& this.selectedOptions[optionId].quantity) { 
          console.log(JSON.stringify(this.selectedOptions[optionId]));
          optionQty = this.selectedOptions[optionId].quantity;
        } 
        console.log("Option quantity = "+optionQty);
        if(optionQty) {
          var optionAmount = optionQty*optionPrice; 
          console.log("Option amount is "+optionQty+"X"+optionPrice+" = "+optionAmount);
          totalAmount += optionAmount;
          console.log("Total amount is "+optionQty+"X"+optionPrice+" = "+optionAmount);
        }
      }
    }
    totalAmount = totalAmount.toFixed(2);
    this.currentModalTotal = totalAmount;
    console.log("Modal total amount is "+totalAmount);
    console.log("calculateTotalAmountForProductsInModal returned...");

  }
  calculateTotalAmountForPickedProducts(callModalFn) {
    console.log("Total amount calculate fn is called...");
    if(callModalFn) {
      this.calculateTotalAmountForProductsInModal(); 
    } 
    console.log("Total amount for products in modal called. Now calling picked products...");

    console.log("Calculating for picked products...");
    var products = this.pickedProducts;  
    console.log("Picked products...");
    console.log(JSON.stringify(this.pickedProducts));
    var totalAmount: any = 0;
    for(var product of products) {
      console.log("Doing calculations for product "+product.productName);
      totalAmount = 0;
      var productId = product.restaurantItemId;
      var price = product.productPrice;
      var quantity = product.quantity;
      price = parseFloat(price);
      quantity = parseInt(quantity);
      totalAmount = price*quantity;
       
      console.log("Total amount without addons: "+totalAmount);
      var addOns = product.addOns;
      console.log("Addons for calculation are ");
      console.log(JSON.stringify(addOns));
      for(var addon of addOns) {
        var addonAmount = 0;
       console.log("Calculating for addon "+addon.name);
        var options = addon.options;
        for(var option of options) {
          var optionId = option._id;
          var optionName = option.name;
          var optionPrice = option.price?option.price: 0;
          var optionQty = option.quantity!=undefined?option.quantity: 0;
          

          optionPrice = parseFloat(optionPrice);
          optionQty = parseInt(optionQty);
          var optionAmount = optionPrice*optionQty; 
         console.log(`Option price: ${optionPrice} X Option Qty: ${optionQty} = ${optionAmount}`);
          totalAmount += optionAmount;
          addonAmount += optionAmount;
           
        }
        console.log("Total amount for addon is "+addonAmount);
        console.log("Net total is "+totalAmount);
      }
      totalAmount = totalAmount.toFixed(2);
      this.totalAmounts[productId] = totalAmount;
      console.log("Calculated total amount for product id "+productId+" is "+totalAmount );
    } 
     
  }
  isAddonChecked(id) {
    //console.log("Checked addons");
    //console.log(JSON.stringify(this.checkedAddons));
    //console.log("Id is "+id);  
    console.log(this.checkedAddons[id]);
    return this.checkedAddons[id] == true;
  }
  isAddonDisabled(id) {
    console.log(this.productAddons);
    console.log(id);
    var addon;
    var i :any = -1;
    var ret = false; 
    ret = this.checkedAddons[id] == false  
    console.log("Checked addons is "+ret);
    var anyOneSet = false;
    for(var idx in this.checkedAddons) {
      if(this.checkedAddons[idx] == true) {
        anyOneSet = true;
        break;
      }
    } 
    ret = !anyOneSet?false: ret; 
    return ret;
  }
  open(content, modalCategoryId, modalType) {

    //console.log('modal CategoryId : ' + modalCategoryId)
    //console.log('modal modalType : ' + modalType)
    this.addressArray = [];
    this.searchResultProducts = false;
    this.modalReference = this.modalService.open(content, { size: '', centered: true, windowClass: 'modal-custom' });
    this.modalReference.result.then((result) => {
    }, (reason) => {
      this.AddressCreateForm.reset();
    });

    if (modalType === "add_product") {
      this.addProductBtnLabel = "Add";
      this.checkedAddons = [];
      // console.log("Content is ");
      // console.log(content);
      // console.log("category id is ");
      // console.log(modalCategoryId);
      this.modalQuantity = 1
      this.count = 1;
      this.searchResultProducts = false
      console.log("Calling get product details api...");
      this.ApiService.getProductdetails(modalCategoryId).subscribe((res: any) => {
        //console.log(res);
        if (res.success == 1) {
          this.imageBase = res.imageBase
          this.productItems = res['item'];
          var productId =  res['item'].id;
          var hasAddons = false;
          //console.log("Items are ");
          //console.log(JSON.stringify(this.productItems));
          this.modalTotalAmount = parseFloat(this.productItems.sellingPrice)
          this.productPrice = this.modalTotalAmount;
          this.productAddons = this.productItems['addOns'];
          console.log("Product addons are");
          console.log(JSON.stringify(this.productAddons));
          for(var addon of this.productAddons) {
            hasAddons = true;
            var addonId = addon._id;
            var options = addon.options;
            for(var option of options) {
              var optionId = option.restaurantItemOptionId; 
              this.checkedAddons[optionId] = false;
              this.selectedOptions[optionId] = {quantity: 0}
            }
          }
          this.productMeta[productId] = {hasAddons: hasAddons};
        }
       // this.calculateTotalAmountForProductsInModal();
        this.calculateTotalAmountForPickedProducts(true);
      })
    } else if (modalType === "update_product") { 
      this.addProductBtnLabel =  "Update"; 
      //console.log("Picked products")
      
      this.ngLoader = true;
      //console.log(JSON.stringify(this.pickedProducts));
      this.modalQuantity = 1
      this.searchResultProducts = false
      this.productItems = [];
      this.productAddons = [];
      this.modalTotalAmount = null;
      //this.checkedAddons = {};
      this.ApiService.getProductdetails(modalCategoryId).subscribe((res: any) => { 
        
        this.ngLoader = false;
        var productId = modalCategoryId;
        var existingValues = null;
        for(var pr of this.pickedProducts) {
          if(pr.restaurantItemId == modalCategoryId) {
            existingValues = pr;
            break;
          }
        }
        if (res.success == 1) { 
          this.imageBase = res.imageBase
          this.productItems = res['item']
          
          this.productPrice = res['item'].sellingPrice;
          if(existingValues) {
            if(existingValues.quantity) {
              this.modalQuantity = existingValues.quantity;
            }
          }
          this.productAddons = this.productItems['addOns'];
          
          //console.log("Setting quantity to product addons");
          for(var i in this.productAddons) {
            var addon = this.productAddons[i]
            var options = addon.options;
            var isMultiSelectEnabled = addon.isMultiSelectEnabled; 
            
            for(var j in options) {
              var option = options[j];
              var optionId = option.restaurantItemOptionId;
              
              if(this.selectedOptions[optionId].quantity) { 
                this.productAddons[i].options[j].quantity = this.selectedOptions[optionId].quantity;
                if(!isMultiSelectEnabled) { 
                  this.checkedAddons[optionId] = true;
                }
              } 
            }
          }
          
          var addons = [];
       
          for(var addon of this.productAddons) {
            var addonId = addon._id;
            var addonName = addon.name;
            var addonResItemOpId = addon.restaurantItemOptionId; 
            options = [];
            var addonOptions = addon.options;
            for(var option of addonOptions) {
              var optionId = option._id;
              var optionName = option.name;
              var price = option.price;
              var qty = option.quantity;
              var resItOpId = option.restaurantItemOptionId;
              var optionObj = {
                id: optionId,
                name: optionName,
                quantity: qty,
                price: price,
                restaurantItemOptionId: resItOpId
              };
              if(qty)
                options.push(option);
            }
            var addonToPush = { 
              id: addonId,
              name: addonName,
              options: options,
              restaurantItemOptionId: addonResItemOpId
            };
            if(options.length)
              addons.push(addonToPush);
          }
          this.addOns = addons; 
          console.log("Selected options on update are");
          console.log(JSON.stringify(this.selectedOptions));
          this.calculateTotalAmountForPickedProducts(true);
        }
      })
    }
    console.log("Modal type "+modalType);
    if ((modalType === "update_address")||(modalType === "create_address")){
      this.getEmiratesList();
    }
    if (modalType === "update_address") {

    if (this.selectedcustomerId != undefined) {

    }else{
      this.toastr.warning('Please select Customer');
    }
  }



  }
  
  get f() {
    return this.createCustomerForm.controls;
  }

  get f2() {
    return this.createOrderForm.controls;
  }
  get f3() {
    return this.AddressCreateForm.controls;
  }

  handleRestaurantRegistration() {
    var token =  localStorage.getItem('UserToken');
    var isLoggedIn = false;
    if(token) {
      isLoggedIn = true;
    }
    if(!isLoggedIn) { 
      this.router.navigate(['login']);
      return false;
    }
    var id = localStorage.getItem('restaurantId'); 
    if(id) {
      id = JSON.parse(id);
    }
    if(id) {
      this.id = id;
    } else {
      //console.log("No id found");
      this.router.navigate(['get-started']);
      return false;
    }
    return true;
  }
  
  loadOrderDetails(orderId) {
    this.orderId = orderId;  
    this.ngLoader = true;  
    this.ApiService.getOrderDetails(orderId).subscribe((res:any)=>{
      this.ngLoader = false;
      
      if (res.success == 1) {
        var orderDetails = res['orderData'];  
         
        var userId = null;
        var user :any =  orderDetails.userId;
        var userName = user.name;
        var userPhone = user.phone; 
        if(user) {
          userId = user.id;  
        }
        this.selectedcustomerId = userId; 
        var isCashOnDelivery=  orderDetails.isCashOnDelivery == true;
        var isCardOnDelivery = orderDetails.isCardOnDelivery == true;
        var isOnlinePayment = orderDetails.isOnlinePayment == true;
        var isDineIn = orderDetails.isDineIn == true;
        var isTakeAway = orderDetails.isTakeAway == true;
        var isHomeDelivery = orderDetails.isHomeDelivery == true;
        var userId = null;
        var userName = null;
        if(orderDetails.userId) {
          var userData = orderDetails.userId;
          if(userData) {
            userId = userData.id; 
            userName = userData.name;
          }
        }
        var tableName =  orderDetails.tableName;
        var orderNotes = orderDetails.orderNotes;
        var items = orderDetails.items;
        console.log("Order details are");
        console.log(JSON.stringify(orderDetails));



         

        if (isDineIn ) { 
          this.showTableInputs = true
        } 
         if ( isHomeDelivery == true) { 
          this.showDeliveryInputs = true
        }

        var orderMethod = null;
        if(isOnlinePayment) {
          orderMethod = "isOnlinePayment";
        } else if(isCashOnDelivery) {
          orderMethod = "isCashOnDelivery";
        } else if(isCardOnDelivery) {
          orderMethod = "isCardOnDelivery";
        }

        var deliveryMethod = null;
        if(isDineIn) {
          deliveryMethod = "isDineIn";
        } else if(isHomeDelivery) {
          deliveryMethod = "isHomeDelivery";
        } else if(isTakeAway) {
          deliveryMethod = "isTakeAway";
        }
       
        this.createOrderForm.patchValue({ orderMethod: orderMethod }); 
        this.createOrderForm.patchValue({ deliveryMethod: deliveryMethod }); 
        this.createOrderForm.patchValue({ userId: userId }); 
        this.createOrderForm.get('tableName').patchValue(tableName); 
        this.createOrderForm.patchValue({ orderNotes:  orderNotes}); 
        this.createOrderForm.patchValue({ userId: userName });
        this.selectedcustomerName =  userName;
        var pickedProducts = [];
 
        
        var productFullDetails = orderDetails.productFullDetails;
        for(var product of productFullDetails) {
          var productId = product.id;
          var hasAddons = false; 
          if(product) {
            if(product.addOns && product.addOns.length){  
              hasAddons = true; 
              var addons = product.addOns;
              for(var addon of addons) {
                var addonId = addon._id; 
                var options = addon.options; 
                console.log("Options being processed are....");
                console.log(JSON.stringify(options));
                for(var option of options) {
                  var optionId = option._id; 
                  var resItOpId =  option.restaurantItemOptionId;
                  this.selectedOptions[resItOpId] = {quantity: 0};
               
                }
              } 
            } else {  
            }
            this.productMeta[productId] = {hasAddons: hasAddons};
          } 
        }


        for(var i in items) {// items from order details
          var item = items[i];
          var productId = item.restaurantItemId;
          this.currentProducts[productId] = true;
          item.id = productId;
          var addons = item.addOns;
          for(var k in addons) {
            var addon =  addons[k];
            var addonResItemOpId = addon.restaurantItemOptionId;  
            console.log(JSON.stringify(addon));
            addon.id = addonResItemOpId;
            addon._id = addonResItemOpId; 
            console.log(JSON.stringify(addon));
            var options =  addon.options; 
            for(var j in   options) {
              var option = options[j];
              console.log("Option being processed is ");
              console.log(JSON.stringify(option));
              var qty = option.quantity?option.quantity:"0"; 
              qty = parseInt(qty);
              var optionId = option.restaurantItemOptionId;
              console.log("Option id identified is "+optionId);
              this.selectedOptions[optionId] = {quantity: qty};
              option.price = option.sellingPrice;
              options[j] = option;
            }
            addon.options = options;
            addons[k] = addon;
          }
           
          item.addOns = addons;
          
          pickedProducts.push(item);
        }
        
        
        this.pickedProducts =  pickedProducts;
        if(orderDetails.addressId) {
          var address = orderDetails.addressId;
          var id = address.id;
          var addressType = address.addressType;
          var addressLine = address.addressLine;
          var emirate = null;
          var area = null;
          if(address.emiratesId) {
            emirate = address.emiratesId.name;
          }
          if(address.deliveryAreaId) {
            area = address.deliveryAreaId.name;
          }
          console.log("Calling selectedAddress function with id "+ id)
          this.selectedAddress(id, addressType, addressLine, emirate, area, address);
        }
        this.calculateTotalAmountForPickedProducts(false); 
        
      }
    })
  }
  prepareLoadedProducts(productId) {
     
    this.addProductBtnLabel =  "Update"; 
    //console.log("Picked products")
    
    this.ngLoader = true;
    //console.log(JSON.stringify(this.pickedProducts));
    this.modalQuantity = 1
    this.searchResultProducts = false
    this.productItems = [];
    this.productAddons = [];
    this.modalTotalAmount = null;
    //this.checkedAddons = {};
    this.ApiService.getProductdetails(productId).subscribe((res: any) => { 
      
      this.ngLoader = false;
      var productId = productId;
      var existingValues = null;
      for(var pr of this.pickedProducts) {
        if(pr.restaurantItemId == productId) {
          existingValues = pr;
          break;
        }
      }
      if (res.success == 1) { 
        this.imageBase = res.imageBase
        this.productItems = res['item']
        
        this.productPrice = res['item'].sellingPrice;
        if(existingValues) {
          if(existingValues.quantity) {
            this.modalQuantity = existingValues.quantity;
          }
        }
        this.productAddons = this.productItems['addOns'];
        
        //console.log("Setting quantity to product addons");
        for(var i in this.productAddons) {
          var addon = this.productAddons[i]
          var options = addon.options;
          var isMultiSelectEnabled = addon.isMultiSelectEnabled;
          console.log("Multiselect enabled is "+isMultiSelectEnabled+" for addon "+addon.name);
          
          for(var j in options) {
            var option = options[j];
            var optionId = option.restaurantItemOptionId;
            
            if(this.selectedOptions[optionId].quantity) { 
              this.productAddons[i].options[j].quantity = this.selectedOptions[optionId].quantity;
              if(!isMultiSelectEnabled) { 
                this.checkedAddons[optionId] = true;
              }
            } 
          }
        }
        //this.addOns = this.productAddons;
        //console.log(JSON.stringify(this.productAddons)) 
        var addons = [];
        /*
        
  addonObj = {
    id: addonId,
    name: addonName,
    options: []
  }
  optionObj = {
    id: optionId,
    name: optionName,
    quantity: qty,
    price: price
  }
        */
        for(var addon of this.productAddons) {
          var addonId = addon._id;
          var addonName = addon.name;
          options = [];
          var addonOptions = addon.options;
          for(var option of addonOptions) {
            var optionId = option._id;
            var optionName = option.name;
            var price = option.price;
            var qty = option.quantity;
            var optionObj = {
              id: optionId,
              name: optionName,
              quantity: qty,
              price: price
            };
            if(qty)
              options.push(option);
          }
          var addonToPush = { 
            id: addonId,
            name: addonName,
            options: options
          };
          if(options.length)
            addons.push(addonToPush);
        }
        this.addOns = addons; 
        this.calculateTotalAmountForPickedProducts(true);
      }
    })
  
  }
  ngOnInit() {
    var ok = this.handleRestaurantRegistration();
    if(!ok) {
      return;
    }
    
    this.ApiService.getPromoCodeApi(null).subscribe(
      (res: any) => {
       console.log("promocode api res----------------");
       this.promoCodeLists = res.items;
       console.log(this.promoCodeLists);
      this.promoCodeLists = this.promoCodeLists.filter((data)=>{
        if(data.isActive == true)
        {
          return data;
        }
      })      
      })
    
    //this.getTableList()
    //this.handleEmiratesList()

    this.createCustomerForm = this.fb.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.minLength(9)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', [Validators.required, Validators.minLength(4)]],
    })

    this.createOrderForm = this.fb.group({
      userId: ['', [Validators.required]],
      items: ['',],
      orderNotes: ['',],
      deliveryAddress: ['',],
      deliveryAreaId: ['',],

      isDineIn: [false,],
      isHomeDelivery: [false,],
      isTakeAway: [false,],

      isCashOnDelivery: [false,],
      isCardOnDelivery: [false,],
      isOnlinePayment: [false,],

      orderMethod: ['', [Validators.required]],
      deliveryMethod: ['', [Validators.required]],
      deliveryOption: ['',],
      tableName: ['',],
      productInput: [''],
      addressId: ['',],
      address: ['',],
      promoCode:['']
    });
    this.AddressCreateForm = this.fb.group({
      emiratesId: ['', [Validators.required]],
      deliveryAreaId: ['', [Validators.required]],
      addressLine: ['', [Validators.required]], 
      addressType: ['', [Validators.required]],
      isDefault: [false],
    });
    this.pageTitle = "Create Order";
    var orderId = this.route.snapshot.paramMap.get('id') ;
    if(orderId) {
      this.pageTitle = "Update Order";
      this.loadOrderDetails(orderId) 
    }
  }


  selectEmirates(e) {
    this.emiratesId = e.target.value
    this.handleAreaList()
  }

  selectArea(e) {
    this.areaId = e.target.value
  }
  handleEmiratesList() {
    this.ApiService.getEmiratesList().subscribe((data) => {
      this.emiratesData = data['items']
    })
  }
  handleAreaList() {
    this.ApiService.getAreaList(this.emiratesId).subscribe((data) => {
      this.areaData = data['items']
    })
  }

  getTableList() {
    this.ApiService.tableListApi().subscribe((res: any) => {
      this.resTableList = res['items']
    })
  }

  onSearchCustomer(searchValue: string): void {
    searchValue = searchValue.trim();
    
    this.addressArray = [];


    var customerId = null;
 
    if( this.selectedcustomerId) {
      this.selectedcustomerId = customerId
      this.selectedcustomerName = null
      this.selectedcustomerPhone = null
    }
    console.log("Form value is");
    console.log(JSON.stringify(this.createOrderForm.value));


    if (searchValue != "") {
      this.ngLoader = true;
      this.ApiService.searchCustomerApi(searchValue).subscribe((res: any) => {
        if (res.success == 1) {
          this.ngLoader = false;
          this.searchResult = res['items']
          console.log("Search result is ");
          console.log(JSON.stringify(this.searchResult));
        }
      })
    } else if (searchValue === "") {
      this.searchResult = false
      this.selectedcustomerId = null;
    }
  }
  onSearchAddress(searchValue,eventName) {    
    if(eventName == "click") {
      searchValue = null;
      this.createOrderForm.patchValue({"address": ""});
    }
    this.addressArray = []
    if (this.selectedcustomerId != undefined) {
      this.ngLoader = true;
      this.ApiService.getCustomerDetails(this.selectedcustomerId).subscribe((res: any) => {
        if (res.success == 1) {
          this.ngLoader = false;
          this.addressList = res['items'].addresses;
          if(searchValue) {
            let search = new RegExp(searchValue , 'i'); // prepare a regex object
            this.addressList = this.addressList.filter(address => search.test(address.addressLine));
          }
          for (let item of this.addressList) {
            console.log("Address is", item);
            console.log("Address item address id is -------------------------------")
            console.log(item._id)
            if (item.status != 0) {
              var addressLine = item.addressLine;
              var emirate = item.emirate;
              var area = item.area;
              var emirateName = emirate?emirate.name:"";
              var areaName = area?area.name:"";
              var newItem = item;
              delete newItem.emirate;
              delete newItem.area;
              item.emirate = emirateName;
              item.area = areaName;
              console.log(`Item being pushed is`, newItem);
              this.addressArray.push(newItem)
            }
          }
        }
      })
    }else{
      this.toastr.warning('Please select Customer');
    }
  }
  selectedAddress(id, addressType, addressLine, emirate, area, itemRef) {
    console.log(`Selected item ref is`, itemRef);
    /**
     * 
									<p>{{addressType}} </p>							 
									<p class="m-0"><span  class="font-weight-bold">Flat No / Block / Street: {{addressLine}}</span></p>							 
									<p class="m-0"><span  class="font-weight-bold">Area:</span> {{areaName}}</p>							 
									<p class="m-0"><span  class="font-weight-bold">Emirate:</span> {{emirateName}}</p>	
     */
    this.selectedAddressId = id;
    console.log(`Selected address is id`,id);
    this.selectedaddressType = addressType ;
    this.addressType = addressType
    this.addressLine = addressLine;
    this.emirateName = emirate;
    this.areaName = area;
    var address = `${addressLine}, ${area}, ${emirate}`;
    this.createOrderForm.patchValue({ addressId: this.selectedAddressId });
    this.createOrderForm.patchValue({ address: address });
    console.log("Patching form value ----------------------------------------")
    console.log(this.selectedAddressId)
    console.log(this.createOrderForm.value.addressId)

    this.addressArray = []
  }
  onDeleteAddress() {
    this.createOrderForm.patchValue({ addressId: null });
    this.addressArray = []
    this.selectedaddressType = ''
  }

  onSearchProducts(searchValue: string): void {
    if (searchValue != "") {
      this.ApiService.getProductList('', searchValue).subscribe((res: any) => {
        //console.log("Res");
        //console.log(JSON.stringify(res));
        this.imageBase = res.imageBase
        var items =  res['items'];
        //console.log("Items are");
        //console.log(JSON.stringify(items));

        var newItems :any = [];
        for(var idx in items) {
          var item = items[idx];
          var productId = item.itemId;
          if(!this.currentProducts[productId]) {
            newItems.push(item);
          }
        }
        this.searchResultProducts = newItems;
      })
    } else if (searchValue === "") {
      this.searchResultProducts = false
    }
  }


  selectCustomer(customerId, customerName, phone, defaultAddress) {
    console.log("Select customer called.");
    this.selectedcustomerId = customerId;
    this.createOrderForm.patchValue({ userId: customerId });
    this.selectedcustomerName = customerName
    this.selectedcustomerPhone = phone
    this.searchResult = false;
    if(defaultAddress) {
      var id = defaultAddress.userAddressId;
      var addressType =  defaultAddress.addressType;
      var addressLine = defaultAddress.addressLine;
      var areaName = defaultAddress.deliveryAreaName;
      var emirateName = defaultAddress.emirateName; 
      console.log("Calling selectedAddress function with id "+ id)
      this.selectedAddress(id, addressType, addressLine, emirateName, areaName, defaultAddress);
    }
  }

  onSubmitCreateCustomer() {

    this.submitted1 = true;
    this.clicked1 = true;
    if (this.createCustomerForm.invalid) {
      this.clicked1 = false;
      return;
    }

    console.log(this.createCustomerForm.value)
    this.ngLoader =  true;
    this.ApiService.createCustomerApi(this.createCustomerForm.value).subscribe((res: any) => {
      //console.log(res);
      if (res.success == 1) {
        this.searchResult = [];
        this.addressArray = [];
        this.toastr.success(res.message);
        var userId = res['id'];
        this.selectedcustomerId = userId;
        this.createOrderForm.patchValue({ userId: userId });
        this.selectedcustomerName = res['name']
        this.selectedcustomerPhone = res['phone']

        this.createCustomerForm.reset();
        this.submitted1 = false;
        this.clicked1 = false;
        this.toggleContainer = false;

        this.selectedAddressId = null;
        this.createOrderForm.patchValue({ addressId: null });
        this.addressArray = [];
        this.selectedaddressType = null ;
        this.addressType = null
        this.addressLine = null;
        this.emirateName = null;
        this.areaName = null;


      } else {
        this.toastr.warning(res.message);
      }
      this.ngLoader =  false;
    }, error => {
      this.ngLoader =  false;
      this.toastr.error(error.error.message);
    })

  }
  getFormValidationErrors() {
    console.log("printing errors now");
    Object.keys(this.createOrderForm.controls).forEach(key => {
      
    const controlErrors = this.createOrderForm.get(key).errors;
    console.log("Errors for "+key);
    console.log(JSON.stringify(controlErrors));
    if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
    }
  checkdeliveryMethod(event) {
    console.log(event.target.value)
    if (event.target.value === 'isHomeDelivery') {
      this.showDeliveryInputs = true
    } else {
      this.showDeliveryInputs = false
    }
    if (event.target.value === 'isDineIn') {
      this.showTableInputs = true
    } else {
      this.showTableInputs = false
    }

  }
  onSubmitCreateOrder() {
    this.addressError = null;
    this.submitted = true;
    console.log("Create order api called...");
    console.log("Order form value is ");
    if(!this.selectedcustomerId) { 
      this.createOrderForm.patchValue({ userId: null });
    }
    console.log(this.createOrderForm.value);
    console.log(JSON.stringify(this.createOrderForm.value));
    this.setDeliveryMethod = this.createOrderForm.value.deliveryMethod
    this.setOrderMethod = this.createOrderForm.value.orderMethod
    if(this.selectedAddressId) {
      console.log('Setting address id', this.selectedaddressType);
      this.createOrderForm.patchValue({ addressId: this.selectedAddressId });
    }
    var addressId = this.createOrderForm.value.addressId;
    
    var isDineIn = false;
    var isTakeAway = false;
    var isHomeDelivery = false;
    if (this.setDeliveryMethod === 'isDineIn') {
      isDineIn = true;
      this.createOrderForm.patchValue({ isDineIn: true });
      this.createOrderForm.patchValue({ isTakeAway: false });
      this.createOrderForm.patchValue({ isHomeDelivery: false });
    }
    else if (this.setDeliveryMethod === 'isTakeAway') {
      isTakeAway = true;
      this.createOrderForm.patchValue({ isDineIn: false });
      this.createOrderForm.patchValue({ isTakeAway: true });
      this.createOrderForm.patchValue({ isHomeDelivery: false });
    }
    else if (this.setDeliveryMethod === 'isHomeDelivery') {
      isHomeDelivery = true;
      console.log("Is home deliuvery");
      this.createOrderForm.patchValue({ isDineIn: false });
      this.createOrderForm.patchValue({ isTakeAway: false });
      this.createOrderForm.patchValue({ isHomeDelivery: true });
      console.log(`Selected address id is`, this.selectedAddressId);
      if(!addressId  &&  !this.selectedAddressId) {
        console.log("No address found....");
        this.addressError  = "Address is required.";
        return;
      }
    }
    if (this.setOrderMethod === 'isCashOnDelivery') {
      this.createOrderForm.patchValue({ isCashOnDelivery: true });
      this.createOrderForm.patchValue({ isCardOnDelivery: false });
      this.createOrderForm.patchValue({ isOnlinePayment: false });
    }
    else if (this.setOrderMethod === 'isCardOnDelivery') {
      this.createOrderForm.patchValue({ isCashOnDelivery: false });
      this.createOrderForm.patchValue({ isCardOnDelivery: true });
      this.createOrderForm.patchValue({ isOnlinePayment: false });
    }
    else if (this.setOrderMethod === 'isOnlinePayment') {
      this.createOrderForm.patchValue({ isCashOnDelivery: false });
      this.createOrderForm.patchValue({ isCardOnDelivery: false });
      this.createOrderForm.patchValue({ isOnlinePayment: true });
    }
    // if (this.showTableInputs = true) {
    //   console.log('table XXXXXXXXXXXXX')
    // }

    //console.log(this.createOrderForm.value)


    this.clicked = true;
    var isFormValid = !this.createOrderForm.invalid;
    
    isFormValid = isFormValid&&(this.pickedProducts.length>0);
    if (!isFormValid) {
      console.log("Order form is invalid...."); 
      this.clicked = false;
      return;
    }
    var formData = this.createOrderForm.value;
    if(this.selectedcustomerId) {
      formData.userId = this.selectedcustomerId;
    }
    if(isHomeDelivery) {
      formData.addressId = this.selectedAddressId; 
    } else {
      formData.addressId = null;
    }

    if(this.createOrderForm.value.promoCode)
    {
      console.log(this.createOrderForm.value.promoCode);
     formData.promoCode = this.createOrderForm.value.promoCode;
    }
    
    console.log("Address id is "+formData.addressId);
    if(!this.orderId) {
      console.log("Submitting data ");
      console.log(JSON.stringify(formData)); 
      this.ApiService.createOrderApi(formData).subscribe((res: any) => {
        //console.log(res);
        if (res.success == 1) {
          
          this.router.navigate(['orders']);
          this.toastr.success(res.message);
          this.toggleContainer = false
        } else {
          this.toastr.warning(res.message);
        }
      }, error => {
        this.toastr.error(error.error.message);
        //console.log(error.error.errors);
      });

    } else {
      var items = this.pickedProducts; 
      for(var i in items) {
        var item = items[i];
        var addons = item.addOns;
        for(var j in addons ) {
          var addon = addons[j]; 
          var options = addon.options;
          for(var k in options) {
            var option = options[k]; 
            option.id = option.restaurantItemOptionId;
            options[k] = option; 
          }
          addon.options = options;
          delete addon._id; 
          addons[j] = addon;
        }
        item.addOns = addons;
        console.log("Submitting item ");
        console.log(JSON.stringify(item));
        items[i] = item;
      }
      formData.items = items;
      console.log("Calling update order api");
      this.ngLoader = true;
      console.log("Items are ");
      console.log(JSON.stringify(items));
      console.log("Formdata ");
      console.log(JSON.stringify(formData));
      
      this.ApiService.updateOrderDetails( this.orderId,formData).subscribe((res: any) => {
       
        this.ngLoader = false;
        if (res.success == 1) {
          this.toastr.success(res.message);
          this.toggleContainer = false;
          this.router.navigate(['orders']);
        } else {
          this.toastr.warning(res.message);
        }
        
      }, error => {
        console.log("Error is ");
        console.log(JSON.stringify(error));
        this.toastr.error(error.error.message);
        console.log(error.error.errors);
        this.ngLoader = false;
      });
    }



  }

  addonsSelectOne1(index, addonId, addonName, optionId, optionName, qty, event, price) {
  }
  addonsSelectOne(index, addonId, addonName, optionIdOrig, optionName, qty, event, price, addonRestaurantItemOptionId,optionRestaurantItemOptionId) {
    console.log("Selected addon restaurant op id is "+addonRestaurantItemOptionId);
    console.log("Selected option restaurant op id is "+optionRestaurantItemOptionId);
    var optionId = optionRestaurantItemOptionId;
    var checked = event.target.checked; 
    if(checked) {
      this.checkedAddons[optionId] = true;
      this.selectedOptions[optionId] = {quantity: 1};
      qty = 1;
    } else {
      this.checkedAddons[optionId] = false;
      this.selectedOptions[optionId] = {quantity: 0};
      qty = 0;
    }
    //console.log("Qaty of selected is "+qty);
    //console.log("Checked addons after");
    //console.log(JSON.stringify(this.checkedAddons));
    this.isCheckedName = event.target.name;
    price = parseFloat(price);
    let addonObj = {}
    let optionObj = {}
    addonObj = {
      id: addonId,
      name: addonName,
      options: [],
      restaurantItemOptionId: addonRestaurantItemOptionId
    }
    optionObj = {
      id: optionIdOrig,
      name: optionName,
      quantity: qty,
      price: price,
      restaurantItemOptionId: optionRestaurantItemOptionId
    }
    addonObj['options'] = []
    addonObj['options'].push(optionObj);
    let addonIndex = this.addOns.findIndex(x => x.id === addonId);
    //console.log("Addons obj");
   // console.log(JSON.stringify(this.addOns));

   let isOld = false;
   let indexOfAddon = this.productItems.addOns.findIndex(x => x._id === addonId);
    
   if (indexOfAddon > -1) {
     //console.log("Option already exists...");

     //let optionsArray = this.addOns[addonIndex].options;
     //let oldOptionId = optionsArray[0].id;
     let oldOptionIndex = this.productItems.addOns[indexOfAddon].options.findIndex(x => x.restaurantItemOptionId === optionId);
     
      
     if (oldOptionIndex > -1) {
       let oldPrice = this.productItems.addOns[indexOfAddon].options[oldOptionIndex].price;
       this.productItems.addOns[indexOfAddon].options[oldOptionIndex].quantity = checked?1:0; 

     } 
   }  



    if (addonIndex > -1) {
      //console.log("Addon already exists...");
      this.addOns.splice(addonIndex, 1);
      //this.addOns.push(addonObj); 
      
    } else {
      //console.log("Addon does not exists...");
      //this.modalTotalAmount = this.modalTotalAmount + price
      //this.addOns.push(addonObj);
    } 
    if(qty)
      this.addOns.push(addonObj);
     
    //console.log("Addons in aaa");
    //console.log(JSON.stringify(this.addOns));
     
    this.calculateTotalAmountForPickedProducts(true);
  }
  
  modifyQty(i, j, type, addonId, addonName, optionIdOrig, optionName, price, addonRestaurantItemOptionId, optionRestaurantItemOptionId) { 
    var optionId = optionRestaurantItemOptionId;
    let qty = this.selectedOptions[optionId].quantity;
    qty = parseInt(qty);
    console.log("Option res i op id is "+optionRestaurantItemOptionId);
    //console.log("Qty of selected option is "+qty);
    console.log("Addon restaurant item option id is "+addonRestaurantItemOptionId);
    //console.log("Type is "+type);
    price = parseFloat(price);
    var addonIndex = this.addOns.findIndex(x => x.id === addonId);
    //console.log("Addon index is "+addonIndex);
    if(addonIndex == -1) {
      addonIndex = this.addOns.length;
      let addonObj = {} 
      addonObj = {
        id: addonId,
        name: addonName,
        options: [],
        restaurantItemOptionId: addonRestaurantItemOptionId
      } 
      this.addOns.push(addonObj); 
    }
    
    var mainAddon = this.addOns[addonIndex];
   // console.log("Addon options are. ...");
    //console.log(JSON.stringify(mainAddon['options']));
    //console.log("Option name is "+optionName);
    var indexOfOption = mainAddon['options'].findIndex(x => x.restaurantItemOptionId === optionId);
    //console.log("Option index is "+indexOfOption);
    //console.log("Option index is "+indexOfOption);
    if(indexOfOption == -1) {
      //console.log("Option index is -1");
      indexOfOption = mainAddon['options'].length; 
      let optionObj = {
        id: optionIdOrig,
        name: optionName,
        quantity: qty,
        price: price,
        restaurantItemOptionId: optionRestaurantItemOptionId
      }
      this.addOns[addonIndex].options.push(optionObj);
      this.productAddons[i].options[j].quantity = qty; 
    }
    //console.log("Index of option is "+indexOfOption);
    //console.log("Selected option is "+optionId);
    mainAddon = this.addOns[addonIndex]; 
    
    var productItemAddonIndex = this.productItems.addOns.findIndex(x => x.restaurantItemOptionId === addonRestaurantItemOptionId);
    console.log("Product item addon index is "+productItemAddonIndex);
    let maxOptionCount = this.productItems.addOns[productItemAddonIndex].maxAllowedOptionsCount;
    if(type == 'plus') { 
      let isHigherThanMaximumQty = this.checkQuantity(this.productAddons[i].options, type, maxOptionCount);
      if(!isHigherThanMaximumQty) {
       console.log("Incrementing quantity "+qty);
        qty++; 
        console.log("Incremented quantity "+qty);
      } else { 
       // console.log("Not incrementing quantity as value is higher than allowed");
        this.toastr.warning(`Maximum ${maxOptionCount} options are allowed...`);
      }
    } else {
      //console.log(`Going to decrement qty`);
      if(qty>0) {
        //console.log(`Not decrementing qty as value is 0`);
        qty--; 
      }
    }

    
    this.selectedOptions[optionId].quantity = qty;
    this.productAddons[i].options[j].quantity = qty;
    this.addOns[addonIndex].options[indexOfOption].quantity = qty;
    if(!qty) {
       this.addOns[addonIndex].options.splice(indexOfOption, 1);
       //this.productAddons[i].options.splice(j, 1);
    }
    if(!this.addOns[addonIndex].options.length) {
      this.addOns.splice(addonIndex, 1);
      //this.productAddons.splice(i, 1);

    }

    
    //console.log(JSON.stringify(this.productAddons));
    this.calculateTotalAmountForPickedProducts(true);
  }

  modifyQty1(i, j, type, addonId, addonName, optionId, optionName, price) {
    

    let indexOfAddon = this.productItems.addOns.findIndex(x => x._id === addonId);
    let maxOptionCount = this.productItems.addOns[indexOfAddon].maxAllowedOptionsCount;

    var addonIndex = this.addOns.findIndex(x => x.id === addonId);
    if (addonIndex > -1) {
      var mainAddon = {};
      mainAddon = this.addOns[addonIndex];
      var indexOfOption = mainAddon['options'].findIndex(x => x.id === optionId);
      if (indexOfOption > -1) {
        //let qty = parseInt(mainAddon['options'][indexOfOption].quantity);
        let qty = this.selectedOptions[optionId].quantity;
        //console.log("Current quantity is "+qty);
        let isHigherMaximumQty = this.checkQuantity(mainAddon['options'], type, maxOptionCount);
        if (!isHigherMaximumQty) {
          if (type === 'plus') {
            qty = qty + 1;
           // this.modalTotalAmount = this.modalTotalAmount + price
          } else {
            qty = qty - 1;
            if (qty >= 0) {
             // this.modalTotalAmount = this.modalTotalAmount - price
            }
          }
          if (qty >= 0) {
            this.selectedOptions[optionId].quantity = qty;
            this.productAddons[i].options[j].quantity = qty;
            this.addOns[addonIndex].options[indexOfOption].quantity = qty;
            if (qty === 0) {
              if (this.addOns[addonIndex].options.length > 1) {
                this.addOns[addonIndex].options.splice(indexOfOption, 1)

              } else {
                this.addOns.splice(addonIndex, 1)
              }
            }
          }
        }
      } else {
        if (type === 'plus') {
          let isHigherMaximumQty = this.checkQuantity(mainAddon['options'], type, maxOptionCount);
          if (!isHigherMaximumQty) {
            let optionObj = {
              id: optionId,
              name: optionName,
              quantity: 1
            }
            this.addOns[addonIndex].options.push(optionObj);
            this.productAddons[i].options[j].quantity = 1;
            this.modalTotalAmount = this.modalTotalAmount + price
          }
        }
      }
    } else {
      if (type === 'plus') {
        let addonObj = {}
        let optionObj = {}
        addonObj = {
          id: addonId,
          name: addonName,
          options: []
        }
        optionObj = {
          id: optionId,
          name: optionName,
          quantity: 1
        }
        addonObj['options'] = []
        addonObj['options'].push(optionObj);
        this.addOns.push(addonObj);
        this.productAddons[i].options[j].quantity = 1;
        //this.modalTotalAmount = this.modalTotalAmount + price

      }
    }
    this.valueConut = this.productAddons[i].options[j].quantity

  }

  checkQuantity(options, type, maxOptionCount) {
    let qty = 0;
    let optionQty :any = 0;
    //console.log("Options to check are");
    //console.log(JSON.stringify(options));
    for (let i = 0; i < options.length; i++) { 
      optionQty = options[i].quantity;
      optionQty = optionQty?optionQty:0;
      //console.log("Qty for option "+i+" = "+optionQty);
      qty = qty + parseInt(optionQty) 
      //console.log("Total qty now "+qty);
    }
    //console.log("Final Total quantity is "+qty);
    if (type === 'plus') {
      qty = qty + 1;
    } else {
      qty = qty - 1;
    }
    console.log(qty + " is modified total qty")
    
    console.log("Max option count: "+maxOptionCount);
    if (maxOptionCount < qty) {
      return true;
    } else {
      return false;
    }
  }


  changeQtyModal(changeVal, addedItem, sellingPrice) {
     
    this.modalQuantity = 1;
    if (changeVal === 'plus') {
      if (this.count != 100) {
        this.count++;
        this.modalQuantity = this.count;
        //this.modalTotalAmount = this.modalTotalAmount + parseInt(sellingPrice)
      }
    }
    else {
      if (this.count != 1) {
        this.count--;
        this.modalQuantity = this.count;
       // this.modalTotalAmount = this.modalTotalAmount - parseInt(sellingPrice)
      }
    }
    this.calculateTotalAmountForPickedProducts(true);
    //console.log(this.modalQuantity)
  }

  modalAddProduct(productId, name, price, qty, productTotalPrice) {
    
    console.log("Create order selected options is");
    console.log(JSON.stringify(this.selectedOptions));
    let mainAddonIndex = this.pickedProducts.findIndex(x => x.restaurantItemId === productId);
    console.log("Value for addons is ");
    console.log(JSON.stringify(this.addOns));
    let pobj = {}
    pobj["restaurantItemId"] = productId;
    pobj["productName"] = name;
    pobj["productPrice"] = price;
    pobj["quantity"] = qty;
    pobj["totalPrice"] = productTotalPrice;
    pobj["addOns"] = this.addOns;
    pobj["id"] = productId;
 
    var isUpdate = false;
    for(var idx in this.pickedProducts) {
      var product = this.pickedProducts[idx];
      var id = product["restaurantItemId"];
      if(id == productId) { 
        isUpdate = true;
        break;
      } 
    }
    if(!isUpdate) 
      this.pickedProducts.push(pobj);
    else { 
      console.log("Updating product at index "+idx);
      this.pickedProducts[idx] = pobj; 
    }
    this.currentProducts[productId] = true;

    // if (mainAddonIndex > -1) {
    //   this.pickedProducts.splice(mainAddonIndex, 1);
    //   let pobj = {}
    //     pobj["restaurantItemId"] = productId;
    //     pobj["productName"] = name;
    //     pobj["productPrice"] = price;
    //     pobj["quantity"] = qty;
    //     pobj["totalPrice"] = productTotalPrice;
    //     pobj["addOns"] = this.addOns;
    //   this.pickedProducts.push(pobj);
    // } else {

    // }    
    //console.log( this.pickedProducts)
    this.createOrderForm.patchValue({ items: this.pickedProducts });
    this.createOrderForm.patchValue({ productInput: this.pickedProducts[0].productName });
    this.selectedProducts = this.pickedProducts[0].productName
    this.modalReference.close();
    this.isChecked = false
    this.addOns = []
    this.count = 1; 
    console.log("Picked products are ");
    console.log(JSON.stringify(this.pickedProducts));
    this.calculateTotalAmountForPickedProducts(true);
  }


  updateQty(changeVal, _index, prdQty, productAmount, productModal) { 
    var product = this.pickedProducts[_index];
    var productId = product.restaurantItemId;
    if(!product) {
      return;
    }
    var openProductModal = product.addOns && product.addOns.length;
    if (changeVal === 'plus') {
      if (prdQty != 100) {
        prdQty++;
        this.pickedProducts[_index].quantity = parseInt(this.pickedProducts[_index].quantity) + 1
        this.pickedProducts[_index].totalPrice = parseInt(this.pickedProducts[_index].totalPrice) + parseInt(productAmount)
      }
    }
    else {
      
      prdQty--;
      if (prdQty == 0) {
        this.pickedProducts.splice(_index, 1)
        delete this.currentProducts[productId];
        openProductModal = false;
      } else  {
        this.pickedProducts[_index].quantity = this.pickedProducts[_index].quantity - 1
        this.pickedProducts[_index].totalPrice = parseInt(this.pickedProducts[_index].totalPrice) - parseInt(productAmount) 
       
      }
    } 
    // if(openProductModal) {
    //   console.log("Item id is "+product.restaurantItemId); 
    //   if(prdQty)
    //     this.open(productModal,product.restaurantItemId,'update_product');
    // }

    this.calculateTotalAmountForPickedProducts(true);
  }


  onSubmitAddressCreate(addressId) {
    if (this.selectedcustomerId != undefined) {
       this.createAddress()
    }else{
      this.toastr.warning('Please selecte Customer');
    }

   
    // if( this.isModalTypeEdit == "create_address"){
    //   this.createAddress() 
    // }else{
    //  this.updateAddress()
    // } 

  }
  
  getEmiratesList() { 
    this.ngLoader = true
    
    this.emiratesNameIdMap = {}; 
    this.ApiService.getEmiratesListPage(1, 10000).subscribe(result => {
      this.ngLoader = false 
      this.emirates = result['items']; 
      var emirates = this.emirates;
      console.log("Em list is ");
      console.log(JSON.stringify(emirates));
      for(var emirate of emirates) {
        var id = emirate.id
        var name = emirate.name;
        this.emiratesNameIdMap[id] =  name;
      }
    }
      , error => {
        this.ngLoader = false
        this.toastr.error(error.error.message);
      })
  }
  
  getAreasList(emirateId) {   
    this.areaNameIdMap = {};
    this.ngLoader = true;
    console.log("Emirate id is "+emirateId);
    this.ApiService.getAreaListPage(emirateId, 1, 10000).subscribe(result => {  
      this.areas = result['items'];
      this.ngLoader = false 
      var areas = this.areas; 
      for(var area of areas) {
        var id = area.id
        var name = area.name;
        this.areaNameIdMap[id] =  name;
      }
    }
      , error => {
        this.ngLoader = false
        this.toastr.error(error.error.message);
      })
  }
  createAddress(){    
    
    this.submitted2 = true;
    this.clicked2 = true;
    let formValue = this.AddressCreateForm.value
    if (this.AddressCreateForm.invalid) {
      this.clicked = false;
      return;
    }
    this.ngLoader = true;
    this.ApiService.createAddressApi(this.selectedcustomerId,this.AddressCreateForm.value).subscribe((res: any) => {
      this.ngLoader = false;
      if (res.success == 1) {
        console.log('Address create result is');
        console.log(res);
        this.toastr.success(res.message);
       // this.handleCustomerDetails();
        var formValue = this.AddressCreateForm.value;
        this.selectedaddressType = formValue.addressType + ', ' + formValue.addressLine;
        this.addressType = formValue.addressType;
        this.addressLine = formValue.addressLine;
        var emiratesId = formValue.emiratesId;
        var areaId = formValue.deliveryAreaId;
        console.log("Emirate id is "+emiratesId);
        console.log("Name id map is ");
        console.log(JSON.stringify(this.emiratesNameIdMap));
        var emirateName =  this.emiratesNameIdMap[emiratesId];
        var areaName =  this.areaNameIdMap[areaId];
        this.emirateName = emirateName;
        let addressId = res.addressId;
        
        console.log("Emirate name is ");
        console.log(emirateName);
        this.areaName = areaName;
        


        this.modalReference.close();
        this.AddressCreateForm.reset();
        this.submitted2 = false;
        this.clicked2 = false;
        this.addressArray =[]

        
       this.selectedAddress(addressId,this.addressType , this.addressLine, this.emirateName, this.areaName, formValue);
      } else {
        this.toastr.warning(res.message);
      }
    }, error => {
      this.ngLoader = false;
      this.toastr.error(error.error.message);
    })
  }

  // updateAddress(){
  //   this.ApiService.updateAddressApi(this.pageId,this.updateAddressId,this.AddressCreateForm.value).subscribe((res: any) => {
  //     if (res.success == 1) {
  //       this.handleCustomerDetails();
  //       this.modalReference.close();
  //       this.AddressCreateForm.reset();
  //       this.submitted = false;
  //       this.clicked = false;
  //       this.addressArray =[]
  //       this.toastr.success(res.message);            
  //     } else {
  //       this.toastr.warning(res.message);
  //     }
  //   }, error => {
  //     this.toastr.error(error.error.message);
  //   })
  // }

}