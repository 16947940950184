import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationDialogService } from "../../confirmation-dialog/confirmation-dialog.service";
import { ApiService } from "src/app/services/api.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";

@Component({
  selector: "app-customers-edit",
  templateUrl: "./customers-edit.component.html",
  styleUrls: ["./customers-edit.component.scss"],
})
export class CustomersEditComponent implements OnInit {
  updateCustomerForm: FormGroup;
  AddressCreateForm: FormGroup;
  submitted: boolean = false;
  clicked: boolean = false;
  pageId: string;
  modalReference: NgbModalRef;
  addressList: any;
  addressArray = [];
  isModalTypeEdit: any;
  updateAddressId: any;
  addressDetails: any;
  ngLoader;
  userDetails: any;
  emiratesNameIdMap = {};
  areaNameIdMap = {};
  emirates = [];
  areas = [];
  addressFormSubmitted = false;
  addressIdToUpdate;
  constructor(
    private ApiService: ApiService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  id;
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  get f() {
    return this.updateCustomerForm.controls;
  }
  get addressFormControls() {
    return this.AddressCreateForm.controls;
  }
  openAddressModal(content) {
    this.modalReference = this.modalService.open(content, {
      size: "",
      centered: true,
      windowClass: "modal-custom",
    });
    this.modalReference.result.then(
      (result) => {},
      (reason) => {
        // console.log('closemodal')
        this.AddressCreateForm.reset();
      }
    );
  }
  onEmiratesChange(emirateId) {
    this.AddressCreateForm.patchValue({
      deliveryAreaId: "",
    });
    //this.AddressCreateForm.reset();
    this.getAreasList(emirateId, () => {
      ;
    });
  }
  open(content, modalId, type) {
    this.addressFormSubmitted = false;
    this.updateAddressId = modalId;
    this.isModalTypeEdit = type;
    this.getEmiratesList(() => {
      if (this.isModalTypeEdit == "update") {
        console.log("------------ Modal type is edit")
        this.ApiService.getAddressDetails(this.pageId, modalId).subscribe(
          (res: any) => {
            console.log("Address details are");
            if (res.success == 1) {
              this.addressDetails = res["addressData"];
              var address = res["addressData"];
              this.addressIdToUpdate = address._id;
              var that = this;
              this.getAreasList(address.emiratesId, () => {
                this.openAddressModal(content);
                let deliveryAreaId = this.areas.length?address.deliveryAreaId:"";

                console.log(
                  "---------------Patching value. address. emirates id is" +
                    address.emiratesId
                );
                that.AddressCreateForm.patchValue({
                  emiratesId: address.emiratesId,
                  addressType: address.addressType,
                  isDefault: address.isDefault,
                  deliveryAreaId: deliveryAreaId,
                  addressLine: address.addressLine,
                });
                console.log("-------------------- Value of Area after patching is " + that.AddressCreateForm.value.deliveryAreaId)
              });

              /**
               * 
        emiratesId: ['', [Validators.required]],
        deliveryAreaId: ['', [Validators.required]],
        addressLine: ['', [Validators.required]], 
        addressType: ['', [Validators.required]],
        isDefault: [false],
               */
            }
          }
        );
      } else {
        console.log("------------------ Modal type is create");
        this.AddressCreateForm.reset();
        this.openAddressModal(content);
      }
    });

    //setting default emirate and area for create form
  }

  handleRestaurantRegistration() {
    var token = localStorage.getItem("UserToken");
    var isLoggedIn = false;
    if (token) {
      isLoggedIn = true;
    }
    if (!isLoggedIn) {
      this.router.navigate(["login"]);
      return false;
    }
    var id = localStorage.getItem("restaurantId");
    if (id) {
      id = JSON.parse(id);
    }
    if (id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(["get-started"]);
      return false;
    }
    return true;
  }
  ngOnInit() {
    this.userDetails = { id: null, name: null, email: null };
    this.updateCustomerForm = this.fb.group({
      name: ["", [Validators.required]],
      phone: ["", [Validators.required, Validators.minLength(9)]],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
    });

    this.AddressCreateForm = this.fb.group({
      emiratesId: ["", [Validators.required]],
      deliveryAreaId: ["", [Validators.required]],
      addressLine: ["", [Validators.required]],
      addressType: ["", [Validators.required]],
      isDefault: [false],
    });

    var ok = this.handleRestaurantRegistration();
    if (!ok) {
      return;
    }
    this.pageId = this.route.snapshot.paramMap.get("id");
    this.handleCustomerDetails();
  }

  onDeleteAddress(addressId) {
    Swal.fire({
      title: "Delete",
      text: "Are you sure you want to delete",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#374abe",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.ApiService.deleteAddressApi(this.pageId, addressId).subscribe(
          (res: any) => {
            if (res.success == 1) {
              this.handleCustomerDetails();
              this.addressArray = [];
              this.toastr.success(res.message);
            } else {
              this.toastr.warning(res.message);
            }
          },
          (error) => {
            this.toastr.error(error.error.message);
          }
        );
      }
    });
  }

  handleCustomerDetails() {
    this.ApiService.getCustomerDetails(this.pageId).subscribe((res: any) => {
      console.log(res);
      this.userDetails = res["items"];
      this.addressList = this.userDetails.addresses;
      this.addressArray = [];
      for (let item of this.addressList) {
        if (item.status != 0) {
          this.addressArray.push(item);
        }
      }
      console.log("Address array is ");
      console.log(this.addressArray);
      if (res.success == 1) {
        this.updateCustomerForm.patchValue({ name: this.userDetails.name });
        this.updateCustomerForm.patchValue({ phone: this.userDetails.phone });
        this.updateCustomerForm.patchValue({ email: this.userDetails.email });
      } else {
        this.toastr.warning(res.message);
      }
    });
  }

  onSubmitUpdateCustomer() {
    this.submitted = true;
    this.clicked = true;
    if (this.updateCustomerForm.invalid) {
      this.clicked = false;
      return;
    }
    this.ngLoader = true;
    this.ApiService.updateCustomerDetails(
      this.pageId,
      this.updateCustomerForm.value
    ).subscribe(
      (res: any) => {
        if (res.success == 1) {
          this.toastr.success(res.message);
        } else {
          this.toastr.warning(res.message);
        }
        this.ngLoader = false;
      },
      (error) => {
        this.ngLoader = false;
        this.toastr.error(error.error.message);
      }
    );
  }

  onSubmitAddressCreate(addressId) {
    if (this.isModalTypeEdit == "update") {
      this.updateAddress();
    } else {
      this.createAddress();
    }
  }
  // createAddress(){
  //   this.submitted = true;
  //   this.clicked = true;
  //   if (this.AddressCreateForm.invalid) {
  //     this.clicked = false;
  //     return;
  //   }
  //   this.ApiService.createAddressApi(this.pageId,this.AddressCreateForm.value).subscribe((res: any) => {
  //     if (res.success == 1) {
  //       this.toastr.success(res.message);
  //       this.handleCustomerDetails();
  //       this.modalReference.close();
  //       this.AddressCreateForm.reset();
  //       this.submitted = false;
  //       this.clicked = false;
  //       this.addressArray =[]
  //     } else {
  //       this.toastr.warning(res.message);
  //     }
  //   }, error => {
  //     this.toastr.error(error.error.message);
  //   })
  // }

  getEmiratesList(cb) {
    this.ngLoader = true;

    this.emiratesNameIdMap = {};
    this.ApiService.getEmiratesListPage(1, 10000).subscribe(
      (result) => {
        console.log("--------------- Emirates list fetched");
        this.ngLoader = false;
        this.emirates = result["items"];
        var emirates = this.emirates;

        console.log("Em list is ");
        console.log(JSON.stringify(emirates));
        for (var emirate of emirates) {
          var id = emirate.id;
          var name = emirate.name;
          this.emiratesNameIdMap[id] = name;
        }
        cb();
      },
      (error) => {
        this.ngLoader = false;
        this.toastr.error(error.error.message);
      }
    );
  }

  getAreasList(emirateId, cb) {
    this.areaNameIdMap = {};
    this.ngLoader = true;
    console.log("Emirate id is " + emirateId);
    this.ApiService.getAreaListPage(emirateId, 1, 10000).subscribe(
      (result) => {
        this.areas = result["items"];
        this.ngLoader = false;
        var areas = this.areas;
        for (var area of areas) {
          var id = area.id;
          var name = area.name;
          this.areaNameIdMap[id] = name;
        }
        if (cb) {
          cb();
        }
      },
      (error) => {
        this.ngLoader = false;
        this.toastr.error(error.error.message);
      }
    );
  }
  createAddress() {
    this.addressFormSubmitted = true;
    this.clicked = true;
    let formValue = this.AddressCreateForm.value;
    if (this.AddressCreateForm.invalid) {
      this.clicked = false;
      return;
    }
    this.ngLoader = true;
    var userId = this.userDetails.id;
    this.ApiService.createAddressApi(
      userId,
      this.AddressCreateForm.value
    ).subscribe(
      (res: any) => {
        this.ngLoader = false;
        if (res.success == 1) {
          this.toastr.success(res.message);

          this.modalReference.close();
          this.AddressCreateForm.reset();
          this.addressFormSubmitted = false;
          this.clicked = false;
          this.handleCustomerDetails();
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.ngLoader = false;
        this.toastr.error(error.error.message);
      }
    );
  }

  updateAddress() {
    this.addressFormSubmitted = true;
    this.clicked = true;
    if (this.AddressCreateForm.invalid) {
      this.clicked = false;
      return;
    }
    console.log("-------------------- Update addresss function called")
    console.log("----------------- Address form values are ")
    console.log(this.AddressCreateForm.value)
    this.ngLoader = true;
    var userId = this.userDetails.id;
    this.ApiService.updateAddressApi(
      userId,
      this.addressIdToUpdate,
      this.AddressCreateForm.value
    ).subscribe(
      (res: any) => {
        if (res.success == 1) {
          this.handleCustomerDetails();
          this.modalReference.close();
          this.AddressCreateForm.reset();
          this.submitted = false;
          this.clicked = false;
          this.addressArray = [];
          this.toastr.success(res.message);
        } else {
          this.toastr.warning(res.message);
        }
        this.ngLoader = false;
      },
      (error) => {
        this.ngLoader = false;
        this.toastr.error(error.error.message);
      }
    );
  }
}
