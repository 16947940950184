import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-restaurant-list',
  templateUrl: './restaurant-list.component.html',
  styleUrls: ['./restaurant-list.component.scss']
})
export class RestaurantListComponent implements OnInit {
  restaurantList: any;
  page = 1;
  perPage = 100;
  pagination: any;
  ngLoader: boolean = false;
  restaurantToken: any;

  constructor(
    private ApiService: ApiService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router,) { }

    viewPage(id){
      let body ={restaurantId:id}
      this.ApiService.getRestaurantToken(body).subscribe((res:any)=>{
        if (res.success == 1) {
          this.restaurantToken = res['token']
          let userRoles = ["admin"]
          
          localStorage.setItem("UserToken", this.restaurantToken);
          localStorage.setItem("restaurantId", JSON.stringify(id));
          localStorage.setItem("userRoles", JSON.stringify(userRoles));
          localStorage.setItem("userType", "superadmin");
          this.router.navigate(['home'])
          
        } else {
          this.toastr.warning(res.message);
        }
      }, error => {
        console.log(error.error.errors);
       })


    }

    ngOnInit() {
      this.handleRestaurantList()
    }
  
    handleRestaurantList(){
    this.ngLoader = true
     this.ApiService.getRestaurantList(this.page, this.perPage).subscribe((res:any)=>{
      console.log(res);
      if (res.success == 1) {
        this.restaurantList = res['items']
        this.pagination = res['pagination']
        this.ngLoader = false
      } else {
        this.toastr.warning(res.message);
      }
    }, error => {
      this.toastr.error(error.error.message);
      console.log(error.error.errors);
     })
    }

}
