import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { customRegexValidator } from "../../helper/validator";
import { passValidator } from "../../helper/validator";
import { regEx } from "../../helper/constants";
@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
})
export class RegistrationComponent implements OnInit {
  userRegisterationFrom: FormGroup;
  submitted: boolean = false;
  clicked: boolean = false;
  ngLoader: boolean = false;
  nameErr: String = "";
  emailExistsErr: String = "";
  nameRegEx = /^[a-z ,.'-]+$/i;
  noSpaceAtStartRegEx = regEx.noSpaceAtStartRegEx;
  emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  constructor(
    private ApiService: ApiService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    this.userRegisterationFrom = this.fb.group({
      name: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(40),
          customRegexValidator({
            pattern: this.nameRegEx,
            msg: "Invalid Name",
          }),
          customRegexValidator({
            pattern: this.noSpaceAtStartRegEx,
            msg: "Name cannot start or end with a blank space",
          }),
        ],
      ],
      email: [
        "",
        [
          Validators.required,
          customRegexValidator({
            pattern: this.emailRegEx,
            msg: "Invalid Email Format",
          }),
        ],
      ],
      phone: ["", [Validators.required, Validators.minLength(9)]],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(6), 
          customRegexValidator({
            pattern: this.noSpaceAtStartRegEx,
            msg: "Password Cannot start with blank space",
          }),
        ],
      ],
      retype_password: ["", [Validators.required, passValidator]],
    }); 
  }

  emailExistsValidator(): ValidatorFn {
    return (control: FormControl) => {
      return new Promise((resolve, reject) => {
        // also add here is premium logic
        let email = control.value;
        if (email) {
          var formData = { email: email };
          this.ApiService.checkUserExists(formData).subscribe(
            (res: any) => {
              if (res.success == 1) {
                console.log("Validator called email" + JSON.stringify(res));
                if (res.exists) {
                  console.log("res.exists is true. Returning message");
                  resolve(null)
                } else resolve(null);
              } else {
                if (res.message) this.toastr.warning(res.message);
              }
            },
            (error) => {
              if (error.error.message) this.toastr.error(error.error.message);
              this.ngLoader = false;
            }
          );
        } else {
          resolve(null);
        }

        // and here call your server and call
        //   resolve(null) in case it's fine
        //   resolve({ emailExists: true }) in case it's existing
      });
    };
  }
  checkEmailExists(ev) {
    this.emailExistsErr = "";
    var group = this.userRegisterationFrom;
    var vals = this.userRegisterationFrom.value;
    console.log(vals);
    let email = vals.email;
    if (email) {
      var formData = { email: email };
      this.ApiService.checkUserExists(formData).subscribe(
        (res: any) => {
          if (res.success == 1) {
            if (res.exists) {
              this.emailExistsErr = "Email id already existing";
              this.userRegisterationFrom.controls['email'].setErrors({'incorrect': true});
            }
          } else {
            if (res.message) this.toastr.warning(res.message);
          }
        },
        (error) => {
          if (error.error.message) this.toastr.error(error.error.message);
          this.ngLoader = false;
        }
      );
    }
  }
  get f() {
    return this.userRegisterationFrom.controls;
  }

  onSubmit() {
    console.log(this.userRegisterationFrom.value)
    this.submitted = true;
    this.clicked = true;
    if (this.userRegisterationFrom.invalid) {
      this.clicked = false;
      return;
    }
    //console.log(this.userRegisterationFrom.value)
    let formData = this.userRegisterationFrom.value;
    delete formData.retype_password;
    this.ngLoader = true;
    this.ApiService.registerUserApi(formData).subscribe(
      (res: any) => {
        console.log("response executed");
        
        this.ngLoader = false;
        if (res.success == 1) {
          this.toastr.success(res.message);
          this.userRegisterationFrom.reset();
          this.submitted = false;
          this.clicked = false;

          localStorage.setItem(
            "userRoles",
            JSON.stringify(res.userDetails.roles)
          );
          localStorage.setItem("UserDetails", JSON.stringify(res.userDetails));
          localStorage.setItem("UserToken", res.token);
          console.log("Navigating....");
          this.router.navigate(["/get-start"]);
        } else {
          if (res.message) this.toastr.warning(res.message);
        }
      },
      (error) => {
        if (error.error.message) this.toastr.error(error.error.message);
        this.ngLoader = false;
      }
    );
  }
}
