import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
  
  customersList = [];
  page = 1;
  perPage = 30;
  pagination: any;
  ngLoader: boolean = false;

  constructor(
    private ApiService: ApiService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router,) { }
    id;
    pageChange(page) {
      this.page = page;
      this.handleCustomersList()
    }
    pageItemCount(event){
      console.log(event.target.value)
      this.perPage = event.target.value
      this. handleCustomersList()
    }
    toggleBlocking(customerId, isBlocked) {  
      let title = !isBlocked? "Block Customer ?": "Unblock Customer ?";
      Swal.fire({
        title: title,
        text: "Are you sure",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#374abe',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          this.ApiService.toggleBlocking(customerId).subscribe((res:any)=>{
            let title = "Unfortunately the action is failed.";
            if(res.success) {
              let isBlocked = res.isBlocked;
              title = isBlocked? 'Successfully blocked the customer': 'Successfully unblocked the customer';
            
            }
            this.toastr.success(title);
            this.handleCustomersList()
          })
        }
      })
      
    }
    deleteCustomer(customerId) {     
      Swal.fire({
        title: 'Delete',
        text: "Are you sure you want to delete",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#374abe',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          this.ApiService.deleteCustomerItem(customerId).subscribe((res:any)=>{
            this.toastr.success(res.message);
            this.handleCustomersList()
          })
        }
      })
    } 
    customerEdit(customerId){
      this.router.navigate(['/customers-edit',customerId]);
    }
    customerDetails(customerId){
      this.router.navigate(['/customers-details',customerId]);
    }
    enableDisable(customerId,type){ 
      let body = {}
      if(type != true){
        console.log('case 1')
         body = {
          isEnabled: true
        }
      }else{
        console.log('case 2')

         body = {
          isEnabled: false
        }
      }

      this.ApiService.updateCustomerDetails(customerId,body).subscribe((res: any) => {
        console.log(res);
        if (res.success == 1) {
          this.toastr.success(res.message);
          this.handleCustomersList()
        } else {
          this.toastr.warning(res.message);
        }
      }, error => {
        this.toastr.error(error.error.message);
      })
    }
    handleRestaurantRegistration() {
      var token =  localStorage.getItem('UserToken');
      var isLoggedIn = false;
      if(token) {
        isLoggedIn = true;
      }
      if(!isLoggedIn) { 
        this.router.navigate(['login']);
        return;
      }
      var id = localStorage.getItem('restaurantId');
      if(id) {
        id = JSON.parse(id);
      }
      if(id) {
        this.id = id;
      } else {
        console.log("No id found");
        this.router.navigate(['get-started']);
        return;
      }
  }
  ngOnInit() { 
    this.handleRestaurantRegistration();
    this.handleCustomersList()
  }
  viewCustomer(customerId) {
    this.router.navigate(["/customers-details", customerId]);
  }
  handleCustomersList(){
    this.ngLoader = true
   this.ApiService.getCustomersList(this.page, this.perPage).subscribe((res:any)=>{
    this.ngLoader = false
    if (res.success == 1) {
      this.customersList = res['items']
      this.pagination = res['pagination']
     
    } else {
      this.toastr.warning(res.message);
    }
  }, error => {
    this.toastr.error(error.error.message);
    this.ngLoader = false
   })
  }

}
