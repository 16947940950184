import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from '@angular/router';
import { passValidator } from '../../helper/validator';
import { customRegexValidator } from "../../helper/validator";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordFrom: FormGroup;
  submitted:boolean = false;
  clicked:boolean = false;
  pageId: any;

  noSpaceAtStartRegEx = /^[^\s]+(\s+[^\s]+)*$/;
  constructor(
    private ApiService:ApiService,
    private toastr: ToastrService, 
    private fb: FormBuilder, 
    private router: Router,
    private route: ActivatedRoute,) {
      console.log(`Validator is`);
      this.resetPasswordFrom = this.fb.group({
        password: [
          "",
          [
            Validators.required,
            Validators.minLength(6),
            customRegexValidator({
              pattern: this.noSpaceAtStartRegEx,
              msg: "Password Cannot start or end with blank space",
            }),
          ],
        ],
        confirmPassword: ['', [Validators.required, passValidator]],
      });
      this.resetPasswordFrom.controls.password.valueChanges
      .subscribe(
        x => this.resetPasswordFrom.controls.confirmPassword.updateValueAndValidity()
      )
     }

    ngOnInit() {
      this.pageId = this.route.snapshot.paramMap.get('id')  
      console.log(this.pageId)  

    }
      get f() {
        return this.resetPasswordFrom.controls;
      }

      onSubmit() {
        this.submitted = true;
        this.clicked = true;
        if (this.resetPasswordFrom.invalid) {
            this.clicked = false;
            return;
        }
        let body ={
          password: this.resetPasswordFrom.value.password,
          token: this.pageId
        }
        console.log(`Body is`, body);
        console.log(body)
        this.ApiService.resetPasswordApi(body).subscribe((res:any)=>{    
          console.log(res) 	        
          if (res.success == 1) {
            this.toastr.success(res.message);
            this.router.navigate(['login']);
           } else {
            this.toastr.warning(res.message);
          }
        }, error => {
           
        })
      }

}

