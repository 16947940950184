import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor( private router: Router, private toastr: ToastrService, public ApiService:ApiService) { }

  ngOnInit() {
    localStorage.removeItem("restaurantId");
    localStorage.removeItem("restaurantName");
    localStorage.removeItem("slug");
    localStorage.removeItem("UserDetails");
    localStorage.removeItem("UserToken");
    this.router.navigate(['login'])    
   // this.toastr.success('Sign out Successfully'); 
    localStorage.removeItem("userRoles");   
    localStorage.removeItem("userType");   
  }

}
