import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
@Component({
  selector: "app-catalogue",
  templateUrl: "./catalogue.component.html",
  styleUrls: ["./catalogue.component.scss"],
})
export class CatalogueComponent implements OnInit {
  vegOnlyFilter: boolean = false;
  viewType: boolean = false;
  listView: boolean = false;
  gridView: boolean = false;
  isSimpleLayoutEnabled: boolean = false;
  gridWithBgView: boolean = false;
  outOfStockEnabled: boolean = false;
  restaurantData;
  updateDataObj;
  skuOrder = null;
  ngLoader;
  id = JSON.parse(localStorage.getItem("restaurantId"));
  constructor(
    private ApiService: ApiService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    var ok = this.handleRestaurantRegistration();
    if (!ok) {
      return;
    }
    this.getRestaurantData();
  }

  handleRestaurantRegistration() {
    var token = localStorage.getItem("UserToken");
    var isLoggedIn = false;
    if (token) {
      isLoggedIn = true;
    }
    if (!isLoggedIn) {
      this.router.navigate(["login"]);
      return false;
    }
    var id = localStorage.getItem("restaurantId");
    if (id) {
      id = JSON.parse(id);
    }
    if (id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(["get-started"]);
      return false;
    }
    return true;
  }
  getRestaurantData() {
    this.ngLoader = true;
    this.ApiService.getRestaurantsSetup(this.id).subscribe((data) => {
      this.restaurantData = data["item"];
      this.isSimpleLayoutEnabled =
        this.restaurantData.isSimpleLayoutEnabled || false;
      if (this.restaurantData.isVegOnlyFilterEnabled) {
        this.vegOnlyFilter = true;
      }
      if (this.restaurantData.isOutOfStockProductsEnabled) {
        this.outOfStockEnabled = true;
      }
      this.viewType = this.restaurantData.viewType;
      if (this.restaurantData.viewType == "list") {
        this.listView = true;
      } else if (this.restaurantData.viewType == "grid") {
        this.gridView = true;
      } else {
        this.gridWithBgView = true;
      }
      if (this.restaurantData.skuOrder) {
        this.skuOrder = this.restaurantData.skuOrder;
      }
      this.ngLoader = false;
    });
  }

  toggleVegFilter(event) {
    this.vegOnlyFilter = event.target.checked;
  }

  toggleListView(event) {
    this.viewType = event.target.value;
  }

  toggleGridView(event) {
    this.viewType = event.target.value;
  }

  toggleGridListView(event) {
    this.viewType = event.target.value;
  }

  toggleOutOfStock(event) {
    this.outOfStockEnabled = event.target.checked;
  }

  updateData(value) {
    this.updateDataObj = {
      isVegOnlyFilterEnabled: this.vegOnlyFilter,
      viewType: this.viewType,
      skuOrder: value,
      isOutOfStockProductsEnabled: this.outOfStockEnabled,
    };
    this.ngLoader = true;
    console.log("Update values are");
    console.log(this.updateDataObj);
    this.ApiService.setupRestaurantsUpdate(
      this.id,
      this.updateDataObj
    ).subscribe((data) => {
      this.ngLoader = false;
      this.toastr.success("Successfully Updated");
    });
  }

  clearTestingCatalogue() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, clear it!",
    }).then((result) => {
      if (result.value) {
        this.confirmClearTestingCatalogue();
      }
    });
  }

  confirmClearTestingCatalogue() {
    this.ngLoader = true;
    this.ApiService.clearTestingCatalogue().subscribe((result) => {
      this.ngLoader = false;
      this.toastr.success("Successfully Updated");
    });
  }

  clearCatalogue() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, clear it!",
    }).then((result) => {
      if (result.value) {
        this.confirmClearCatalogue();
      }
    });
  }

  confirmClearCatalogue() {
    this.ngLoader = true;
    this.ApiService.clearCatalogue().subscribe((result) => {
      this.ngLoader = false;
      this.toastr.success("Successfully Updated");
    });
  }
}
