import { Component, OnInit, ViewChild } from "@angular/core";
import {
  NgbModal,
  NgbModalRef,
  NgbModalConfig,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services/api.service";
import { ConfirmationDialogService } from "../../confirmation-dialog/confirmation-dialog.service";
import { ToastrService } from "ngx-toastr";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import {
  customRegexValidator,
  requiredFileType,
  numberRangeValidator,
  numberValidator,
} from "../../helper/validator";
import { regEx } from "../../helper/constants";
import { ThisReceiver } from "@angular/compiler";
import { ExportService } from "src/app/services/export.service";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-products-list",
  templateUrl: "./products-list.component.html",
  styleUrls: ["./products-list.component.scss"],
})
export class ProductsListComponent implements OnInit {
  @ViewChild("attachments", { static: false }) attachment: any;
  addCategoryForm: FormGroup;
  addSubCategoryForm: FormGroup;
  createProductForm: FormGroup;
  addAddonsForm: FormGroup;
  submitted: boolean = false;
  clicked: boolean = false;
  isSimpleLayoutEnabled: boolean = false;
  modalReference: NgbModalRef;
  categoryList: any;
  editCategoryItem: any;
  subCategoryList: any;
  isSubCategoryList: boolean = false;
  subCategoryId: any;
  selectedCategory: any;
  globalCategoryIdProduct = "";
  productList: any;
  productsDetails: any;
  products: any;
  filterProducts: any;
  imageBase: any;
  productImages: any = [];
  imagesToDisplay: any = [];
  fileData;
  thumbFile = [];
  deletedImages: any = [];
  selectedFile: File;
  fileList: File[] = [];
  selected: any;
  selectclickedCategory: any;
  selectedclickedProductItem: any;
  selectedsubCategoryItem: any;
  selectedCategoryName: any;
  editSubCategoryItem: any;
  checkInventoryEnabled: boolean;
  saveAndContinue: boolean = false;
  productItems: any;
  isModalTypeEdit: any;
  checkMultiSelectEnabled: boolean = false;
  addonsList: any;
  categoryFirstChildId: void;
  productFirstChildId: any;
  categorythumbImage: string | ArrayBuffer;
  SubCategorythumbImage: string | ArrayBuffer;
  categoryDetails: any;
  subCategoryDetails: any;
  ngLoader: boolean = false;
  subCategoryFirstChildId: any;
  addonsDetails: any;
  enableAddons: any;
  isChecked: boolean = false;
  isCheckedQuantity: boolean = false;
  enableQuantity: any;
  tag: string = "nonVeg";
  prodctDetailsView: boolean;
  updateImage: any;
  enableQuantityInput: any;
  addonErrors: any;
  showAddonOptionsEmptyError: any;
  id;
  addOnSubmitButtonLabel = "Add";
  selectedImage;
  productFormValidators: any;
  multiSelectAlert: any = false;
  searchProduct: any;
  showInputBox = false;
  constructor(
    public ApiService: ApiService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private router: Router,
    private exportService: ExportService
  ) {
    config.backdrop = "static";
    config.keyboard = false;

    this.productFormValidators = {
      categoryId: [""],
      productName: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(40),
          customRegexValidator({
            pattern: regEx.productNameRegEx,
            msg: "Invalid Product Name",
          }),
          customRegexValidator({
            pattern: regEx.noSpaceAtStartRegEx,
            msg: "Product Name cannot start or end with a space",
          }),
        ],
      ],
      slug: [
        "",
        [
          Validators.required,
          customRegexValidator({
            pattern: regEx.slugRegEx,
            msg: "Invalid Slug Format",
          }),
        ],
      ],
      skuNumber: [
        "",
        [
          Validators.required,
          customRegexValidator({
            pattern: regEx.skuNumberRegEx,
            msg: "Invalid SKU Format. Only Numbers allowed",
          }),
        ],
      ],
      costPrice: [
        "",
        [
          Validators.required,
          Validators.min(0.1),
          customRegexValidator({
            pattern: regEx.priceRegEx,
            msg: "Invalid Price",
          }),
          customRegexValidator({
            pattern: regEx.numbersWithDecimalsRegEx,
            msg: "Invalid Price",
          }),
        ],
      ],
      sellingPrice: [
        "",
        [
          customRegexValidator({
            pattern: regEx.priceRegEx,
            msg: "Invalid Price",
          }),
          customRegexValidator({
            pattern: regEx.numbersWithDecimalsRegEx,
            msg: "Invalid Price",
          }),
        ],
      ],
      minQuantityPerOrder: [
        1,
        [
          Validators.required,
          numberRangeValidator({
            min: 1,
          }),
        ],
      ],
      maxQuantityPerOrder: [
        "",
        [
          Validators.required,
          numberRangeValidator({
            min: 1,
          }),
        ],
      ],
      preparationTimeMinutes: ["", [Validators.required]],
      servesFor: ["", [Validators.required]],
      description: [
        "",
        [
          Validators.required,
          customRegexValidator({
            // pattern: regEx.descriptionTextRegEx,
            msg: "Invalid Text",
          }),
        ],
      ],
      ingredients: [
        "",
        [
          customRegexValidator({
            pattern: regEx.ingredientsTextRegEx,
            msg: "Invalid Text",
          }),
        ],
      ],
      quantityAvailable: [""],
      isInventoryEnabled: [false],
      tag: [false],
      subImages: ["", [requiredFileType(["png", "jpeg", "jpg"])]],
      mainImage: ["", [requiredFileType(["png", "jpeg", "jpg"])]],
    };
  }
  numberOnly(evt): boolean {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  pureDigitsOnly(evt): boolean {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;

    return true;
  }
  formatPrice(value) {
    return Number(value).toFixed(2);
  }
  integerOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  closeSearchbar() {
    this.searchProduct = false;
  }
  setDefaultMainImageIfNeeded() {
    var images = this.imagesToDisplay;
    if (images.length == 1) {
      var imageObj = images[0];
      this.setMainImage(imageObj.id);
    }
  }
  clearDisplayImages() {
    console.log("Clearing product images..");
    this.deletedImages = [];
    this.imagesToDisplay = [];
  }
  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  addDisplayImage(url, isExisting, isMainImage, file) {
    var uniqueId = Date.now() + "" + this.getRandomInt(1, 1000000);
    console.log("Unique id is " + uniqueId);

    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        this.fileData = event.target;
        var imageData = this.fileData.result;

        this.imagesToDisplay.push({
          id: uniqueId,
          url: null,
          data: imageData,
          isExisting: isExisting,
          isMain: isMainImage,
          file: file,
        });
        this.setDefaultMainImageIfNeeded();
      };
    } else {
      this.imagesToDisplay.push({
        id: uniqueId,
        url: url,
        data: null,
        isExisting: isExisting,
        isMain: isMainImage,
      });
      this.setDefaultMainImageIfNeeded();
    }
    console.log(JSON.stringify(this.imagesToDisplay));
  }
  open(content, modalCategoryId, modalItemName, modalSubCategoryId, modalType) {
    // console.log('modal CategoryId : ' + modalCategoryId)
    // console.log('modal SubCategoryId : ' + modalSubCategoryId)
    // console.log('modalItem Name : ' + modalItemName)
    // console.log('modalItem type : ' + modalType)
    console.log(this.f4.maxAllowedOptionsCount.errors);
    this.multiSelect(false);
    // /\/\/\/\/\/\/\ FOR BLOCKING Customer can select
    this.editCategoryItem = modalCategoryId;
    this.editSubCategoryItem = modalSubCategoryId;
    this.isModalTypeEdit = modalType;
    console.log("Modal type is " + modalType);
    if (modalType == "addonModal") {
      this.addOnSubmitButtonLabel = "Add";
      this.clearFormArray(this.addonsFrom);
      if (this.addonsFrom.length < 1) {
        this.addAddons(false);
      }
      var addonValuesCount = this.addonsFrom.getRawValue().length;
      var i = 0;
      this.addonErrors = [];
      while (i < addonValuesCount) {
        this.addonErrors.push({
          name: "This field is required",
          price: "This field is required",
        });
        i++;
      }
    }
    if (modalType == "editAddons") {
      this.addOnSubmitButtonLabel = "Update";
      this.ngLoader = true;
      this.ApiService.getAddonsDetails(
        modalCategoryId,
        modalSubCategoryId
      ).subscribe((res: any) => {
        this.addonsDetails = res["addOnDetails"];
        this.addonsFrom.clear();
        this.addonErrors = [];
        this.addonsDetails["options"].forEach((element) => {
          var pushEmptyErrors = true;
          this.addAddons(pushEmptyErrors);
        });
        console.log("------------------ Options values are currently");
        console.log(this.addonsDetails.options);
        this.addAddonsForm.patchValue({
          name: this.addonsDetails.name,
          maxAllowedOptionsCount: this.addonsDetails.maxAllowedOptionsCount,
          options: this.addonsDetails.options,
        });
        if (this.addonsDetails.isMultiSelectEnabled === false) {
          this.addAddonsForm.get("isMultiSelectEnabled").setValue("false");
        } else if (this.addonsDetails.isMultiSelectEnabled === true) {
          this.checkMultiSelectEnabled = true;
          this.addAddonsForm.get("isMultiSelectEnabled").setValue("true");
        }
        this.ngLoader = false;
      });
    }
    if (modalType == "create_product") {
      this.reInitProductForm();
    }
    if (modalType == "category_edit") {
      this.ApiService.getCategoryDetails(modalCategoryId).subscribe((res) => {
        this.imageBase = res["imageBase"];
        this.categoryDetails = res["category"];
        this.addCategoryForm.patchValue({ name: this.categoryDetails.name });
        this.addCategoryForm.patchValue({
          iconImage: this.categoryDetails.iconImage,
        });
        this.categorythumbImage = this.categoryDetails.iconImage
          ? this.imageBase + this.categoryDetails.iconImage
          : "";
      });
    }
    if (modalType == "subCategory_edit") {
      this.ApiService.getSubCategoryDetails(
        modalCategoryId,
        modalSubCategoryId
      ).subscribe((res) => {
        this.imageBase = res["imageBase"];
        this.subCategoryDetails = res["subCategory"];
        this.addSubCategoryForm.patchValue({
          name: this.subCategoryDetails.name,
        });
        this.addSubCategoryForm.patchValue({
          iconImage: this.subCategoryDetails.iconImage,
        });
        this.SubCategorythumbImage =
          this.imageBase + this.subCategoryDetails.iconImage;
      });
    }
    if (modalType === "edit_product" || modalType === "duplicate_product") {
      this.clearDisplayImages();
      this.productImages = [];
      this.ApiService.getProductdetails(modalSubCategoryId).subscribe(
        (res: any) => {
          let hasImages = true;
          if (res.success == 1) {
            this.imageBase = res.imageBase;
            this.productItems = res["item"];
            var images = [];
            var mainImage = this.productItems.mainImage;
            var subImages = this.productItems.subImages;
            if (mainImage) {
              this.addDisplayImage(mainImage, true, true, null);
            }
            hasImages = mainImage || subImages.length > 0;
            if (hasImages) {
              this.removeRequiredValidatorForImage();
            }
            for (var image of subImages) {
              this.addDisplayImage(image, true, false, null);
            }
            console.log("--------------------- Checkpoint 1.5");

            this.createProductForm.patchValue({
              categoryId: modalCategoryId,
              productName: this.productItems.name,
              slug: this.productItems.slug,
              skuNumber: this.productItems.skuNumber,
              servesFor: this.productItems.servesFor,
              costPrice: this.productItems.costPrice,
              sellingPrice: this.productItems.sellingPrice,
              minQuantityPerOrder: this.productItems.minQuantityPerOrder,
              maxQuantityPerOrder: this.productItems.maxQuantityPerOrder,
              preparationTimeMinutes: this.productItems.preparationTimeMinutes,
              description: this.productItems.description,
              ingredients: this.productItems.ingredients,
              quantityAvailable: this.productItems.quantityAvailable,
              isInventoryEnabled: this.productItems.isInventoryEnabled,
              //subImages: this.productItems.productImages || [],
              //mainImage: this.productItems.mainImage || "",
            });
            console.log("--------------------- Checkpoint 2");
            console.log("--------------------- Checkpoint 2.05");
            if (this.productItems.quantityAvailable == null) {
              this.createProductForm.patchValue({ quantityAvailable: 0 });
            }
            console.log("--------------------- Checkpoint 2.1");
            this.checkInventoryEnabled = false;
            if (this.productItems.isInventoryEnabled) {
              this.checkInventoryEnabled = true;
            }
            console.log("--------------------- Checkpoint 2.2");
            this.updateImage = this.productItems.subImages;
            console.log("--------------------- Checkpoint 3");
            if (this.productItems.tag == "veg") {
              console.log("--------------- tag is veg");
              this.createProductForm.patchValue({ tag: true });
            } else {
              console.log(" --------------- tag is not veg'");
              this.createProductForm.patchValue({ tag: false });
            }
            //this.thumbFile.push(this.imageBase +  this.productItems.mainImage)
            // console.log(  this.imageBase +  this.productItems.mainImage)
          }
        }
      );
    }
    this.modalReference = this.modalService.open(content, {
      size: "",
      centered: true,
      windowClass: "modal-custom",
    });
    this.modalReference.result.then(
      (result) => {
        // this.confirmationDialogService.confirm('Confirm', 'Delete').then((confirmed) => {
        //   })
        // .catch(() => {
        //    // console.log('User dismissed the dialog')
        //   });
        // console.log('delete photo')
      },
      (reason) => {
        this.submitted = false;
        this.clicked = false;
        this.modalReference.close();
        this.addAddonsForm.reset();
        this.addCategoryForm.reset();
        this.addSubCategoryForm.reset();
        this.createProductForm.reset();
        this.categorythumbImage = "";
        this.SubCategorythumbImage = "";
        this.thumbFile = [];
        this.deletedImages = [];
        this.tag = "nonVeg";
      }
    );
  }
  reInitProductForm() {
    this.clearDisplayImages();
    this.createProductForm.reset();
    this.createProductForm.patchValue({ minQuantityPerOrder: 1 });
    this.createProductForm.patchValue({ isInventoryEnabled: false });
    this.createProductForm.patchValue({ quantityAvailable: 0 });
    let autoSkuNumber = Math.floor(100000 + Math.random() * 900000);
    this.createProductForm.patchValue({ skuNumber: autoSkuNumber });
    this.submitted = false;
    this.clicked = false;
    this.thumbFile = [];
    this.updateImage = [];
    this.tag = "nonVeg";
    this.checkInventoryEnabled = false;
  }

  handleRestaurantRegistration() {
    var token = localStorage.getItem("UserToken");
    var isLoggedIn = false;
    if (token) {
      isLoggedIn = true;
    }
    if (!isLoggedIn) {
      this.router.navigate(["login"]);
      return;
    }
    var id = localStorage.getItem("restaurantId");
    if (id) {
      id = JSON.parse(id);
    }
    if (id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(["get-started"]);
      return;
    }
  }
  /*validateMinMaxQuantity(): ValidationErrors | null {
 
    const minQty = this.createProductForm.get("minQuantityPerOrder").value;
    const maxQty = this.createProductForm.get("maxQuantityPerOrder").value;
 
 
    if (minQty > maxQty) { return { 'comparisonError': true } }
 
    return null
 
  } */
  ngOnInit() {
    this.products = "";
    this.filterProducts = "";
    this.addonErrors = [];
    this.showAddonOptionsEmptyError = false;
    this.handleRestaurantRegistration();
    this.hangleCategoryList();
    this.getProductListItem();
    this.addCategoryForm = this.fb.group({
      name: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(40),
          customRegexValidator({
            pattern: regEx.categoryNameRegEx,
            msg: "Invalid Category Name",
          }),
          customRegexValidator({
            pattern: regEx.noSpaceAtStartRegEx,
            msg: "Category Name cannot start or end with a space",
          }),
        ],
      ],
      iconImage: [
        "",
        [Validators.required, requiredFileType(["png", "jpeg", "jpg"])],
      ],
      isEnabled: [Boolean],
    });
    this.addSubCategoryForm = this.fb.group({
      name: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(40),
          customRegexValidator({
            pattern: regEx.categoryNameRegEx,
            msg: "Invalid Sub Category Name",
          }),
          customRegexValidator({
            pattern: regEx.noSpaceAtStartRegEx,
            msg: "Sub Category Name cannot start or end with a space",
          }),
        ],
      ],
      iconImage: [
        "",
        [Validators.required, requiredFileType(["png", "jpeg", "jpg"])],
      ],
    });

    this.createProductForm = this.fb.group(this.productFormValidators);
    this.addAddonsForm = this.fb.group({
      name: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          customRegexValidator({
            pattern: regEx.addonNameRegEx,
            msg: "Invalid Addon Name",
          }),
          customRegexValidator({
            pattern: regEx.noSpaceAtStartRegEx,
            msg: "Cannot start or end with Space",
          }),
        ],
      ],
      isMultiSelectEnabled: ["", [Validators.required]],
      // maxAllowedOptionsCount: ["", [Validators.required,Validators.min(1)]],
      maxAllowedOptionsCount: [""],

      options: this.fb.array([], Validators.required),
    });
  }

  /* ngAfterViewInit() {
    setTimeout(() => {
      //this.clickedProductItem(this.productFirstChildId, 0);
    }, 700);
    setTimeout(() => {
      this.ngLoader = false;
    }, 1000);

    // setTimeout(() => {
    //   this.clickedSubCategory(this.globalCategoryIdProduct, 0)
    // }, 1000);
  } */

  onMaxAllowedChange() {
    if (
      this.submitted &&
      this.addAddonsForm.value.maxAllowedOptionsCount <= 0
    ) {
      this.multiSelectAlert = true;
    } else {
      this.multiSelectAlert = false;
    }
    console.log(this.multiSelectAlert);
  }
  multiSelect(val) {
    console.log("val is " + val);
    this.checkMultiSelectEnabled = val;
    if (this.checkMultiSelectEnabled) {
      console.log("Multi select is now enabled...");
    } else {
      console.log("Multi select is now not enabled...");
    }
  }
  checkEnableAddons(event) {
    this.enableAddons = event;
  }
  checkQuantity(event, itemId) {
    this.enableQuantity = event;
    let formData = new FormData();
    if (event == "true") {
      formData.append("isInventoryEnabled", "true");
    } else if (event == "false") {
      formData.append("isInventoryEnabled", "false");
    }

    this.ApiService.updateProductListItem(itemId, formData).subscribe(
      (res: any) => {
        if (res.success == 1) {
          this.toastr.success("Inventory Updated successfully");
          this.clickedProductItem(itemId, null);
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.toastr.error(error.error.message);
      }
    );
  }
  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  get addonsFrom() {
    return this.addAddonsForm.get("options") as FormArray;
  }
  onAddonPriceChanged(i) {
    var field = "price";
    console.log("Len " + this.addonsFrom.length);
    var form = this.addonsFrom[i];
    var val = this.addonsFrom.getRawValue()[i][field];
    val = val.trim();
    if (val) {
      val = parseFloat(val);
    }
    if (val || val == 0) {
      this.addonErrors[i][field] = "";
    } else {
      this.addonErrors[i][field] = "This field is required";
    }
  }
  onAddonTypeChanged(i) {
    var field = "name";
    var form = this.addonsFrom[i];
    var val = this.addonsFrom.getRawValue()[i][field];
    val = val.trim();
    if (val) {
      this.addonErrors[i][field] = "";
    } else {
      this.addonErrors[i][field] = "This field is required";
    }
  }
  addAddons(pushEmptyErrors) {
    pushEmptyErrors = pushEmptyErrors != undefined ? pushEmptyErrors : false;
    const options = this.fb.group({
      name: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20),
          customRegexValidator({
            pattern: regEx.productNameRegEx,
            msg: "Invalid Addon Name",
          }),
          customRegexValidator({
            pattern: regEx.noSpaceAtStartRegEx,
            msg: "Addon Name cannot start or end with a space",
          }),
        ],
      ],
      price: [
        "",
        [
          Validators.required,
          customRegexValidator({
            pattern: regEx.priceRegEx,
            msg: "Invalid Price",
          }),
          customRegexValidator({
            pattern: regEx.numbersWithDecimalsRegEx,
            msg: "Invalid Price",
          }),
        ],
      ],
    });
    this.addonsFrom.push(options);

    var error = !pushEmptyErrors ? "This field is required" : "";
    this.addonErrors.push({ name: error, price: error });
    this.showAddonOptionsEmptyError = false;
  }

  deleteAddonsRow(i) {
    this.addonsFrom.removeAt(i);
    this.addonErrors.splice(i, 1);

    var addonValuesCount = this.addonsFrom.getRawValue().length;
    this.showAddonOptionsEmptyError = false;
    if (!addonValuesCount) {
      this.showAddonOptionsEmptyError = true;
    }
  }

  get f() {
    return this.addCategoryForm.controls;
  }
  get f2() {
    return this.addSubCategoryForm.controls;
  }
  get f3() {
    return this.createProductForm.controls;
  }
  get f4() {
    return this.addAddonsForm.controls;
  }
  get f5() {
    console.log("------------ This . addonsfrom is ");
    console.log(this.addonsFrom.controls);
    return this.addonsFrom.controls;
  }

  onIconImageChanged(event: any) {
    // this.logoFile = false;
    if (event.target.files.length > 0) {
      this.handleFiles(event.target.files[0]);
    }
  }

  onSubCategoryIconImageChanged(event: any) {
    // this.logoFile = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addSubCategoryForm.patchValue({ iconImage: file });
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.SubCategorythumbImage = reader.result;
      };
    }
  }

  productImageUpload(event: any) {
    console.log("executed");

    for (var i = 0; i <= event.target.files.length - 1; i++) {
      var selectedFile = event.target.files[i];
      this.fileList.push(selectedFile);
      console.log(this.fileList);

      //this.createProductForm.patchValue({ subImages: this.fileList });
      //this.createProductForm.patchValue({ mainImage: this.fileList[0] });
      this.addDisplayImage(null, false, false, selectedFile);
    }
    this.attachment.nativeElement.value = "";
  }

  removeSelectedFile(index) {
    this.fileList.splice(index, 1);
    this.thumbFile.splice(index, 1);
  }
  removeProductImage(id): void {
    let mainImageInput: any = document.querySelector(".mainImageInput");
    console.log(mainImageInput.value);
    mainImageInput.value = "";

    var images = this.imagesToDisplay;
    console.log(images);
    var idx = images.findIndex((imgObj) => imgObj.id == id);
    console.log(idx);
    if (idx != -1) {
      var imageObj = images[idx];
      // if (!imageObj.isMain) {
      this.imagesToDisplay.splice(idx, 1);
      if (imageObj.url) this.deletedImages.push(imageObj.url);
      if (!this.imagesToDisplay.length) {
        this.addRequiredValidatorForImage();
      }
      // } else {
      //   this.toastr.warning("Main image cannot be removed.");
      // }
    }
    // checkpointremove
    this.submitted = true;
    this.createProductForm.controls["mainImage"].reset();
    console.log(this.createProductForm.value);
    if (this.validateUploadImagesArray()) {
    }
  }

  setMainImage(id) {
    console.log("id received is " + id);
    var images = this.imagesToDisplay;
    console.log(images);
    var idx = images.findIndex((imgObj) => imgObj.id == id);
    console.log("Id is found at index " + idx);
    if (idx != -1) {
      var imageObj = this.imagesToDisplay[idx];
      this.imagesToDisplay.forEach((imgObj, index) => {
        if (index != idx) {
          console.log("Setting main to false for index " + index);
          imgObj.isMain = false;
        } else {
          console.log("Setting main to true for index " + index);
          imgObj.isMain = true;
        }
      });
      // this.createProductForm.patchValue({ mainImage: imageObj.file });
    }
  }

  removeImage(type) {
    if (type === "categorythumbImage") {
      this.categorythumbImage = "";
      this.addCategoryForm.patchValue({ iconImage: null });
    }
    if (type === "SubCategorythumbImage") {
      this.SubCategorythumbImage = "";
      this.addSubCategoryForm.patchValue({ iconImage: null });
    }
  }

  onSubmit() {
    if (this.editCategoryItem == undefined) {
      this.createCategoryItem();
    } else {
      this.updateCategoryItem();
    }
  }

  createCategoryItem() {
    this.submitted = true;
    this.clicked = true;
    const formData = new FormData();
    let submitFormVal = this.addCategoryForm.value;
    formData.append("iconImage", this.addCategoryForm.get("iconImage").value);
    formData.append("name", submitFormVal.name);

    if (this.addCategoryForm.invalid) {
      this.clicked = false;
      return;
    }
    this.ngLoader = true;
    this.ApiService.createCategory(formData).subscribe(
      (res: any) => {
        this.ngLoader = false;
        console.log(res.message);
        if (res.success == 1) {
          this.hangleCategoryList();
          this.toastr.success(res.message);
          this.modalReference.close();
          this.addCategoryForm.reset();
          this.categorythumbImage = "";
          this.submitted = false;
          this.clicked = false;
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.ngLoader = false;
        this.toastr.error(error.error.message);
      }
    );
  }

  updateCategoryItem() {
    this.submitted = true;
    this.clicked = true;
    const formData = new FormData();
    let submitFormVal = this.addCategoryForm.value;

    formData.append("iconImage", this.addCategoryForm.get("iconImage").value);
    formData.append("name", submitFormVal.name);
    //formData.append('isEnabled', 'false');

    if (this.addCategoryForm.invalid) {
      this.clicked = false;
      return;
    }
    this.ApiService.editCategory(this.editCategoryItem, formData).subscribe(
      (res: any) => {
        this.ngLoader = false;
        if (res.success == 1) {
          this.hangleCategoryList();
          this.toastr.success(res.message);
          this.modalReference.close();
          this.addCategoryForm.reset();
          this.submitted = false;
          this.clicked = false;
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.toastr.error(error.error.message);
        this.ngLoader = false;
      }
    );
  }

  hangleCategoryList() {
    this.ngLoader = true;
    this.ApiService.getCategoryList().subscribe(
      (res: any) => {
        if (res.success == 1) {
          this.categoryList = res;
          //console.log(this.categoryList['items'].length > 0)
          if (this.categoryList.items.length > 0) {
            this.categoryFirstChildId = this.categoryList["items"][0].id;
            this.selectedCategoryName = this.categoryList["items"][0].name;
            this.clickedCategory(this.categoryFirstChildId, 0);
          } else {
            this.isSubCategoryList = false;
            this.productsDetails = false;
            this.clickedProductItem(null, null);
          }
        }
      },
      (error) => {
        this.ngLoader = false;
      }
    );
  }

  deleteCategory(categoryId) {
    Swal.fire({
      title: "Delete",
      text: "Are you sure you want to delete",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#374abe",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.ApiService.deleteCategoryItem(categoryId).subscribe(
          (res: any) => {
            // console.log(res.message);
            if (res.success == 1) {
              this.toastr.success(res.message);

              this.hangleCategoryList();
              this.getProductListItem();
              if (this.categoryList.items.length > 0) {
                this.categoryFirstChildId = this.categoryList["items"][0].id;
                this.clickedCategory(this.categoryFirstChildId, 0);
              }
            } else {
              this.toastr.warning(res.message);
            }
          },
          (error) => {
            this.toastr.error(error.error.message);
          }
        );
      }
    });
  }
  disableEnabledCatgory(categoryId, val) {
    var formData = new FormData();
    formData.append("isEnabled", val);
    this.ngLoader = true;
    this.ApiService.editCategory(categoryId, formData).subscribe(
      (res: any) => {
        this.ngLoader = false;
        console.log(res);
        console.log(res.message);
        if (res.success == 1) {
          this.hangleCategoryList();
          this.toastr.success(res.message);
          this.modalReference.close();
          this.addCategoryForm.reset();
          this.submitted = false;
          this.clicked = false;
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.ngLoader = false;
      }
    );
    this.ApiService.getProductList(categoryId, "").subscribe((res: any) => {
      let productList = res.items;
      console.log(productList);
      for (let i = 0; i < productList.length; i++) {
        this.disableEnabledProduct(productList[i].itemId, val);
      }
    });
  }

  clickedCategory(categoryId, _index) {
    this.selectclickedCategory = _index;
    this.globalCategoryIdProduct = categoryId;
    this.selectedCategory = categoryId;
    this.getProductListItem();
    if (!this.ngLoader) {
      this.ngLoader = true;
    }
    this.ApiService.getSubCategory(categoryId).subscribe(
      (res: any) => {
        if (res.success == 1) {
          this.subCategoryId = res.categoryId;
          this.subCategoryList = res["items"];

          if (this.subCategoryList.length > 0) {
            this.subCategoryFirstChildId = this.subCategoryList[0]._id;
            this.selectedCategoryName = this.subCategoryList[0].name;
            this.clickedSubCategory(this.subCategoryFirstChildId, 0);
          }

          /* this.clickedProductItem(this.productFirstChildId, 0); */

          if (this.subCategoryList.length == 0) {
            this.isSubCategoryList = false;
          } else {
            this.isSubCategoryList = true;
          }
        }
      },
      (error) => {
        this.ngLoader = false;
        // this.toastr.error(error.error.message);
      }
    );
    //console.log(this.globalCategoryIdProduct)
  }

  clickedSubCategory(subCategoryId, _index) {
    this.selectedsubCategoryItem = _index;
    this.globalCategoryIdProduct = subCategoryId;
    // console.log(subCategoryId)
    // this.selectedCategory = categoryId
    this.getProductListItem();
    this.clickedProductItem(this.productFirstChildId, 0);
  }

  deleteSubCategory(categoryid, subCategoryid) {
    Swal.fire({
      title: "Delete",
      text: "Are you sure you want to delete",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#374abe",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        // console.log(categoryid, subCategoryid)
        this.ApiService.deleteSubCategoryItem(
          categoryid,
          subCategoryid
        ).subscribe((res: any) => {
          this.toastr.success(res.message);
          this.hangleCategoryList();
          this.getProductListItem();
        });
      }
    });
  }

  onSubmitSubCategoryForm() {
    if (this.editSubCategoryItem == undefined) {
      this.createSubCategoryList();
    } else {
      this.updateSubCategoryList();
    }
  }

  createSubCategoryList() {
    this.submitted = true;
    this.clicked = true;
    const formData = new FormData();
    let submitFormVal = this.addSubCategoryForm.value;
    console.log(submitFormVal);
    formData.append(
      "iconImage",
      this.addSubCategoryForm.get("iconImage").value
    );
    formData.append("name", submitFormVal.name);
    if (this.addSubCategoryForm.invalid) {
      this.clicked = false;
      return;
    }
    this.ngLoader = true;
    this.ApiService.addSubCategoryList(
      this.editCategoryItem,
      formData
    ).subscribe(
      (res: any) => {
        this.ngLoader = false;
        // this.toastr.success(res.message);
        if (res.success == 1) {
          this.toastr.success(res.message);
          this.clickedCategory(this.selectedCategory, "");
          this.modalReference.close();
          this.addSubCategoryForm.reset();
          this.SubCategorythumbImage = "";
          this.submitted = false;
          this.clicked = false;
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.ngLoader = false;
      }
    );
  }
  updateSubCategoryList() {
    this.submitted = true;
    this.clicked = true;
    const formData = new FormData();
    let submitFormVal = this.addSubCategoryForm.value;
    formData.append(
      "iconImage",
      this.addSubCategoryForm.get("iconImage").value
    );
    formData.append("name", submitFormVal.name);

    if (this.addSubCategoryForm.invalid) {
      this.clicked = false;
      return;
    }
    let catgId = this.editCategoryItem;
    let SubcatgId = this.editSubCategoryItem;
    this.ngLoader = true;
    this.ApiService.updateSubCategoryList(
      catgId,
      SubcatgId,
      formData
    ).subscribe(
      (res: any) => {
        this.ngLoader = false;
        //  this.toastr.success(res);
        if (res.success == 1) {
          this.toastr.success(res.message);
          this.clickedCategory(this.selectedCategory, "");
          this.modalReference.close();
          // this.addSubCategoryForm.reset();
          // this.submitted = false;
          // this.clicked = false;
        } else {
          this.ngLoader = false;
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.ngLoader = false;
      }
    );
  }

  checkInventory(event: any) {
    this.checkInventoryEnabled = event.target.checked;
    console.log(event.target.checked);
  }
  checkVeg(event: any) {
    let value = event.target.checked;
    if (value == true) {
      this.tag = "veg";
    } else {
      this.tag = "nonVeg";
    }
  }

  saveAndCloseProduct(event: any) {
    this.saveAndContinue = event.target.checked;
  }
  validateUploadImagesArray() {
    console.log(this.imagesToDisplay);
    let isValid = true;
    let isNotEmpty = true;
    var allowedTypes = ["jpeg", "jpg", "png"];
    if (this.imagesToDisplay.length) {
      this.imagesToDisplay.forEach((image) => {
        if (image.file) {
          let fileName = image.file.name;
          const extension = fileName.split(".")[1].toLowerCase();

          if (allowedTypes.includes(extension.toLowerCase())) {
            console.log("File is valid");
          } else {
            isValid = false;
          }
        }
        if (image.isExisting) {
          console.log("Exising image found");
        }
      });
    } else {
      console.log("Images are empty");
      isNotEmpty = false;
    }

    /// 1

    let currentErrors = this.f3.mainImage.errors;

    if (currentErrors) {
      delete currentErrors.incorrect;
      delete currentErrors.required;
    }

    currentErrors = this.isEmpty(currentErrors) ? null : currentErrors;
    let imageErrors;
    if (isNotEmpty) {
      imageErrors = isValid ? currentErrors : { incorrect: true };
    } else {
      imageErrors = isNotEmpty ? currentErrors : { required: true };
    }

    this.f3.mainImage.setErrors(imageErrors);
    console.log("-------------------- main IMage errors are ");
    JSON.stringify(this.f3.mainImage.errors);
    let valid = isValid && isNotEmpty;

    return valid;
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  /*removeImageRequiredErrors() {

    let currentErrors = this.f3.mainImage.errors;
    
    if(currentErrors) delete currentErrors.incorrect;
    currentErrors = this.isEmpty(currentErrors)?null: currentErrors;
    let imageErrors = isValid ? currentErrors : { incorrect: true };
    this.f3.mainImage.setErrors(imageErrors);
  }*/
  removeRequiredValidatorForImage() {
    this.f3.mainImage.clearValidators();
  }
  addRequiredValidatorForImage() {
    let validators = this.productFormValidators.mainImage;
    for (let validator of validators) {
      this.f3.mainImage.setValidators(validator);
    }
    this.f3.mainImage.updateValueAndValidity();
  }

  onSubmitCreateProductForm() {
    /*if (this.isModalTypeEdit === "edit_product") {
      this.updateProduct();
      //console.log('updateProduct')
    } else if (this.isModalTypeEdit === "duplicate_product") {
      this.duplicateProduct();
      //console.log('duplicate_product')
    } else {
      this.createProduct();
      //console.log('createProduct')
    } */

    const { costPrice, sellingPrice } = this.createProductForm.value;
    if ((!sellingPrice || sellingPrice === "") && costPrice !== "") {
      this.createProductForm.get("sellingPrice")?.setValue(costPrice);
    }
    this.submitted = true;
    //this.f3.mainImage.setErrors(null);

    if (this.validateUploadImagesArray()) {
      if (this.isModalTypeEdit === "edit_product") {
        this.updateProduct();
        //console.log('updateProduct')
      } else if (this.isModalTypeEdit === "duplicate_product") {
        this.duplicateProduct();
        //console.log('duplicate_product')
      } else {
        this.createProduct();
        //console.log('createProduct')
      }
    }
  }

  duplicateProduct() {
    this.submitted = true;
    this.clicked = true;
    const formData = new FormData();
    let submitFormVal = this.createProductForm.value;
    console.log(this.createProductForm.value);
    var minQuantityPerOrder = parseInt(submitFormVal.minQuantityPerOrder);
    var maxQuantityPerOrder = parseInt(submitFormVal.maxQuantityPerOrder);
    var preparationTimeMinutes = parseFloat(
      submitFormVal.preparationTimeMinutes
    );
    var quantityAvailable = parseFloat(submitFormVal.quantityAvailable);
    formData.append("categoryId", this.globalCategoryIdProduct);
    formData.append("productName", submitFormVal.productName);
    formData.append("slug", submitFormVal.slug);
    formData.append("skuNumber", submitFormVal.skuNumber);
    formData.append("costPrice", submitFormVal.costPrice);
    formData.append("sellingPrice", submitFormVal.sellingPrice);
    formData.append("minQuantityPerOrder", minQuantityPerOrder.toString());
    formData.append("maxQuantityPerOrder", maxQuantityPerOrder.toString());
    formData.append(
      "preparationTimeMinutes",
      preparationTimeMinutes.toString()
    );
    formData.append("description", submitFormVal.description);
    formData.append("servesFor", submitFormVal.servesFor);
    formData.append("ingredients", submitFormVal.ingredients);
    formData.append("quantityAvailable", quantityAvailable.toString());
    formData.append("isInventoryEnabled", submitFormVal.isInventoryEnabled);
    formData.append("tag", submitFormVal.tag);

    if (this.createProductForm.invalid) {
      this.clicked = false;
      return;
    }

    this.ngLoader = true;
    this.ApiService.createProductList(formData).subscribe(
      (res: any) => {
        this.ngLoader = false;
        console.log(res);
        if (res.success == 1) {
          this.toastr.success(res.message);
          this.getProductListItem();
          let createdItemId = res.id;
          setTimeout(() => {
            this.clickedProductItem(createdItemId, this.productList.length - 1);
          }, 500);
          this.createProductForm.reset();
          let autoSkuNumber = Math.floor(100000 + Math.random() * 900000);
          this.createProductForm.patchValue({ skuNumber: autoSkuNumber });

          this.submitted = false;
          this.clicked = false;
          this.thumbFile = [];
          this.tag = "nonVeg";
          if (this.saveAndContinue == false) {
            this.modalReference.close();
          }
        } else {
          this.ngLoader = false;
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.ngLoader = false;
        //this.toastr.error(error.error.message);
      }
    );
  }
  attachImagesToForm(formData) {
    var images = this.imagesToDisplay;
    for (var imgObj of images) {
      if (!imgObj.url) {
        var file = imgObj.file;
        if (!imgObj.isMain) {
          formData.append("subImages", file);
        } else formData.append("mainImage", file);
      } else if (imgObj.isMain) {
        formData.append("replacedMainImageName", imgObj.url);
      }
    }
    formData.append("deletedImages", this.deletedImages.join(","));
    return formData;
  }
  createProduct() {
    this.submitted = true;
    this.clicked = true;
    var formData = new FormData();
    //  this.createProductForm.patchValue({ minQuantityPerOrder: '1' ,isInventoryEnabled :false, quantityAvailable:''})
    let submitFormVal = this.createProductForm.value;
    console.log(this.globalCategoryIdProduct);
    console.log(this.createProductForm);

    if (this.createProductForm.invalid) {
      this.clicked = false;
      return;
    }
    formData = this.attachImagesToForm(formData);

    console.log(
      "Min quantity per order is " + submitFormVal.minQuantityPerOrder
    );
    console.log("Quantity available is " + submitFormVal.quantityAvailable);
    formData.append("categoryId", this.globalCategoryIdProduct);
    formData.append("productName", submitFormVal.productName);
    formData.append("slug", submitFormVal.slug);
    formData.append("skuNumber", submitFormVal.skuNumber);
    formData.append("costPrice", submitFormVal.costPrice);
    formData.append("sellingPrice", submitFormVal.sellingPrice);
    formData.append("minQuantityPerOrder", submitFormVal.minQuantityPerOrder);
    formData.append("maxQuantityPerOrder", submitFormVal.maxQuantityPerOrder);
    formData.append(
      "preparationTimeMinutes",
      submitFormVal.preparationTimeMinutes
    );
    formData.append("description", submitFormVal.description);
    formData.append("ingredients", submitFormVal.ingredients);
    formData.append("quantityAvailable", submitFormVal.quantityAvailable);
    formData.append("isInventoryEnabled", submitFormVal.isInventoryEnabled);
    formData.append("servesFor", submitFormVal.servesFor);
    formData.append("tag", this.tag);

    this.ngLoader = true;
    this.ApiService.createProductList(formData).subscribe(
      (res: any) => {
        this.ngLoader = false;
        console.log(res);
        if (res.success == 1) {
          this.toastr.success(res.message);
          this.getProductListItem();
          let createdItemId = res.id;
          setTimeout(() => {
            this.clickedProductItem(createdItemId, this.productList.length - 1);
          }, 500);
          this.reInitProductForm();
          if (this.saveAndContinue == false) {
            this.modalReference.close();
          }
        } else {
          this.ngLoader = false;
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        console.log(error);
        this.ngLoader = false;
        //   if(error.error.errors.length > 0){
        //   this.toastr.error(error.error.errors[0].message);

        // }
      }
    );
  }

  updateProduct() {
    this.submitted = true;
    this.clicked = true;
    var formData = new FormData();
    let submitFormVal = this.createProductForm.value;

    //let imageArry = submitFormVal.subImages.length;
    console.log("Serves for is ");
    console.log(submitFormVal.servesFor);

    formData = this.attachImagesToForm(formData);
    formData.append("categoryId", this.editCategoryItem);
    formData.append("productName", submitFormVal.productName);
    formData.append("slug", submitFormVal.slug);
    formData.append("skuNumber", submitFormVal.skuNumber);
    formData.append("costPrice", submitFormVal.costPrice);
    formData.append("sellingPrice", submitFormVal.sellingPrice);
    formData.append("minQuantityPerOrder", submitFormVal.minQuantityPerOrder);
    formData.append("maxQuantityPerOrder", submitFormVal.maxQuantityPerOrder);
    formData.append(
      "preparationTimeMinutes",
      submitFormVal.preparationTimeMinutes
    );
    formData.append("description", submitFormVal.description);
    formData.append("ingredients", submitFormVal.ingredients);
    formData.append("quantityAvailable", submitFormVal.quantityAvailable);
    if (submitFormVal.isInventoryEnabled == null) {
      formData.append("isInventoryEnabled", "false");
    } else {
      formData.append("isInventoryEnabled", submitFormVal.isInventoryEnabled);
    }
    formData.append("servesFor", submitFormVal.servesFor);
    formData.append("tag", submitFormVal.tag);
    console.log(submitFormVal);
    if (this.createProductForm.invalid) {
      this.clicked = false;
      return;
    }
    this.ngLoader = true;
    let itemId = this.editSubCategoryItem;
    this.ApiService.updateProductListItem(itemId, formData).subscribe(
      (res: any) => {
        this.ngLoader = false;
        if (res.success == 1) {
          this.toastr.success(res.message);
          this.getProductListItem();
          setTimeout(() => {
            this.clickedProductItem(itemId, "");
          }, 500);
          this.modalReference.close();
          this.createProductForm.reset();
          this.submitted = false;
          this.clicked = false;
          this.thumbFile = [];
          this.tag = "nonVeg";
          this.updateImage = "";
        } else {
          this.ngLoader = false;
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.ngLoader = false;
        //this.toastr.error(error.error.message);
      }
    );
  }

  onNameChange(searchValue: string): void {
    let slugName = this.createSlug(searchValue);
    this.createProductForm.patchValue({ slug: slugName });
    //this.onSearchChange(slugName)
  }
  createSlug(Text) {
    return Text.toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-");
  }

  updateProductQuantity(event, itemId) {
    let val = event.target.value;
    let formData = new FormData();
    formData.append("quantityAvailable", val);
    formData.append("isInventoryEnabled", "true");
    this.ngLoader = true;
    this.ApiService.updateProductListItem(itemId, formData).subscribe(
      (res: any) => {
        this.ngLoader = false;
        // console.log(res);
        if (res.success == 1) {
          this.toastr.success("Quantity Updated successfully");
          this.clickedProductItem(itemId, null);
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.ngLoader = false;
        this.toastr.error(error.error.message);
      }
    );
  }

  getProductListItem() {
    this.ngLoader = true;
    console.log("GET PRODUCT LIST ITEM");

    this.ApiService.getProductList(this.globalCategoryIdProduct, "").subscribe(
      (res: any) => {
        this.ngLoader = false;
        if (res.success == 1) {
          this.productList = res["items"];
          console.log(this.productList);
          if (this.productList.length > 0) {
            this.productFirstChildId = this.productList[0].itemId;
            this.clickedProductItem(this.productFirstChildId, 0);
            this.productsDetails = false;
            this.prodctDetailsView = true;
          }
          if (this.productList.length == 0) {
            this.prodctDetailsView = false;
          }
        } else {
        }
      },
      (error) => {
        // this.toastr.error(error.error.message);
      }
    );
  }

  deleteProduct(id) {
    Swal.fire({
      title: "Delete",
      text: "Are you sure you want to delete",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#374abe",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.ApiService.deleteProductItem(id).subscribe((res: any) => {
          this.toastr.success(res.message);
          this.getProductListItem();
          setTimeout(() => {
            this.clickedProductItem(this.productFirstChildId, 0);
          }, 500);
        });
      }
    });
  }

  clickedProductItem(productItemId, _index) {
    this.selectedclickedProductItem = _index;
    if (!this.ngLoader) {
      this.ngLoader = true;
    }
    const apiRequests = [this.ApiService.getProductdetails(productItemId)];
    apiRequests.push(this.ApiService.getAddonsList(productItemId));
    forkJoin(apiRequests).subscribe(
      (apiResponse: any[]) => {
        this.isSimpleLayoutEnabled =
          apiResponse[0].isSimpleLayoutEnabled || false;
        this.imageBase = apiResponse[0].imageBase;
        this.productsDetails = apiResponse[0]["item"];

        this.addonsList = apiResponse[1]["items"];
        if (apiResponse[0]["item"]["addOns"].length > 0) {
          this.enableAddons = "true";
          this.isChecked = true;
        } else {
          this.enableAddons = "false";
          this.isChecked = false;
        }
        if (apiResponse[0]["item"].isInventoryEnabled == true) {
          this.isCheckedQuantity = true;
          this.enableQuantity = true;
        } else if (apiResponse[0]["item"].isInventoryEnabled == false) {
          this.isCheckedQuantity = false;
          this.enableQuantity = false;
        }
        this.ngLoader = false;
      },
      (error) => {
        this.ngLoader = false;
      }
    );
  }

  disableEnabledProduct(categoryId, val) {
    var formData = new FormData();
    formData.append("isEnabled", val);

    this.ApiService.updateProductListItem(categoryId, formData).subscribe(
      (res: any) => {
        console.log(res.message);
        if (res.success == 1) {
          this.getProductListItem();
          this.toastr.success(res.message);
        } else {
          this.toastr.warning(res.message);
        }
      }
    );
  }

  onSubmitAddonsForm() {
    console.log("Add on errors are");
    console.log(JSON.stringify(this.addonErrors));
    var addonValuesCount = this.addonsFrom.getRawValue().length;
    this.showAddonOptionsEmptyError = false;
    this.submitted = true;
    if (!this.checkMultiSelectEnabled) {
      this.addAddonsForm.patchValue({ maxAllowedOptionsCount: 0 });
      console.log("Multi select is not enabled....");
    } else {
      console.log("Multi select is   enabled....");
      console.log(this.addAddonsForm.value.maxAllowedOptionsCount);
      if (this.addAddonsForm.value.maxAllowedOptionsCount <= 0) {
        this.multiSelectAlert = true;
        return;
      }
    }
    if (!addonValuesCount) {
      this.showAddonOptionsEmptyError = true;
      return;
    }
    if (this.addAddonsForm.invalid) {
      console.log("Form is still invalid....");
      console.log("--------------- " + this.addAddonsForm.invalid);
      console.log(this.addAddonsForm.controls);
      console.log("Errors addonerrors 1234....");
      console.log(this.addAddonsForm.controls.options);
      console.log(this.addonErrors);
      this.clicked = false;
      return;
    }
    for (var error of this.addonErrors) {
      if (error.name || error.price) {
        return;
      }
    }
    if (this.isModalTypeEdit === "editAddons") {
      this.updateAddonsForm();
    } else {
      this.createAddonsForm();
    }
  }
  createAddonsForm() {
    this.submitted = true;
    this.clicked = true;
    let productID = this.editCategoryItem;
    if (this.addAddonsForm.invalid) {
      this.clicked = false;
      return;
    }
    this.ngLoader = true;
    this.ApiService.createAddons(productID, this.addAddonsForm.value).subscribe(
      (res: any) => {
        this.ngLoader = false;
        if (res.success == 1) {
          this.clickedProductItem(productID, productID);
          this.toastr.success(res.message);
          this.modalReference.close();
          this.addAddonsForm.reset();
          this.submitted = false;
          this.clicked = false;
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.ngLoader = false;
        this.toastr.error(error.error.errors[0].message);
        console.log(error.error.errors.message);
      }
    );
  }
  updateAddonsForm() {
    this.submitted = true;
    this.clicked = true;
    let productID = this.editCategoryItem;

    // if (this.addAddonsForm.invalid) {
    //   this.clicked = false;
    //   return;
    // }
    console.log(this.addAddonsForm.value);
    this.ApiService.updateAddonsDetails(
      productID,
      this.editSubCategoryItem,
      this.addAddonsForm.value
    ).subscribe(
      (res: any) => {
        if (res.success == 1) {
          this.clickedProductItem(productID, productID);
          this.toastr.success(res.message);
          this.modalReference.close();
          this.addAddonsForm.reset();
          this.submitted = false;
          this.clicked = false;
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.toastr.error(error.error.errors[0].message);
        console.log(error.error.errors.message);
      }
    );
  }

  deleteAddons(productId, addonsId) {
    Swal.fire({
      title: "Delete",
      text: "Are you sure you want to delete",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#374abe",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.ApiService.deleteAddonsList(productId, addonsId).subscribe(
          (res: any) => {
            this.toastr.success(res.message);
            this.ApiService.getAddonsList(productId).subscribe((res: any) => {
              this.addonsList = res["items"];
            });
          },
          (error) => {
            this.toastr.error(error.error.message);
          }
        );
      }
      //  console.log(productId, addonsId)
    });
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // Add any styling or feedback for drag over here
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // Remove any styling or feedback for drag leave here
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      // Handle the dropped files (e.g., upload or display them)
      this.handleFiles(files[0]);
    }

    // Remove any styling or feedback for drag leave here
  }
  handleFiles = (file) => {
    this.addCategoryForm.patchValue({ iconImage: file });
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.categorythumbImage = reader.result;
    };
  };
}
