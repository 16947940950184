import { Component, OnInit } from '@angular/core';
import { json } from '@rxweb/reactive-form-validators';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userDetails: any;
  userName: any;
  logoText: any;

  constructor(private ApiService:ApiService) { }
  ngOnInit(){

  }
   ngDoCheck() {
    if(this.ApiService.loggedIn()){
    this.userDetails =  JSON.parse(localStorage.getItem("UserDetails"))
    this.userName =   this.userDetails.name;
    this.logoText =  this.userName.substring(0,1)
    }
  }

}
