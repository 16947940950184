import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { BASE_URL } from "../../environments/api";
import { BASE_URL_PWA } from "../../environments/api";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs";

@Injectable()
export class ApiService {
  UserToken: string;
  decodedToken: any;
  tokenExpTime: any;
  tokenData: Object;
  restaurantId: any;
  loginUserRoles = JSON.parse(localStorage.getItem("userRoles"));
  static userLogedIn() {
    throw new Error("Method not implemented.");
  }

  constructor(private http: HttpClient) {}
  userLogedIn;

  getRestaurantId(Id) {
    this.restaurantId = Id;
  }

  POST_LOGIN = `${BASE_URL}/accounts/login`;
  FORGOT_PASSWORD = `${BASE_URL}/accounts/forgot-password`;
  RESET_PASSWORD = `${BASE_URL}/accounts/reset-password`;
  REGISTER_USER = `${BASE_URL}/accounts/restaurant/admin`;
  RESTAURANTS_TOKEN = `${BASE_URL}/accounts/restaurant/token`;
  POST_RESTAURANTS_SETUP = `${BASE_URL}/restaurants`;
  GET_RESTAURANTS_SETUP = `${BASE_URL}/restaurants`;
  UPDATE_RESTAURANTS_SETUP = `${BASE_URL}/restaurants`;
  SLUG_RESTAURANTS = `${BASE_URL}/restaurants/slug/check`;
  LIST_CUSTOMER = `${BASE_URL}/users`;
  CREATE_CUSTOMER = `${BASE_URL}/users`;
  UPDATE_CUSTOMER = `${BASE_URL}/users/update/`;
  DETAILS_CUSTOMER = `${BASE_URL}/users/details/`;
  DELETE_CUSTOMER = `${BASE_URL}/users/delete/`;
  BLOCK_CUSTOMER = `${BASE_URL}/users/update-block-status/:customer_id`;
  SEARCH_CUSTOMER = `${BASE_URL}/users/names`;
  CREATE_ORDER = `${BASE_URL}/orders/create`;
  GET_ORDER_DETAILS = `${BASE_URL}/orders/`;
  EDIT_ORDER = `${BASE_URL}/orders/`;
  LIST_ORDER = `${BASE_URL}/orders`;
  ORDER_STATUS = `${BASE_URL}/masters/order-statuses`;
  UPDATE_ORDER_STATUS = `${BASE_URL}/orders/`;
  ORDER_ACCEPT = `${BASE_URL}/orders/`;
  GET_EMIRATES_LIST = `${BASE_URL}/masters/emirates/list`;
  GET_AREA_LIST = `${BASE_URL}/masters/delivery-areas/list`;
  CREATE_EMIRATE = `${BASE_URL}/masters/emirates/create`;
  EMIRATES_LIST = `${BASE_URL}/masters/emirates/list`;
  GET_EMIRATE = `${BASE_URL}/masters/emirates/`;
  DELETE_EMIRATE = `${BASE_URL}/masters/emirates/delete/`;
  UPDATE_EMIRATE = `${BASE_URL}/masters/emirates/update/`;
  CREATE_AREA = `${BASE_URL}/masters/delivery-areas/create`;
  AREA_LIST = `${BASE_URL}/masters/delivery-areas/list`;
  GET_AREA = `${BASE_URL}/masters/delivery-areas/detail/`;
  DELETE_AREA = `${BASE_URL}/masters/delivery-areas/delete/`;
  UPDATE_AREA = `${BASE_URL}/masters/delivery-areas/update/`;
  DISCOUNT = `${BASE_URL}/masters/restaurant-offers/`;
  DISCOUNT_MODE = `${BASE_URL}/masters/restaurant-promo/`;
  DISCOUNT_lIST = `${BASE_URL}/masters/restaurant-offers/`;
  GET_ROLES = `${BASE_URL}/masters/roles`;
  CREATE_USER = `${BASE_URL}/restaurants/`;
  GET_RESTAURANT_USERS = `${BASE_URL}/restaurants/`;
  GET_PROFILEDATA = `${BASE_URL}/accounts/profile`;
  UPDATE_PROFILEDATA = `${BASE_URL}/accounts/self`;
  CLEAR_TESTING_CATALOGUE = `${BASE_URL}/restaurants/clear/test/catalogues`;
  CLEAR_CATALOGUE = `${BASE_URL}/restaurants/clear/your-catalogues`;
  CREATE_PAGE = `${BASE_URL}/restaurants/pages/`;
  GET_PAGE_LIST = `${BASE_URL}/restaurants/pages/`;
  COUNTRY_LIST = `${BASE_URL}/masters/countries`;
  CREATE_ADDRESS = `${BASE_URL}/users/`;
  GET_RESTAURANT_CONFIG = `${BASE_URL}/restaurants/config/details`;
  UPDATE_RESTAURANT_CONFIG = `${BASE_URL}/restaurants/config/update`;
  UPDATE_DELIVERY_AREAS = `${BASE_URL}/masters/delivery-areas/restaurant-update`;
  VERIFY_RESTAURANT = `${BASE_URL}/accounts/verify`;
  RESENT_VERIFICATION_LINK = `${BASE_URL}/accounts/resend-verification-link`;

  getUserRoles(data) {
    this.loginUserRoles = data;
  }

  loggedIn() {
    return !!localStorage.getItem("UserToken");
  }
  loginSubmit(loginData) {
    console.log("Login api url " + this.POST_LOGIN);
    console.log("Login api data " + JSON.stringify(loginData));
    return this.http.post(this.POST_LOGIN, loginData);
  }
  canShowSidebar() {
    var ret = this.loggedIn();

    return ret;
  }
  checkUserExists(formData) {
    var url = `${BASE_URL}/accounts/check-user-exists?email=${formData.email}`;
    return this.http.get(url);
  }
  importData(data) {
    var restaurantId = this.restaurantId;
    var url = `${BASE_URL}/products/${restaurantId}/import-from-excel`;
    return this.http.post(url, data);
  }
  forgotPasswordApi(data) {
    return this.http.post(this.FORGOT_PASSWORD, data);
  }
  resetPasswordApi(data) {
    return this.http.post(this.RESET_PASSWORD, data);
  }
  registerUserApi(data) {
    return this.http.post(this.REGISTER_USER, data);
  }
  setupRestaurantsSubmit(setupRestaurants) {
    return this.http.post(this.POST_RESTAURANTS_SETUP, setupRestaurants);
  }
  setupRestaurantsUpdate(id, setupRestaurants) {
    return this.http.patch(
      this.UPDATE_RESTAURANTS_SETUP + "/" + id,
      setupRestaurants
    );
  }
  getRestaurantsSetup(restaurantToken) {
    return this.http.get(this.GET_RESTAURANTS_SETUP + "/" + restaurantToken);
  }
  getSlugChecking(body) {
    return this.http.post(this.SLUG_RESTAURANTS, body);
  }

  createCategory(data) {
    return this.http.post(
      `${BASE_URL}/categories/` + this.restaurantId + `/catalogue/categories`,
      data
    );
  }
  getCategoryList() {
    return this.http.get(
      `${BASE_URL}/categories/` + this.restaurantId + `/catalogue/categories/`
    );
  }
  getCategoryDetails(id) {
    return this.http.get(
      `${BASE_URL}/categories/` +
        this.restaurantId +
        `/catalogue/categories/` +
        id
    );
  }

  deleteCategoryItem(id) {
    return this.http.delete(
      `${BASE_URL}/categories/` +
        this.restaurantId +
        `/catalogue/categories/` +
        id
    );
  }
  editCategory(id, data) {
    return this.http.patch(
      `${BASE_URL}/categories/` +
        this.restaurantId +
        `/catalogue/categories/` +
        id,
      data
    );
  }

  addSubCategoryList(categoryId, data) {
    return this.http.patch(
      `${BASE_URL}/categories/` +
        this.restaurantId +
        `/catalogue/categories/` +
        categoryId +
        "/subCategories",
      data
    );
  }

  updateSubCategoryList(catgId, SubcatgId, formData) {
    return this.http.patch(
      `${BASE_URL}/categories/` +
        this.restaurantId +
        `/catalogue/categories/` +
        catgId +
        "/subCategories/update/" +
        SubcatgId,
      formData
    );
  }

  getSubCategory(categoryId) {
    return this.http.get(
      `${BASE_URL}/categories/` +
        this.restaurantId +
        `/catalogue/categories/` +
        categoryId +
        "/subCategories"
    );
  }
  getSubCategoryDetails(categoryid, subCategoryid) {
    return this.http.get(
      `${BASE_URL}/categories/` +
        this.restaurantId +
        `/catalogue/categories/` +
        categoryid +
        "/subCategories/" +
        subCategoryid
    );
  }
  deleteSubCategoryItem(categoryid, subCategoryid) {
    return this.http.delete(
      `${BASE_URL}/categories/` +
        this.restaurantId +
        `/catalogue/categories/` +
        categoryid +
        "/subCategories/" +
        subCategoryid
    );
  }

  getRestaurantDetails()
  {
    return this.http.get(
      `${BASE_URL}/restaurants/products/` + this.restaurantId,
    ); 
  }
  getProductList(itemCategoryId, keyword) {
    return this.http.get(
      `${BASE_URL}/products/` + this.restaurantId + `/products`,
      {
        params: {
          itemCategoryId: itemCategoryId,
          keyword: keyword,
          // 'perPage': 30,
        },
      }
    );
  }

  createProductList(data) {
    // for(let item of data){
    //   console.log(item)
    // }
    return this.http.post(
      `${BASE_URL}/products/` + this.restaurantId + `/products`,
      data
    );
  }

  updateProductListItem(categoryId, data) {
    // for(let item of data){
    //   console.log(item)
    // }
    return this.http.patch(
      `${BASE_URL}/products/` + this.restaurantId + `/products/` + categoryId,
      data
    );
  }

  deleteProductItem(id) {
    return this.http.delete(
      `${BASE_URL}/products/` + this.restaurantId + `/products/` + id
    );
  }

  getProductdetails(productId) {
    console.log("Product details are ");
    console.log(
      `${BASE_URL}/products/` + this.restaurantId + `/products/` + productId
    );
    return this.http.get(
      `${BASE_URL}/products/` + this.restaurantId + `/products/` + productId
    );
  }

  createAddons(productId, data) {
    return this.http.post(
      `${BASE_URL}/products/` + this.restaurantId + `/products/` + productId,
      data
    );
  }

  getAddonsList(productId) {
    return this.http.get(
      `${BASE_URL}/products/` +
        this.restaurantId +
        `/products/` +
        productId +
        "/addOns/"
    );
  }
  deleteAddonsList(productId, addonsId) {
    return this.http.delete(
      `${BASE_URL}/products/` +
        this.restaurantId +
        `/products/` +
        productId +
        "/addOns/" +
        addonsId
    );
  }
  getAddonsDetails(productId, addonsId) {
    return this.http.get(
      `${BASE_URL}/products/` +
        this.restaurantId +
        `/products/` +
        productId +
        "/addOns/" +
        addonsId
    );
  }
  updateAddonsDetails(productId, addonsId, data) {
    return this.http.patch(
      `${BASE_URL}/products/` +
        this.restaurantId +
        `/products/` +
        productId +
        "/addOns/" +
        addonsId,
      data
    );
  }

  createCustomerApi(data) {
    return this.http.post(this.CREATE_CUSTOMER, data);
  }
  searchCustomerApi(keyword) {
    return this.http.get(this.SEARCH_CUSTOMER, {
      params: {
        name: keyword,
        restaurantId: this.restaurantId,
      },
    });
  }
  createOrderApi(data) {
    return this.http.post(this.CREATE_ORDER, data);
  }

  createEmirate(data) {
    return this.http.post(this.CREATE_EMIRATE, data);
  }

  getEmiratesListPage(page, perPage) {
    return this.http.get(this.EMIRATES_LIST, {
      params: {
        page: page,
        perPage: perPage,
      },
    });
  }

  getEmirateData(id) {
    return this.http.get(this.GET_EMIRATE + id);
  }

  deleteEmirate(id) {
    return this.http.delete(this.DELETE_EMIRATE + id);
  }

  updateEmirate(data, id) {
    return this.http.patch(this.UPDATE_EMIRATE + id, data);
  }

  createArea(data) {
    return this.http.post(this.CREATE_AREA, data);
  }

  getAreaData(id) {
    return this.http.get(this.GET_AREA + id);
  }

  updateArea(data, id) {
    return this.http.patch(this.UPDATE_AREA + id, data);
  }

  deleteArea(id) {
    return this.http.delete(this.DELETE_AREA + id);
  }

  getAreaListPage(id, page, perPage) {
    return this.http.get(this.AREA_LIST, {
      params: {
        emiratesId: id,
        page: page,
        perPage: perPage,
      },
    });
  }

  getRoles() {
    return this.http.get(this.GET_ROLES);
  }

  createUser(data, id) {
    return this.http.post(this.CREATE_USER + id + "/users", data);
  }

  getRestaurantUsers(id, page, perPage) {
    return this.http.get(this.GET_RESTAURANT_USERS + id + "/users", {
      params: {
        page: page,
        perPage: perPage,
      },
    });
  }

  deleteUser(id) {
    return this.http.delete(
      `${BASE_URL}/restaurants/` + this.restaurantId + "/users/" + id
    );
  }

  getUserData(id) {
    return this.http.get(
      `${BASE_URL}/restaurants/` + this.restaurantId + "/users/" + id
    );
  }

  updateUserData(id, data) {
    return this.http.patch(
      `${BASE_URL}/restaurants/` + this.restaurantId + "/users/" + id,
      data
    );
  }

  getProfileData() {
    return this.http.get(this.GET_PROFILEDATA);
  }

  getProfileDataUsingRestId(id:any) {
    return this.http.get(this.GET_PROFILEDATA+'/'+id);
  }

  updateProfile(data) {
    return this.http.patch(this.UPDATE_PROFILEDATA, data);
  }

  updateProfileById(id,data) {
    return this.http.patch(this.UPDATE_PROFILEDATA +'/'+id, data);
  }


  tableListApi() {
    return this.http.get(
      `${BASE_URL}/restaurants/` + this.restaurantId + `/tables/`
    );
  }

  getEmiratesList() {
    return this.http.get(this.GET_EMIRATES_LIST);
  }
  getAreaList(id) {
    let params = id?{
      emiratesId: id,
    }: {};
    params["pagination"] =  0;
    return this.http.get(this.GET_AREA_LIST, {
      params: params,
    });
  }

  getOrdersList(orderStatus, formData, page, perPage) {
    return this.http.get(this.LIST_ORDER, {
      params: {
        orderStatus: orderStatus,
        startDate: formData.startDate,
        endDate: formData.endDate,
        orderId: formData.orderId,
        deliveryAreaId: formData.deliveryAreaId,
        emiratesId:formData.emiratesId,
        userName: formData.userName,
        page: page,
        perPage: perPage,
      },
    });
  }

  ordersAccept(Id, body) {
    return this.http.patch(this.ORDER_ACCEPT + Id + "/order-accept", body);
  }

  getOrdersStatus() {
    return this.http.get(this.ORDER_STATUS);
  }

  updateOrdersStatus(Id, body) {
    return this.http.patch(this.UPDATE_ORDER_STATUS + Id + "/status/", body);
  }

  clearTestingCatalogue() {
    var data = {};
    return this.http.patch(this.CLEAR_TESTING_CATALOGUE, data);
  }

  clearCatalogue() {
    var data = {};
    return this.http.patch(this.CLEAR_CATALOGUE, data);
  }

  createPage(id, data) {
    return this.http.post(this.CREATE_PAGE + id, data);
  }

  getPageList(id) {
    return this.http.get(this.GET_PAGE_LIST + id);
  }

  getPageDetails(id) {
    return this.http.get(
      `${BASE_URL}/restaurants/` + this.restaurantId + "/pages/" + id
    );
  }

  updatePageData(id, data) {
    return this.http.patch(
      `${BASE_URL}/restaurants/` + this.restaurantId + "/pages/update/" + id,
      data
    );
  }

  deletePage(id) {
    return this.http.delete(
      `${BASE_URL}/restaurants/` + this.restaurantId + "/pages/" + id
    );
  }

  getOrderDetails(Id) {
    return this.http.get(this.GET_ORDER_DETAILS + Id);
  }

  updateOrderDetails(orderId, formData) {
    return this.http.patch(this.EDIT_ORDER + orderId, formData);
  }
  getCustomerDetails(Id) {
    console.log("Calling api " + this.DETAILS_CUSTOMER + Id);
    return this.http.get(this.DETAILS_CUSTOMER + Id);
  }
  getCustomersList(page, perPage) {
    return this.http.get(this.LIST_CUSTOMER, {
      params: {
        page: page,
        perPage: perPage,
      },
    });
  }
  deleteCustomerItem(id) {
    return this.http.delete(this.DELETE_CUSTOMER + id);
  }
  toggleBlocking(customerId) {
    let url = this.BLOCK_CUSTOMER.replace(":customer_id", customerId);
    console.log(`Url is`, url);
    return this.http.patch(url, {});
  }
  updateCustomerDetails(pageId, formData) {
    return this.http.patch(this.UPDATE_CUSTOMER + pageId, formData);
  }
  customerOrdersHistory(customerId) {
    return this.http.get(this.LIST_ORDER, {
      params: {
        userId: customerId,
      },
    });
  }
  createDiscountApi(data) {
    return this.http.post(this.DISCOUNT, data);
  }
  getDiscountApi() {
    return this.http.get(this.DISCOUNT_lIST, {
      params: {
        isDiscount: "true",
      },
    });
  }
  getDiscountDetails(Id) {
    return this.http.get(this.DISCOUNT + Id);
  }
  updateDiscountDetails(Id, data) {
    return this.http.patch(this.DISCOUNT + Id, data);
  }
  updateDiscountStatus(Id, data) {
    return this.http.patch(this.DISCOUNT + Id + "/status", data);
  }
  deleteDiscount(id) {
    return this.http.delete(this.DISCOUNT_lIST + id);
  }

  createPromoCodeApi(data) {
    return this.http.post(this.DISCOUNT, data);
  }
  getPromocodeDetails(Id) {
    return this.http.get(this.DISCOUNT + Id);
  }
  updatePromocodeDetails(Id, data) {
    return this.http.patch(this.DISCOUNT + Id, data);
  }
  updatePromocodeModeDetails(Id, data) {
    return this.http.patch(this.DISCOUNT_MODE + Id, data);
  }
  updatePromocodeStatus(Id, data) {
    return this.http.patch(this.DISCOUNT + Id + "/status", data);
  }
  getPromoCodeApi(filter) {
    return this.http.get(this.DISCOUNT_lIST, {
      params: {
        isPromoCode: "true",
        promoFilter: filter,
      },
    });
  }
  deletePromoCode(id) {
    return this.http.delete(this.DISCOUNT_lIST + id);
  }
  createAddressApi(id, data) {
    var url = this.CREATE_ADDRESS + id + "/address/";
    console.log("Url for address create api is " + url);
    return this.http.post(url, data);
  }
  getAddressDetails(id, dataId) {
    return this.http.get(this.CREATE_ADDRESS + id + "/address/" + dataId);
  }
  deleteAddressApi(id, dataId) {
    return this.http.delete(this.CREATE_ADDRESS + id + "/address/" + dataId);
  }
  updateAddressApi(userId, addressId, formData) {
    return this.http.patch(
      this.CREATE_ADDRESS + userId + "/address/" + addressId,
      formData
    );
  }
  getCountryList() {
    return this.http.get(this.COUNTRY_LIST);
  }
  updateDeliveryAreas(data) {
    return this.http.patch(this.UPDATE_DELIVERY_AREAS, data);
  }
  getRestaurantConfig() {
    return this.http.get(this.GET_RESTAURANT_CONFIG);
  }

  updateRestaurantConfig(data) {
    return this.http.patch(this.UPDATE_RESTAURANT_CONFIG, data);
  }

  roleMatch(allowedRoles): boolean {
    var isMatch = false;
    var userRoles: string[] = JSON.parse(localStorage.getItem("userRoles"));
    allowedRoles.forEach((element) => {
      if (userRoles.indexOf(element) > -1) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }
  getDashboardCount() {
    return this.http.get(
      `${BASE_URL}/restaurants/` + this.restaurantId + `/dashboard-counts`
    );
  }
  getDashboardOrdersList() {
    return this.http.get(`${this.LIST_ORDER}?perPage=10`);
  }
  getFrontPageUrl() {
    var slug = localStorage.getItem("slug");
    console.log("Slug is " + slug);
    var url = `${BASE_URL_PWA}/${slug}`;
    return url;
  }
  getFrontPageUrlFromSlug(slug) {
    if (!slug) slug = localStorage.getItem("slug");
    var url = `${BASE_URL_PWA}/${slug}`;
    return url;
  }
  getRestaurantList(page, perPage) {
    return this.http.get(`${BASE_URL}/restaurants/list/restaurants`, {
      params: {
        page: page,
        perPage: perPage,
      },
    });
  }
  getRestaurantToken(body) {
    console.log(body);
    return this.http.post(this.RESTAURANTS_TOKEN, body);
  }
  verifyRestaurant(token) {
    var body = {
      token: token,
    };
    return this.http.post(this.VERIFY_RESTAURANT, body);
  }
  resendVerificationLink() {
    return this.http.post(this.RESENT_VERIFICATION_LINK, {});
  }
  getUnixTsInSecondsFromDate(dateStr) {
    return (Math.floor(Date.parse(dateStr)/1000)) || 0;
  }
  getUnixTsInSecondsFromDateObj(date) {
    return Math.floor(date.getTime()/1000);
  }
  getDateFromUnixTsInSeconds(unixTsInSeconds) {
    return new Date(unixTsInSeconds*1000); 
  }
  getDateStrFromUnixTsInSeconds(unixTsInSeconds) { 
    console.log("Date is",this.getDateFromUnixTsInSeconds(unixTsInSeconds));
    return this.getDateFromUnixTsInSeconds(unixTsInSeconds).toISOString().slice(0, 10);
  }
  addDateStrsFromUnixTs(obj) {
    obj.startDate = obj.startUnixTsSeconds?this.getDateStrFromUnixTsInSeconds(obj.startUnixTsSeconds): null;
    obj.endDate = obj.endUnixTsSeconds?this.getDateStrFromUnixTsInSeconds(obj.endUnixTsSeconds): null;
    return obj;
  }
  addDatesFromUnixTs(obj) {
    obj.startDate = obj.startUnixTsSeconds?this.getDateFromUnixTsInSeconds(obj.startUnixTsSeconds): null;
    obj.endDate = obj.endUnixTsSeconds?this.getDateFromUnixTsInSeconds(obj.endUnixTsSeconds): null;
    return obj;
  }
  addUnixTsToRequestBody(requestBody) { 
    requestBody.startDate.setHours(0, 0, 0, 0);
    requestBody.endDate.setHours(23, 59, 0, 0); 
    console.log("Unix ts for end date is", requestBody.endDate);
    requestBody.start_unix_ts_seconds = this.getUnixTsInSecondsFromDateObj(requestBody.startDate);
    requestBody.end_unix_ts_seconds = this.getUnixTsInSecondsFromDateObj(requestBody.endDate);
    return requestBody; 
  }
}
