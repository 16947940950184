import { Injectable } from '@angular/core';
import * as fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable()
export class ExportService {
constructor() { }
public exportAsExcelFile(json: any[],json1:any[] ,excelFileName: string): void {
  console.log("EXPORT SERVICE EXECUTED");
  console.log(json1);
  
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  const worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json1);
  const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook,worksheet, 'Products');
      XLSX.utils.book_append_sheet(workbook,worksheet1, 'Addons');
      const excelBuffer: any = XLSX.writeXLSX(workbook, { bookType: 'xlsx', type: 'array' });
  // const workbook: XLSX.WorkBook = { Sheets: { 'products': worksheet }, SheetNames: ['products'] };
  // const workbook1: XLSX.WorkBook = { Sheets: { 'Addons': worksheet }, SheetNames: ['Addons'] };
  // let excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  this.saveAsExcelFile(excelBuffer, excelFileName);
}
private saveAsExcelFile(buffer: any, fileName: string): void {
   const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
   fileSaver.saveAs(data, fileName + '_' + new  Date().getTime() + EXCEL_EXTENSION);
}
}