import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';
@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {
  settingsForm: FormGroup;
  submitted:boolean = false;
  clicked:boolean = false;
  passwordRequired: boolean = false;
  profileData;
  country;
  countryArray;
  ngLoader;
  adminId:any;
  constructor(private fb: FormBuilder, private ApiService: ApiService, private toastr: ToastrService,  private router: Router) { }
  handleLogin() {
    var token =  localStorage.getItem('UserToken');
    var isLoggedIn = false;
    if(token) {
      isLoggedIn = true;
    }
    if(!isLoggedIn) { 
      this.router.navigate(['login']);
      return false;
    } 
    return true;
  }
  ngOnInit() {
    this.handleLogin();
    this.settingsForm = this.fb.group({
      name: ['', Validators.required],
      email: [''],
      mobileNumber: ['', Validators.required],
      companyName: [''],
      domainName: [''],
      countryName: [''],
      address: [''],
      currentPassword: ['', Validators.minLength(6)],
      newPassword: ['', Validators.minLength(6)],
      confirmPassword: ['']
    })
    this.getProfileDetails();
    this.getCountryList();
  }

  getCountryList() {
    this.ngLoader = true;
   this.ApiService.getCountryList().subscribe(result => {
    this.countryArray = result['items'];
    console.log(this.countryArray);
    this.ngLoader = false;
   })
  }


  displayErrorsAndSuccess(result) {
    if(result['success'] == 1) {
      this.toastr.success(result.message);
      } else {
       return this.toastr.warning(result.message)
      }
  }

  getProfileDetails() {
    this.ngLoader = true;
    // this.ApiService.getProfileData().subscribe(result => {
    //   this.profileData = result['userData'];
    //   console.log(this.profileData);
    //   this.country = this.profileData.countryId;
    //   this.settingsForm.patchValue({
    //     name: this.profileData.name,
    //     email: this.profileData.email,
    //     mobileNumber: this.profileData.mobileNumber,
    //     companyName: this.profileData.companyName,
    //     domainName: this.profileData.domainName,
    //     countryName: this.profileData.countryName,
    //     address: this.profileData.address
    //   });
    //   this.ngLoader = false;
      
    // })

    var restId:any = localStorage.getItem("restaurantId").toString();
    restId = restId.replaceAll('"','');
    console.log(restId);
    this.ApiService.getProfileDataUsingRestId(restId).subscribe(result => {
      console.log(result);
      this.profileData = result['userData'][0];
      console.log(this.profileData);
      this.adminId =  this.profileData.id;
      console.log(this.adminId);
      this.country = this.profileData.countryId;
      this.settingsForm.patchValue({
        name: this.profileData.name,
        email: this.profileData.email,
        mobileNumber: this.profileData.mobileNumber,
        companyName: this.profileData.companyName,
        domainName: this.profileData.domainName,
        countryName: this.profileData.countryName,
        address: this.profileData.address
      });
      this.ngLoader = false;
      
    })
  }

  get f() {
    return this.settingsForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.clicked = true;
    if (this.settingsForm.invalid) {
      this.clicked = false;
      return;
    }
    
    this.ngLoader = true;
    // this.ApiService.updateProfile(this.settingsForm.value).subscribe(data => {
    //   this.displayErrorsAndSuccess(data);
    //   this.ngLoader = false;
    // })
    this.ApiService.updateProfileById(this.adminId,this.settingsForm.value).subscribe(data => {
      this.displayErrorsAndSuccess(data);
      this.ngLoader = false;
    })
  }

}
