import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { customRegexValidator, requiredFileType } from "../../helper/validator";
import { regEx } from "../../helper/constants";
import { BASE_URL_PWA } from "src/environments/api";
@Component({
  selector: "app-get-start",
  templateUrl: "./get-start.component.html",
  styleUrls: ["./get-start.component.scss"],
})
export class GetStartComponent implements OnInit {
  setupRestaurantsForm: FormGroup;
  submitted: boolean = false;
  loading = false;
  clicked: boolean = false;
  isVerified: boolean = true;
  isResent: boolean = false;
  id;
  slugPreview = "";
  slug = "";

  restaurantNameRegEx = regEx.restaurantNameRegEx;
  restaurantAddressRegEx = regEx.addressRegEx;
  slugRegEx = regEx.slugRegEx;
  noSpaceAtStartRegEx = regEx.noSpaceAtStartRegEx;

  // logoFile:boolean = false;
  // splashFile:boolean = false;
  logoImgPath: string | ArrayBuffer;
  splashImgPath: string | ArrayBuffer;
  backgroundImgPath: string | ArrayBuffer;
  result: any;
  responseData: any;
  spfile: any;
  imageBase: any;
  restaurantToken: any;
  isCompleted: boolean = false;
  isProductsCount: boolean = false;
  isOrdersCount: boolean = false;
  takeAwayEnabled = false;
  homeDeliveryEnabled = true;
  isSimpleLayoutEnabled = false;
  dineInEnabled = false;
  deliveryAvailableInAllDeliveryAreas = true;
  defaultDeliveryMode = true;
  isDeliveryManually: boolean;
  ngLoader: boolean = false;
  keyword: string;
  slugAvilable: any;
  slugAvilableCheckBox: boolean;
  updateActiveId: any;
  slugValue: string;
  currentTabSlNo: 1;
  userDetails: any;

  restaurantNotSetMessage = "You have to finish restaurant setup first.";
  constructor(
    private ApiService: ApiService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  createSlug(Text) {
    return Text.toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-");
  }

  onNameChange(searchValue: string): void {
    let slugName = this.createSlug(searchValue);
    this.setupRestaurantsForm.patchValue({ slug: slugName });
    this.onSearchChange(slugName);
    console.log(this.f.name);
  }

  onSearchChange(searchValue: string): void {
    if (searchValue) {
      this.slugAvilableCheckBox = true;
      if (searchValue != "") {
        let body = { slug: searchValue };
        this.ApiService.getSlugChecking(body).subscribe((res) => {
          this.slugAvilable = res["isSlugAlreadyExists"];
          if (!this.slugAvilable)
            this.slugPreview = BASE_URL_PWA + "/" + searchValue;
          else {
            this.slugPreview = "";
            this.setupRestaurantsForm.controls["slug"].setErrors({
              incorrect: true,
            });
          }
        });
      }
    } else {
      this.slugAvilableCheckBox = false;
    }
  }

  viewWebApp() {
    var url = this.ApiService.getFrontPageUrlFromSlug(this.slugValue);
    window.open(url, "_blank");
  }

  handleLogin() {
    var token = localStorage.getItem("UserToken");
    var isLoggedIn = false;
    if (token) {
      isLoggedIn = true;
    }
    if (!isLoggedIn) {
      this.router.navigate(["login"]);
      return;
    }
  }

  resendVerificationLink() {
    this.ngLoader = true;
    this.ApiService.resendVerificationLink().subscribe((data: any) => {
      this.ngLoader = false;
      this.isResent = true;
    });
  }
  setIsVerified(cb) {
    this.ngLoader = true;
    this.ApiService.getProfileData().subscribe((data: any) => {
      data = data.userData;
      console.log(data.isVerified);
      this.isVerified = data.isVerified;
      // this.isVerified = false;
      console.log(this.isVerified);
      this.ngLoader = false;
      cb();
    });
  }
  ngOnInit() {
    this.handleLogin();
    this.userDetails = JSON.parse(localStorage.getItem("UserDetails"));
    console.log(this.userDetails.email);
    console.log(this.userDetails.phone);

    this.setupRestaurantsForm = this.fb.group({
      name: [
        "",
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(40),
          customRegexValidator({
            pattern: this.restaurantNameRegEx,
            msg: "Invalid Name",
          }),
          customRegexValidator({
            pattern: this.noSpaceAtStartRegEx,
            msg: "Name cannot start or end with a blank space",
          }),
        ],
      ],
      address: [
        "",
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(150),
          customRegexValidator({
            pattern: this.restaurantAddressRegEx,
            msg: "Invalid Address",
          }),
        ],
      ],
      slug: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          customRegexValidator({
            pattern: this.slugRegEx,
            msg: "Invalid Slug",
          }),
        ],
      ],
      displayAddress: [
        "",
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(150),
          customRegexValidator({
            pattern: this.restaurantAddressRegEx,
            msg: "Invalid Address",
          }),
        ],
      ],
      logoImage: ["", [requiredFileType(["png", "jpeg", "jpg"])]],
      splashImage: ["", [requiredFileType(["png", "jpeg", "jpg"])]],
      backgroundImage: ["", [requiredFileType(["png", "jpeg", "jpg"])]],

      isDineInEnabled: [false],
      isHomeDeliveryEnabled: [true],
      isTakeAwayEnabled: [false],
      defaultDeliveryMode: [""],
      isSimpleLayoutEnabled: [false],
      defaultDeliveryCharge: [""],
      preparationTimeMinutes: [""],
      isDeliveryAvailableInAllDeliveryAreas: [""],
    });
    this.setIsVerified(() => {
      if (localStorage.getItem("restaurantId")) {
        this.restaurantToken = JSON.parse(localStorage.getItem("restaurantId"));
        this.handleRestaurantsSetup();
      }
    });
  }
  get f() {
    return this.setupRestaurantsForm.controls;
  }

  onSplashImageChanged(event: any) {
    // this.splashFile = false;

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.setupRestaurantsForm.patchValue({ splashImage: file });
    }
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.result;
        this.splashImgPath = reader.result;
      };
    }
  }

  onBackgroundImageChanged(event: any) {
    // this.splashFile = false;

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.setupRestaurantsForm.patchValue({ backgroundImage: file });
    }
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.result;
        this.backgroundImgPath = reader.result;
      };
    }
  }
  onLogoImageChanged(event: any) {
    // this.logoFile = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.setupRestaurantsForm.patchValue({ logoImage: file });
    }
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.result;
        this.logoImgPath = reader.result;
      };
    }
  }
  removeImage(type) {
    if (type === "logoImage") {
      this.logoImgPath = "";
      this.responseData.logoImage = null;
      this.setupRestaurantsForm.patchValue({ logoImage: null });
    }
    if (type === "splashImgPath") {
      this.splashImgPath = "";
      this.responseData.splashImage = null;
      this.setupRestaurantsForm.patchValue({ splashImage: null });
    }

    if (type === "backgroundImgPath") {
      this.backgroundImgPath = "";
      this.responseData.backgroundImage = null;
      this.setupRestaurantsForm.patchValue({ backgroundImage: null });
    }
  }
  toggleSimpleLayoutEnabled() {
    console.log(
      "Current value of simple layout enabled " + this.isSimpleLayoutEnabled
    );
    this.isSimpleLayoutEnabled = !this.isSimpleLayoutEnabled;
    console.log(
      "New value of simple layout enabled " + this.isSimpleLayoutEnabled
    );
  }
  toggleTakeAwayEnabled() {
    console.log("Current value of take away enabled " + this.takeAwayEnabled);
    this.takeAwayEnabled = !this.takeAwayEnabled;
    console.log("New value of take away enabled " + this.takeAwayEnabled);
  }
  toggleHomeDeliveryEnabled() {
    this.homeDeliveryEnabled = !this.homeDeliveryEnabled;
  }
  toggleDineInEnabled() {
    this.dineInEnabled = !this.dineInEnabled;
  }
  checkDeliveryAvailableInAllDeliveryAreas(event: any) {
    this.deliveryAvailableInAllDeliveryAreas = event;
    this.isDeliveryManually = false;
  }
  deliveryManually(event) {
    if (event.target.value == 0) {
      this.deliveryAvailableInAllDeliveryAreas = false;
      this.isDeliveryManually = true;
    }
  }

  onSubmit(navItemId) {
    if (this.restaurantToken == null) {
      console.log("Calling create rest");
      this.createRestaurant();
    } else {
      console.log("Calling update rest");
      this.updateRestaurant(navItemId);
    }
  }
  ////////////////////////// create ////////////////////////////////////////////
  createRestaurant() {
    console.log("Create called...");
    this.submitted = true;
    this.clicked = true;
    const formData = new FormData();
    let setupRestaurants = this.setupRestaurantsForm.value;
    formData.append(
      "splashImage",
      this.setupRestaurantsForm.get("splashImage").value
    );
    formData.append(
      "backgroundImage",
      this.setupRestaurantsForm.get("backgroundImage").value
    );
    formData.append(
      "logoImage",
      this.setupRestaurantsForm.get("logoImage").value
    );
    formData.append("name", setupRestaurants.name);
    formData.append("address", setupRestaurants.address);
    formData.append("slug", setupRestaurants.slug);
    formData.append("displayAddress", setupRestaurants.displayAddress);
    formData.append("contactEmail", this.userDetails.email);
    formData.append("contactPhone", this.userDetails.phone);

    formData.append(
      "isSimpleLayoutEnabled",
      this.isSimpleLayoutEnabled.toString()
    );
    this.slugValue = setupRestaurants.slug;

    if (this.setupRestaurantsForm.invalid) {
      this.clicked = false;
      return;
    }
    this.loading = true;
    var data: any = formData;
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    this.ApiService.setupRestaurantsSubmit(formData).subscribe(
      (res: any) => {
        if (res.success == 1) {
          console.log("Res is ");
          console.log(JSON.stringify(res));
          this.isCompleted = true;
          this.toastr.success(res.message);
          this.id = res.id;
          localStorage.setItem("restaurantId", JSON.stringify(res.id));
          localStorage.setItem("restaurantName", setupRestaurants.name);
          localStorage.setItem("slug", setupRestaurants.slug);
          this.restaurantToken = res.id;
          console.log("R token is " + this.restaurantToken);
          var navClass = ".navset-2";
          console.log("Nav class is " + navClass);
          let element: HTMLElement = document.querySelector(
            navClass
          ) as HTMLElement;
          element.click();
        } else {
          this.toastr.warning(res.message);
        }

        this.loading = false;
      },
      (error) => {
        this.toastr.error(error.error.message);
      }
    );
  }

  ////////////////////////// update ////////////////////////////////////////////
  updateRestaurant(navItemId) {
    this.isSimpleLayoutEnabled = this.isSimpleLayoutEnabled || false;
    navItemId = navItemId ? navItemId : 2;
    console.log("Update called...");
    this.submitted = true;
    this.clicked = true;
    const formData = new FormData();
    let setupRestaurants = this.setupRestaurantsForm.value;
    formData.append(
      "splashImage",
      this.setupRestaurantsForm.get("splashImage").value
    );
    formData.append(
      "backgroundImage",
      this.setupRestaurantsForm.get("backgroundImage").value
    );
    formData.append(
      "logoImage",
      this.setupRestaurantsForm.get("logoImage").value
    );
    formData.append("name", setupRestaurants.name);
    formData.append("address", setupRestaurants.address);
    formData.append("slug", setupRestaurants.slug);
    formData.append("displayAddress", setupRestaurants.displayAddress);
    if (setupRestaurants.defaultDeliveryMode)
      formData.append(
        "defaultDeliveryMode",
        setupRestaurants.defaultDeliveryMode
      );
    if (setupRestaurants.defaultDeliveryCharge)
      formData.append(
        "defaultDeliveryCharge",
        setupRestaurants.defaultDeliveryCharge
      );
    if (setupRestaurants.preparationTimeMinutes)
      formData.append(
        "preparationTimeMinutes",
        setupRestaurants.preparationTimeMinutes
      );
    formData.append("isDineInEnabled", this.dineInEnabled.toString());
    formData.append(
      "isHomeDeliveryEnabled",
      this.homeDeliveryEnabled.toString()
    );
    formData.append(
      "isSimpleLayoutEnabled",
      this.isSimpleLayoutEnabled.toString()
    );
    formData.append("isTakeAwayEnabled", this.takeAwayEnabled.toString());
    formData.append(
      "isDeliveryAvailableInAllDeliveryAreas",
      this.deliveryAvailableInAllDeliveryAreas.toString()
    );

    this.slugValue = setupRestaurants.slug;

    if (this.setupRestaurantsForm.invalid) {
      this.clicked = false;
      return;
    }
    this.loading = true;
    console.log("Restaurant id to update is " + this.restaurantToken);
    console.log("Formdata is this", formData);
    this.ApiService.setupRestaurantsUpdate(
      this.restaurantToken,
      formData
    ).subscribe(
      (res: any) => {
        // console.log(res.message);
        if (res.success == 1) {
          localStorage.setItem("restaurantName", setupRestaurants.name);
          localStorage.setItem("slug", setupRestaurants.slug);
          this.toastr.success(res.message);
          navItemId++;
          console.log("nav item id is " + navItemId);
          let element: HTMLElement = document.querySelector(
            ".navset-" + navItemId
          ) as HTMLElement;
          element.click();
        } else {
          this.toastr.warning(res.message);
        }

        this.loading = false;
      },
      (error) => {
        this.toastr.error(error.error.message);
      }
    );
  }
  toggleDeliveryAvailableInAllDeliveryAreas() {
    this.deliveryAvailableInAllDeliveryAreas =
      !this.deliveryAvailableInAllDeliveryAreas;
  }
  handleRestaurantsSetup() {
    this.loading = true;
    console.log("Handling setup...");
    this.ApiService.getRestaurantsSetup(this.restaurantToken).subscribe(
      (res: any) => {
        this.ngLoader = true;
        console.log("Result is " + JSON.stringify(res));
        if (res.success == 1) {
          this.ngLoader = false;
          this.isCompleted = true;
          this.imageBase = res.imageBase;
          this.responseData = res["item"];
          this.isSimpleLayoutEnabled = this.responseData.isSimpleLayoutEnabled;
          this.id = this.responseData.id;
          this.slugValue = this.responseData.slug;
          this.slugPreview = BASE_URL_PWA + "/" + this.responseData.slug;
          this.takeAwayEnabled = this.responseData.isTakeAwayEnabled;
          this.dineInEnabled = this.responseData.isDineInEnabled;
          this.homeDeliveryEnabled = this.responseData.isHomeDeliveryEnabled;
          // console.log(this.responseData.isDineInEnabled)
          this.setupRestaurantsForm.patchValue({
            name: this.responseData.name,
            slug: this.responseData.slug,
            address: this.responseData.address,
            displayAddress: this.responseData.displayAddress,
            logoImage: this.responseData.logoImage,
            splashImage: this.responseData.splashImage,
            backgroundImage: this.responseData.backgroundImage,

            isDineInEnabled: this.responseData.isDineInEnabled,
            isHomeDeliveryEnabled: this.responseData.isHomeDeliveryEnabled,
            isTakeAwayEnabled: this.responseData.isTakeAwayEnabled,

            defaultDeliveryMode: this.responseData.defaultDeliveryMode,
            defaultDeliveryCharge: this.responseData.defaultDeliveryCharge,
            preparationTimeMinutes: this.responseData.preparationTimeMinutes,
            isDeliveryAvailableInAllDeliveryAreas:
              this.responseData.isDeliveryAvailableInAllDeliveryAreas,
          });
          this.deliveryAvailableInAllDeliveryAreas =
            this.responseData.isDeliveryAvailableInAllDeliveryAreas;
          if (this.responseData.totalProductsCount < 0) {
            this.isProductsCount = true;
          }
          if (this.responseData.totalOrdersCount < 0) {
            this.isOrdersCount = true;
          }
          // this.logoImgPath = this.responseData.logoImage
          // this.splashImgPath = this.responseData.splashImage
        }
        this.loading = false;
      }
    );
  }
}
