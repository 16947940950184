import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  toggleMenuSettings;
  toggleMenuConfig;
  toggleMenuCity;
  userRole: any;
  checkUserType;

  constructor( private router: Router, private toastr: ToastrService, public ApiService:ApiService) { }

  ngOnInit() { 
   
   }  
  ngDoCheck(){   
    // this.userRole = this.ApiService.roleMatch([])
    //console.log(this.userRole + " : user roles side menu")
     this.checkUserType = localStorage.getItem('userType')
  }
  switchToSupperAdmin(){
    let userRoles = ["superadmin"]
    localStorage.setItem("userRoles", JSON.stringify(userRoles));
    localStorage.removeItem("restaurantId");
    localStorage.removeItem("userType");  
    this.router.navigate(['restaurant-list'])
  }

  logout(){    
    this.router.navigate(['/logout']) 
  }
}
