import {
  AbstractControl,
  ValidatorFn,
  FormControl,
  Validators,
} from "@angular/forms";
import { isArray } from "util";

export function passValidator(control: AbstractControl) {
  if (control && (control.value !== null || control.value !== undefined)) {
    const cnfpassValue = control.value;

    const passControl = control.root.get("password"); // magic is this
    if (passControl) {
      const passValue = passControl.value;
      if (passValue !== cnfpassValue || passValue === "") {
        return {
          isError: true,
        };
      }
    }
  }

  return null;
}
export function nonNegativeNumbers(control: AbstractControl): {
  [key: string]: any;
} {
  if (Number(control.value) < 0) {
    return { negativeNumber: true };
  } else {
    return null;
  }
}
export function customRegexValidator(config: any): ValidatorFn {
  return (control: FormControl) => {
    let urlRegEx: RegExp = config.pattern;
    if (control.value && !String(control.value).match(urlRegEx)) {
      return {
        invalidMsg: config.msg,
      };
    } else {
      return null;
    }
  };
}

export function requiredFileType(type: string[]) {
  console.log("Validator executed");
  
  return function (control: FormControl) {
    console.log("-------------- Control is ");
    console.log(control);
    console.log("-------------- Control.value  is ");
    console.log(control.value);
    if (typeof control.value === "string" || control.value instanceof String) {
      const fileName = control.value;
      if (fileName) {
        const extension = fileName.split(".")[1].toLowerCase();

        if (type.includes(extension.toLowerCase())) {
          return null;
        } else {
          return {
            requiredFileType: true,
          };
        }
      }
      return null;
    }

    const file = control.value;
    if (file) {
      const extension = file.name.split(".")[1].toLowerCase();
      if (type.includes(extension.toLowerCase())) {
        return null;
      } else {
        return {
          requiredFileType: true,
        };
      }
    }
    return null;
  };
}

/* Number Validator Options - { isDecimalAllowed: Boolean, moreThan, moreThanOrEqual lessThan, lessThanOrEqual } */
// Need Improvement. If no decimals are allowed and if developer passed min, max values in decimal, there ischance to give bugs at the range start/end
export function numberValidator(options: any = {}): ValidatorFn {
  return (control: FormControl): { [key: string]: any } => {
    let val: number = parseFloat(control.value);
    //return no errors is no value is passed
    if (!val) return null;

    //Received Settings
    let isDecimalAllowed = false;
    if (options.isDecimalAllowed && options.isDecimalAllowed == true)
      isDecimalAllowed = true;

    if (!isDecimalAllowed) {
      if (val - Math.round(val) > 0) {
        return {
          invalidMsg: "Decimal Values are not allowed",
        };
      }

    }

    //Checking min values
    if (options.moreThan || options.moreThanOrEqual) {
      //if both moreThan and moreThanOrEqual params are passed by user, we select the least value and allow that condition(to get maximum range). If both are equal, then moreThanOrEqual is considered
      if (options.moreThan && options.moreThanOrEqual) {
        let moreThan = options.moreThan;
        let moreThanOrEqual = options.moreThanOrEqual;
        if (moreThan < moreThanOrEqual) {
          if (val <= moreThan) {
            return {
              invalidMsg: "Invalid Value",
            };
          }
        } else {
          if (val < moreThanOrEqual) {
            return {
              invalidMsg: "Invalid Value",
            };
          }
        }
      } else {
        if (options.moreThan) {
          let moreThan = options.moreThan;
          if (val <= moreThan) {
            return {
              invalidMsg: "Invalid Value",
            };
          }
        } else {
          let moreThanOrEqual = options.moreThanOrEqual;
          if (val < moreThanOrEqual) {
            return {
              invalidMsg: "Invalid Value",
            };
          }
        }
      }
    }

    //Checking max values
    if (options.lessThan || options.lessThanOrEqual) {
      //if both moreThan and moreThanOrEqual params are passed by user, we select the least value and allow that condition(to get maximum range). If both are equal, then moreThanOrEqual is considered
      if (options.lessThan && options.lessThanOrEqual) {
        let lessThan = options.lessThan;
        let lessThanOrEqual = options.lessThanOrEqual;
        if (lessThan > lessThanOrEqual) {
          if (val >= lessThan) {
            return {
              invalidMsg: "Invalid Value",
            };
          }
        } else {
          if (val > lessThanOrEqual) {
            return {
              invalidMsg: "Invalid Value",
            };
          }
        }
      } else {
        if (options.lessThan) {
          let lessThan = options.lessThan;
          if (val >= lessThan) {
            return {
              invalidMsg: "Invalid Value",
            };
          }
        } else {
          let lessThanOrEqual = options.lessThanOrEqual;
          if (val > lessThanOrEqual) {
            return {
              invalidMsg: "Invalid Value",
            };
          }
        }
      }
    }
  };
}
export function numberRangeValidator(params: any = {}): ValidatorFn {
  return (control: FormControl): { [key: string]: any } => {
    /*if(isPresent(Validators.required(control))) {
      return null;
    }*/
    let val: number = control.value;
    if (!val) return null;

    if (isNaN(val) || /\D/.test(val.toString())) {
      return { numberRange: "Value Not Allowed" };
    } else if (!isNaN(params.min) && !isNaN(params.max)) {
      return val < params.min || val > params.max
        ? { numberRange: "Value Not Allowed" }
        : null;
    } else if (!isNaN(params.min)) {
      return val < params.min ? { numberRange: "Value Not Allowed" } : null;
    } else if (!isNaN(params.max)) {
      return val > params.max ? { numberRange: "Value Not Allowed" } : null;
    } else {
      return null;
    }
  };
}
