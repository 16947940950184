import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordFrom: FormGroup;
  submitted:boolean = false;
  clicked:boolean = false;
  ngLoader: boolean = false;
  constructor(
    private ApiService:ApiService,
    private toastr: ToastrService, 
    private fb: FormBuilder, 
    private router: Router,) { }

    ngOnInit() {
      this.forgotPasswordFrom = this.fb.group({
        email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      }) 

    }
      get f() {
        return this.forgotPasswordFrom.controls;
      }

      onSubmit() {
        this.submitted = true;
        this.clicked = true;
        if (this.forgotPasswordFrom.invalid) {
            this.clicked = false;
            return;
        }
        console.log(this.forgotPasswordFrom.value)
        let formData = this.forgotPasswordFrom.value;
        this.ngLoader  = true;
        this.ApiService.forgotPasswordApi(formData).subscribe((res:any)=>{    
          this.ngLoader  = false;	        
          if (res.success == 1) {
            this.toastr.success(res.message);
            this.router.navigate(['login']);
           } else {
            this.toastr.warning(res.message);
          }
        }, error => {
          this.ngLoader  = false;
        })
      }

}
