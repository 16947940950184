import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-verify-restaurant',
  templateUrl: './verify-restaurant.component.html',
  styleUrls: ['./verify-restaurant.component.scss']
})
export class VerifyRestaurantComponent implements OnInit {
  ngLoader = false;
  isVerified = false;
  isFailed = false;
  isAlreadyVerified = false;
  isResent = false;
  constructor(
    private ApiService:ApiService,   
    private router: Router,
    private route: ActivatedRoute,)  { }

  ngOnInit(): void {  
    this.ngLoader = true;
    var token = this.route.snapshot.paramMap.get('token');  
    
    var userToken = localStorage.getItem("UserToken");  
    if(userToken) {
      this.ApiService.getProfileData().subscribe((data: any) => {
        console.log("Data is ");
        console.log(data);
        data = data.userData;
        if(data.isVerified) {
          this.ngLoader = false;
          this.isAlreadyVerified = true;
        } else {  
          this.verifyAccount(token);
        } 
      });  
    } else {
      this.verifyAccount(token);
    }
  }
  verifyAccount(token) {
    this.ApiService.verifyRestaurant(token).subscribe( (data: any )=> {
      this.ngLoader = false; 
      if(data.success) {
        this.isVerified = true;
        //this.manage();
      } else {
        this.isFailed = true;
      }
    });
  }
  resend() {
    this.ngLoader = true; 
    this.ApiService.resendVerificationLink().subscribe( (data: any )=> {
      console.log("Verification Response ");
      
      this.ngLoader = false;  
      this.isResent = true;
    });

  }
  manage() { 
    this.router.navigate(['get-start']);
  }

}
