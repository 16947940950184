import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  dashboardCount: any;
  ordersList: any;
  ngLoader: boolean = false;
  ordersStatus: any;
  id;

  constructor(
    private ApiService: ApiService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  handleRestaurantRegistration() {
    var token = localStorage.getItem("UserToken");
    var isLoggedIn = false;
    if (token) {
      isLoggedIn = true;
    }
    if (!isLoggedIn) {
      this.router.navigate(["login"]);
      return false;
    }
    var id = localStorage.getItem("restaurantId");
    if (id) {
      id = JSON.parse(id);
    }
    if (id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(["get-started"]);
      return false;
    }
    return true;
  }
  ngOnInit() {
    var ok = this.handleRestaurantRegistration();
    if (!ok) return;
    this.handleDashboardCount();
    this.handleOrdersList();
    this.handleOrdersStatus();
  }

  handleDashboardCount() {
    this.ApiService.getDashboardCount().subscribe((res) => {
      console.log("Dashboard Count is " + JSON.stringify(res));
      this.dashboardCount = res;
    });
  }

  handleOrdersList() {
    this.ngLoader = true;
    this.ApiService.getDashboardOrdersList().subscribe(
      (res: any) => {
        this.ngLoader = false;
        if (res.success == 1) {
          console.log("API Response is  -------------");
          console.log(res);
          this.ordersList = res["items"];
        } else {
          this.toastr.error(res.message);
        }
      },
      (error) => {
        this.ngLoader = false;
      }
    );
  }
  handleOrdersStatus() {
    this.ApiService.getOrdersStatus().subscribe((res) => {
      this.ordersStatus = res["items"];
    });
  }
  editOrder(pageId) {
    this.router.navigate(["/order-edit", pageId]);
  }
  billSummary(pageId) {
    this.router.navigate(["/order-summary", pageId]);
  }
  formatPercent(value: any) {
    value = Number(value);
    value = value.toFixed(2);
    if (value > 0) return "+" + value;
    else return "-" + value;
  }
  onAcceptOrder(id, preparationTime = null) {
    let preparationTimeText = "Preparation Time (in Minutes)";
    let preparationTimeMinutes = "0";
    if (preparationTime) {
      preparationTimeText =
        "Preparation Time (estimated " + preparationTime + " minutes)";
      preparationTimeMinutes = String(preparationTime);
    }

    Swal.fire({
      title: "Accept Order",
      text: "Preparation time (minutes)",
      input: "text",
      inputPlaceholder: preparationTimeText,
      inputValue: preparationTimeMinutes,
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#1dbc60",
      cancelButtonColor: "#374abe",
      confirmButtonText: "Accept",
      cancelButtonText: "Close",
    }).then((result) => {
      console.log("Result is ---------------------------------");
      console.log(result);
      if (result.value) {
        this.orderAccept(id, "accept", {
          preparationTimeMinutes: result.value,
        });
      }
    });
  }
  onCancelOrder(id) {
    let cancellationReasonText = " ";

    Swal.fire({
      title: "Cancel Order",
      text: "Cancellation Reason",
      input: "text",
      inputPlaceholder: "Enter cancellation Reason",
      inputValue: cancellationReasonText,
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#374abe",
      confirmButtonText: "Reject",
      cancelButtonText: "Close",
    }).then((result) => {
      if (result.value) {
        this.orderAccept(id, "cancell", {
          cancellationReason: result.value,
        });
      }
    });
  }
  orderAccept(orderId, type, info = null) {
    let body = {};
    let cancellationReason = null;
    let preparationTimeMinutes = null;
    if (type == "accept") {
      if (info && info.preparationTimeMinutes)
        preparationTimeMinutes = info.preparationTimeMinutes;
      body = {
        isAccepted: true,
        isCancelled: false,
        preparationTimeMinutes: preparationTimeMinutes,
      };
    } else {
      if (info && info.cancellationReason)
        cancellationReason = info.cancellationReason;
      body = {
        isAccepted: false,
        isCancelled: true,
        cancellationReason: cancellationReason,
      };
    }
    this.ApiService.ordersAccept(orderId, body).subscribe((res: any) => {
      this.handleOrdersList();
      this.toastr.success(res.message);
    });
  }
  changeStatus(orderId, statusId) {
    let body = {
      orderStatusId: statusId,
    };
    this.ApiService.updateOrdersStatus(orderId, body).subscribe((res: any) => {
      this.handleOrdersList();
      this.toastr.success(res.message);
    });
  }
}
