import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sms-gateway',
  templateUrl: './sms-gateway.component.html',
  styleUrls: ['./sms-gateway.component.scss']
})
export class SmsGatewayComponent implements OnInit {
  smsGateWayForm: FormGroup;
  submitted:boolean = false;
  clicked:boolean = false;
  ngLoader;
  restaurantData;
  id = JSON.parse(localStorage.getItem('restaurantId'));
  constructor(private fb: FormBuilder, private ApiService: ApiService, private toastr: ToastrService, private router: Router) { }

  handleRestaurantRegistration() {
    var token =  localStorage.getItem('UserToken');
    var isLoggedIn = false;
    if(token) {
      isLoggedIn = true;
    }
    if(!isLoggedIn) { 
      this.router.navigate(['login']);
      return false;
    }
    var id = localStorage.getItem('restaurantId'); 
    if(id) {
      id = JSON.parse(id);
    }
    if(id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(['get-started']);
      return false;
    }
    return true;
  } 
  ngOnInit() {
    this.smsGateWayForm = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
      senderId: ['', Validators.required],
      isEnabled: [false]
    });
    var ok  = this. handleRestaurantRegistration();
    if(!ok) {
      return;
    }
    this.ngLoader =  true;
    this.ApiService.getRestaurantsSetup(this.id).subscribe(data => {
      this.restaurantData = data['item'];
      this.smsGateWayForm.patchValue({
        userName: this.restaurantData.smsGatewaySettings.userName,
        password: this.restaurantData.smsGatewaySettings.password,
        senderId: this.restaurantData.smsGatewaySettings.senderId,
        isEnabled: this.restaurantData.smsGatewaySettings.isEnabled
      });
      this.ngLoader =  false;
    })
  }

  get f() {
    return this.smsGateWayForm.controls;
  }

  onSubmit() { 
    this.submitted = true;
    this.clicked = true;
    if (this.smsGateWayForm.invalid) {
      this.clicked = false;
      return;
    }
    var smsGatewaySettings = {
      smsGatewaySettings: this.smsGateWayForm.value
    };
    this.ngLoader =  true;
    this.ApiService.setupRestaurantsUpdate(this.id,smsGatewaySettings).subscribe(data => {
      this.toastr.success('Successfully saved');
      this.ngLoader =  false;
    })
  }

}
