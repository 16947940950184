import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services/api.service";
import { ConfirmationDialogService } from "../../confirmation-dialog/confirmation-dialog.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-order-summary",
  templateUrl: "./order-summary.component.html",
  styleUrls: ["./order-summary.component.scss"],
})
export class OrderSummaryComponent implements OnInit {
  pageId: string;
  orderDetail: any;

  constructor(
    private ApiService: ApiService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  referenceNo: any;
  orderId: any;
  currentStatus: any;
  customerId;
  customerName;
  customerCountryCode;
  customerPhone;
  orderDateTime;
  scheduledDateTime;
  paymentMethod;
  statusHistory = ["", ""];
  preparationTime;
  tableName;
  deliveryMethod;
  customerEmail;
  deliveryAreaName;
  addressType;
  addressLine;
  emirateName;
  customerAddress;
  maplink:any = "https://maps.google.com/?q=";
  ngOnInit() {
    this.handleOrderDetails();
  }
  formatDate(dateString) {
    console.log("------------------ Date recieved to format date function is ");
    console.log(dateString);
    var dateValue = new Date(dateString);
    var formattedDate = dateValue.toLocaleString();
    return formattedDate;
  }
  formatPrice(value) {
    if(isNaN(value))
      return "0.00";
    return Number(value).toFixed(2);
  }
  printDetailPage() {
    const printContent = document.getElementById("orderDetailsSection");
    const WindowPrt = window.open(
      "",
      "",
      "left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0"
    );
    WindowPrt.document.write(document.head.innerHTML);
    WindowPrt.document.write(`<div class = 'print-window'>${printContent.innerHTML}</div>`); 
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.document.close();
    //WindowPrt.close();
  }
  handleOrderDetails() {
    this.pageId = this.route.snapshot.paramMap.get("id");
    console.log(this.pageId);
    this.ApiService.getOrderDetails(this.pageId).subscribe((res: any) => {
      console.log("Order Details is " + JSON.stringify(res));
      if (res.success == 1) {
        if(res.orderData.addressId != undefined && res.orderData.addressId != undefined)
        {
          this.maplink += res.orderData.addressId.lat+','+res.orderData.addressId.lng;

        }
        console.log("Assigning order data...");
        var orderDetail = res["orderData"];
        this.referenceNo = orderDetail.referenceNo;
        this.currentStatus = orderDetail.currentStatus;
        this.orderId = orderDetail.orderId;
        this.orderDateTime = orderDetail.orderDateTime;
        this.scheduledDateTime = orderDetail.scheduledDateTime;
        var items = orderDetail.items;
        if (orderDetail.statusHistory)
          this.statusHistory = orderDetail.statusHistory;

        let updatedStatusHistory = [];
        let insertPosition = 1;
        if (orderDetail.isCancelled) {
          let orderCancelledStatus = {
            datetime: orderDetail.acceptedTime,
            isCompleted: 1,
            orderStatusId: {
              title: "Order Cancelled",
            },
          };
          for (let i = 0; i < this.statusHistory.length; i++) {
            if (i < insertPosition) {
              updatedStatusHistory.push(this.statusHistory[i]);
            } else if (i == insertPosition) {
              updatedStatusHistory.push(orderCancelledStatus);
            } else {
            }
          }
        } else {
          let orderAcceptedStatus = {};
          if (orderDetail.isAccepted) {
            orderAcceptedStatus = {
              datetime: orderDetail.acceptedTime,
              isCompleted: 1,
              orderStatusId: {
                title: "Order Accepted",
              },
            };
          } else {
            orderAcceptedStatus = {
              datetime: orderDetail.acceptedTime,
              isCompleted: 0,
              orderStatusId: {
                title: "Order Accepted",
              },
            };
          }
          for (let i = 0; i < this.statusHistory.length; i++) {
            if (i < insertPosition) {
              updatedStatusHistory.push(this.statusHistory[i]);
            } else if (i == insertPosition) {
              updatedStatusHistory.push(orderAcceptedStatus);
              updatedStatusHistory.push(this.statusHistory[i]);
            } else {
              updatedStatusHistory.push(this.statusHistory[i]);
            }
          }
        }

        this.statusHistory = updatedStatusHistory;
        if (orderDetail.isCancelled) {
          //
        }

        console.log(
          "OrderStatus History is " + JSON.stringify(this.statusHistory)
        );

        for (var idx in items) {
          var item = items[idx];
          var price: any = item.discountedPrice
            ? item.discountedPrice
            : item.sellingPrice;
          price = price ? price : 0;
          var addOns = item.addOns;
          var quantity: any = item.quantity;
          quantity = quantity ? quantity : 0;
          item.price = price;
          price = price * quantity;
          console.log("Product price is " + price);
          for (var addOn of addOns) {
            var options = addOn.options;
            for (var option of options) {
              console.log("Adding " + option.totalPrice + " with " + price);
              if (!isNaN(option.totalPrice))
                price += parseFloat(option.totalPrice);
            }
          }
          item.totalPrice = price;
          items[idx] = item;
        }
        this.orderDetail = orderDetail;
        if (orderDetail.isOnlinePayment) {
          this.paymentMethod = "Online payment";
        } else if (orderDetail.isCashOnDelivery) {
          this.paymentMethod = "Cash on delivery";
        } else if (orderDetail.isCardOnDelivery) {
          this.paymentMethod = "Card on delivery";
        }
        if (orderDetail.isHomeDelivery) {
          this.deliveryMethod = "Home delivery";
        } else if (orderDetail.isTakeAway) {
          this.deliveryMethod = "Take away";
        } else if (orderDetail.isDineIn) {
          this.deliveryMethod = "Dine in";
        }
        console.log(
          "--------------------------------------- preparationtimeminutes is"
        );
        console.log(orderDetail.preparationTimeMinutes);
        this.preparationTime = orderDetail.preparationTimeMinutes
          ? orderDetail.preparationTimeMinutes
          : 0;

        this.tableName = orderDetail.tableName ? orderDetail.tableName : "";

        if (orderDetail.userId) {
          var customer = orderDetail.userId;
          console.log("address is ----------------------------");
          console.log(orderDetail.addressId);
          this.customerId = customer.customerId;
          this.customerName = customer.name;
          this.customerCountryCode = customer.countryCode
            ? customer.countryCode
            : "";
          this.customerPhone = customer.phone;
          this.customerEmail = customer.email;
        }
        var deliveryArea = orderDetail.deliveryAreaId;
        if (deliveryArea) {
          this.deliveryAreaName = deliveryArea.name;
        }
        var address = orderDetail.addressId;
        if (address) {
          this.addressType = address.addressType;
          this.addressLine = address.addressLine;
          var emirate = address.emiratesId;
          if (emirate) {
            this.emirateName = emirate.name;
          }
          var deliveryArea = address.deliveryAreaId;
          if (deliveryArea) {
            this.deliveryAreaName = deliveryArea.name;
          }
          var address: any = [
            this.addressLine,
            this.deliveryAreaName,
            this.emirateName,
          ];
          address = address.filter(function (el) {
            return el != null;
          });
          address = address.join(", ");
          if (this.addressType) {
            address += ` ( ${this.addressType} ) `;
          }
          console.log("Address is ");
          console.log(address);
          this.customerAddress = address;
        }
      }
    });
  }
}
