import { Component, OnInit,Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-customers-details',
  templateUrl: './customers-details.component.html',
  styleUrls: ['./customers-details.component.scss']
})
export class CustomersDetailsComponent implements OnInit {
  ordersList: any;
  pageId: any;
  userDetails: any;
  customerId: any;
  noList: any;
  ngLoader: boolean = false;
  id;
  constructor(
    private ApiService:ApiService,
    private toastr: ToastrService, 
    private router: Router,
    private route: ActivatedRoute,) {     
      
    }
    customerEdit(customerId){
      this.router.navigate(['/customers-edit',customerId]);
    }
    ngOnInit() {
      var ok = this.handleRestaurantRegistration();
      if(!ok) {
        return ;
      }
      this.pageId = this.route.snapshot.paramMap.get('id')     
      this.handleCustomerDetails()
    }
    billSummary(pageId) {
      this.router.navigate(["/order-summary", pageId]);
    }
    printCustomerInfo() { 
      const printContent = document.getElementById("customerInfoSection");
      const WindowPrt = window.open(
        "",
        "",
        "left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WindowPrt.document.write(document.head.innerHTML);
      WindowPrt.document.write(`<div class = 'print-window'>${printContent.innerHTML}</div>`); 
      WindowPrt.focus();
      WindowPrt.print();
      WindowPrt.document.close();
    }
    
    handleRestaurantRegistration() {
      var token =  localStorage.getItem('UserToken');
      var isLoggedIn = false;
      if(token) {
        isLoggedIn = true;
      }
      if(!isLoggedIn) { 
        this.router.navigate(['login']);
        return false;
      }
      var id = localStorage.getItem('restaurantId'); 
      if(id) {
        id = JSON.parse(id);
      }
      if(id) {
        this.id = id;
      } else {
        console.log("No id found");
        this.router.navigate(['get-started']);
        return false;
      }
      return true;
    }
    handleCustomerDetails(){
      this.ngLoader = true
      this.ApiService.getCustomerDetails( this.pageId).subscribe((res:any)=>{     
        this.ngLoader = false
        this.userDetails = res['items']
        this.customerId = this.userDetails.id
        if (res.success == 1) {     
             console.log(this.userDetails.id) 
             this.handleOrdersList()
        } else {
          this.toastr.warning(res.message);
        } 
  
      })
    }

    handleOrdersList(){ 
      this.ApiService.customerOrdersHistory(this.customerId).subscribe((res)=>{
          console.log(JSON.stringify(res["items"]))
          this.ordersList = res['items']     
         
      })
    }

}
