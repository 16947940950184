import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.scss']
})
export class PaymentGatewayComponent implements OnInit {
  paymentGateWayForm: FormGroup;
  submitted:boolean = false;
  clicked:boolean = false;
  enabled: boolean = false;
  restaurantData;
  ngLoader;
  id = JSON.parse(localStorage.getItem('restaurantId'));
  constructor(private fb: FormBuilder, private ApiService: ApiService, private toastr: ToastrService, private router: Router) { }

  handleRestaurantRegistration() {
    var token =  localStorage.getItem('UserToken');
    var isLoggedIn = false;
    if(token) {
      isLoggedIn = true;
    }
    if(!isLoggedIn) { 
      this.router.navigate(['login']);
      return false;
    }
    var id = localStorage.getItem('restaurantId'); 
    if(id) {
      id = JSON.parse(id);
    }
    if(id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(['get-started']);
      return false;
    }
    return true;
  } 
  ngOnInit() {
    this.paymentGateWayForm = this.fb.group({
      profileId: ['', Validators.required],
      serverKey: ['', Validators.required],
      isEnabled: [false]
    })
    
    var ok  = this. handleRestaurantRegistration();
    if(!ok) {
      return;
    }
    this.ngLoader = true;
    this.ApiService.getRestaurantsSetup(this.id).subscribe(data => {
      this.restaurantData = data['item'];
      this.paymentGateWayForm.patchValue({
        profileId: this.restaurantData.paymentGatewaySettings.profileId,
        serverKey: this.restaurantData.paymentGatewaySettings.serverKey,
        isEnabled: this.restaurantData.paymentGatewaySettings.isEnabled
      });
      
      this.ngLoader = false;
    })
  }

  get f() {
    return this.paymentGateWayForm.controls;
  }

  onSubmit() { 
    this.submitted = true;
    this.clicked = true;
    if (this.paymentGateWayForm.invalid) {
      this.clicked = false;
      return;
    }
    var paymentGatewaySettings = {
      paymentGatewaySettings: this.paymentGateWayForm.value
    };
    console.log(paymentGatewaySettings);
    this.ngLoader = true;
    this.ApiService.setupRestaurantsUpdate(this.id,paymentGatewaySettings).subscribe(data => {
      this.toastr.success('Successfully saved');
      this.ngLoader = false;
    })
  }

}
