import { Injectable } from '@angular/core';
import {  HttpRequest,  HttpHandler,  HttpEvent,  HttpInterceptor} from '@angular/common/http';
import { ApiService } from '../services/api.service';
import { Observable, throwError } from 'rxjs'; 
import { catchError } from 'rxjs/operators';
import {Router} from '@angular/router';
  
@Injectable()
export class TokenInterceptorService implements HttpInterceptor {  
  userToken: any;
  userDetails: any;
  constructor(public auth: ApiService,private router:Router) {}  

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.userToken = localStorage.getItem("UserToken")  
    request = request.clone({
      setHeaders: {
        Authorization: "Bearer "  + this.userToken
      }
    });
    return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
              console.log('Token expired')
              this.router.navigate(['/logout']);               
            }  
             const error = err;
              return throwError(error);            
        }))
  }

 
  
}







// import { Injectable } from '@angular/core';
// import { HttpInterceptor } from '@angular/common/http';

// @Injectable()
// export class TokenInterceptorService  implements HttpInterceptor{
//   userToken: any;
//   userDetails: any;

//   constructor() { }
//   intercept(req, next){
//     this.userToken = localStorage.getItem("UserToken")   
//     let tokenizedReq = req.clone({
//       setHeaders: {
//         Authorization: "Bearer "  + this.userToken
//       }
//     })
//     return next.handle(tokenizedReq)
//   }
// }
